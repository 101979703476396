import React, { useEffect, useState } from 'react';
import { View, TouchableOpacity, Modal, NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';
import MyAppText from '../../../components/MyAppText';
import { translate } from '../../../services/translate';
import { Pagination } from '../../../components/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import UserModal from './UserModal';
import Toast from 'react-native-toast-message';
import { accessService } from '../../../services/central-api/access';
import DeleteModal from '../../../components/DeleteModal';
import { ClientError } from '../../../services/central-api/base-service';
import MyDataTable from '../../../components/MyDataTable';
import { RouteProp, useRoute } from '@react-navigation/native';
import { AccessParamList } from '../../../typings/Params';
import UsersFilter from './Filters';
import useFilters from '../../../components/Filter/hooks/useFilters';
import FilterInput from '../../../components/Filter/components/FilterInput';
import CustomButton from '../../../components/CustomButton';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import getBestContrastColor from '../../../services/best-contrast-color';

export default function Users({ navigation, authenticatedUser }: { navigation: Navigation; authenticatedUser?: AuthenticatedUser; }) {
    const { styles, theme } = useStyles(styleSheet);

    const route = useRoute<RouteProp<AccessParamList, 'Users'>>();

    const [users, setUsers] = useState<UserData[]>([]);

    const [selectedUser, setSelectedUser] = useState<UserData>();
    const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
    const [isModalVisible, setModalVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [filters, setFilters] = useFilters<AccessParamList['Users']>({
        ...route.params,
        page: Math.max(+route.params.page - 1, 0),
        limit: Number(route.params.limit) || 25,
    });
    const [textFilter, setTextFilter] = useState(filters.textFilter ?? '');
    const [totalItems, setTotalItems] = useState(0);

    async function getUsers() {
        try {
            setIsLoading(true);
            const response = await accessService.getUsers(filters);
            setUsers(response.rows);
            setTotalItems(response.count);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    async function confirmDelete(user: UserData) {
        try {
            if (user?.id) {
                await accessService.deleteUser(user.id);
                Toast.show({
                    type: 'sentinelxSuccess',
                    text1: translate('ActionSuccessfully'),
                });

                setDeleteModalVisible(false);
                getUsers();
            }
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    useEffect(() => {
        getUsers();
    }, []);

    useEffect(() => {
        // there is a fadeout animation on modalVisible = false
        // wait for it to change selected user so the 'password' input
        // don't appear on the modal
        if (!isModalVisible) {
            setTimeout(() => setSelectedUser(undefined), 250);
        }
    }, [isModalVisible]);

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == 'Enter') {
            setFilters(old => ({
                ...old,
                textFilter
            }));

            navigation.setParams({
                textFilter
            });
        }
    }

    return (
        <View style={styles.container}>
            <View style={styles.headerContent}>
                <View style={{ flexGrow: 1, maxWidth: 500, minWidth: 350 }}>
                    <FilterInput
                        label={translate('searchUser')}
                        value={textFilter}
                        onChange={text => {
                            setTextFilter(text);
                        }}
                        placeholder={translate('userFilterPlaceholder')}
                        onKeyPress={handleKeyDown}
                    />
                </View>
                <View style={{ flex: 1 }}></View>
                <View style={styles.buttonsContainer}>
                    <UsersFilter setTextFilter={setTextFilter} textFilter={textFilter} filters={filters} setFilters={setFilters} authenticatedUser={authenticatedUser} />
                    <CustomButton icon={faPlus} text={translate('create')} onPress={() => {
                        setSelectedUser(undefined);
                        setModalVisible(true);
                    }} />
                </View>
            </View>
            <View style={{ flex: 1 }}>
                <View style={styles.cardList}>
                    <MyDataTable
                        columns={[
                            {
                                name: translate('registry'),
                                selector: row => row.registry,
                                wrap: true,
                            },
                            {
                                name: translate('name'),
                                selector: row => row.name,
                                wrap: true,
                                grow: 3
                            },
                            {
                                name: translate('email'),
                                selector: row => row.email,
                                wrap: true,
                                grow: 2
                            },
                            {
                                name: translate('actingBody'),
                                selector: row => row.role?.actingBody?.name || '',
                                wrap: true,
                            },
                            {
                                name: translate('unit'),
                                selector: row => row.actingBodyUnit?.name || '',
                                wrap: true,
                            },
                            {
                                name: translate('role'),
                                selector: row => row.role?.name || '',
                                wrap: true,
                            },
                            {
                                name: translate('tags'),
                                cell: row =>
                                    <View style={{ flexDirection: 'row', flexWrap: 'wrap', width: '100%', gap: 5, paddingVertical: 5 }}>
                                        {row.tags ?
                                            row.tags.map((tag) =>
                                                <View key={tag.id} style={[styles.marker, { backgroundColor: tag.color }]}>
                                                    <MyAppText style={styles.markerText(tag.color)}>
                                                        {tag.name}
                                                    </MyAppText>
                                                </View>
                                            ) : null
                                        }
                                    </View>
                            },
                            {
                                name: translate('action'),
                                button: true,
                                cell: row =>
                                    <View style={{ flexDirection: 'row', columnGap: 10 }}>
                                        <TouchableOpacity onPress={() => {
                                            setSelectedUser(row);
                                            setModalVisible(true);
                                        }}>
                                            <FontAwesomeIcon icon={faEdit} fontSize={16} color={theme.colors.iconColor} />
                                        </TouchableOpacity>
                                        <TouchableOpacity onPress={() => {
                                            setSelectedUser(row);
                                            setDeleteModalVisible(true);
                                        }}>
                                            <FontAwesomeIcon icon={faTrashCan} fontSize={16} color={theme.colors.iconColor} />
                                        </TouchableOpacity>
                                    </View>
                            }
                        ]}
                        data={users}
                        progressPending={isLoading}
                    />
                </View>
                <Pagination
                    totalItems={totalItems}
                    currentPage={filters.page}
                    pageSize={filters.limit}
                    setPageSize={pageSize => {
                        setFilters(old => ({
                            ...old,
                            limit: pageSize,
                            page: 0,
                        }));

                        navigation.setParams({
                            ...route.params,
                            limit: pageSize,
                            page: 0,
                        });
                    }}
                    setPage={page => {
                        setFilters(old => ({
                            ...old,
                            page
                        }));

                        navigation.setParams({
                            ...route.params,
                            page: page + 1
                        });
                    }}
                />
            </View>

            <DeleteModal
                setModalVisible={setDeleteModalVisible}
                isModalVisible={isDeleteModalVisible}
                itemName={String(selectedUser?.name) || ''}
                onSubmit={async () => {
                    if (selectedUser) {
                        await confirmDelete(selectedUser);
                    }
                }}
            />

            {/* Create/Edit Modal */}
            <Modal transparent={true} visible={isModalVisible} animationType='fade' onRequestClose={() => setModalVisible(false)}>
                <View style={styles.modalContainer}>
                    <View style={styles.centeredView}>
                        <UserModal
                            onClose={(success) => {
                                setModalVisible(false);
                                getUsers();
                                if (success) {
                                    Toast.show({
                                        type: 'sentinelxSuccess',
                                        text1: translate('ActionSuccessfully'),
                                    });
                                }
                            }}
                            userId={selectedUser?.id}
                        />
                    </View>
                </View>
            </Modal>
        </View>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flex: 1,
        rowGap: 15
    },
    headerContent: {
        flexDirection: 'row',
        minHeight: 65,
        zIndex: 2,
        flexWrap: 'wrap',
        gap: 5,
        justifyContent: 'space-between'
    },
    cardList: {
        borderWidth: 1,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderColor: theme.colors.dataTable.border,
        backgroundColor: theme.colors.dataTable.background,
        paddingHorizontal: 10,
        flex: 1,
    },
    buttonsContainer: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        gap: 10,
    },
    modalContainer: {
        flex: 1,
        backgroundColor: '#31313199'
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    marker: {
        borderRadius: 8,
        minHeight: 16,
        paddingLeft: 10,
        paddingRight: 10,
        maxWidth: '100%'
    },
    markerText: (tagColor: string) => ({
        fontWeight: '500',
        color: getBestContrastColor(tagColor),
        fontSize: 11
    }),
}));
