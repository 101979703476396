import React, { useMemo, useState } from 'react';
import { Image, Pressable, Text, View } from 'react-native';
import UserInformationModal from './UserInformationModal';
import { WindowInformation } from '../services/window-information';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faUser } from '@fortawesome/free-solid-svg-icons';
import { createStyleSheet, UnistylesRuntime, useStyles } from 'react-native-unistyles';
import { useAuth } from '../context/Auth';
import { NavigationProp } from '@react-navigation/native';
import { RootStackParamList } from '../typings/Params';

interface Props {
    navigation: NavigationProp<RootStackParamList> | Navigation;
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    showDropDownOnMobile?: boolean;
}

function TopMenu({ navigation, setOpen, showDropDownOnMobile }: Props) {
    const { styles, theme } = useStyles(stylesheet);
    const [showDropDown, setShowDropDown] = useState(false);
    const windowInfo = WindowInformation();
    const isSmartSampa = useMemo(() => window.location.hostname.includes('smartsampa'), [window.location]);

    const { user: authenticatedUser } = useAuth();

    return (
        <View style={styles.container}>
            <View style={styles.titleContainer}>
                {isSmartSampa && !windowInfo.isMobileOrTablet ?
                    <Image style={styles.image(400)} source={require(`../../assets/big_smartsampa_logo_${UnistylesRuntime.themeName}.png`)} />
                    :
                    <Image style={styles.image(200)} source={require(`../../assets/SentinelX_logo_${UnistylesRuntime.themeName}.png`)} />
                }
            </View>

            <Pressable onPress={() => {
                if (windowInfo.isMobile && !showDropDownOnMobile) {
                    setOpen?.(old => !old);
                } else {
                    setShowDropDown(!showDropDown);
                }
            }} style={styles.userContainer}>
                <>
                    {windowInfo.isMobile ?
                        showDropDownOnMobile ?
                            <View style={styles.userFrame}>
                                <FontAwesomeIcon icon={faUser} color='black' stroke='1.5' />
                            </View>
                            : <FontAwesomeIcon icon={faList} color={theme.colors.iconColor} size="xl" stroke='1.5' />
                        :
                        <>
                            <Text style={styles.title}>{authenticatedUser?.name}</Text>

                            <View style={styles.userFrame}>
                                <FontAwesomeIcon icon={faUser} color='black' stroke='1.5' />
                            </View>
                        </>
                    }
                </>
            </Pressable>

            <UserInformationModal acceptedTermsOfUse={authenticatedUser?.acceptedTermsOfUse || false}
                showDropDown={showDropDown} setShowDropDown={setShowDropDown} navigation={navigation} />
        </View>
    );
}

const stylesheet = createStyleSheet((theme) => ({
    container: {
        backgroundColor: theme.colors.topMenu,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignContent: 'center',
        height: '100%',
        width: '100%',
    },

    titleContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: 15
    },

    title: {
        color: '#717171',
        fontSize: 16
    },

    userContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
        padding: 15
    },

    userFrame: {
        width: 32,
        aspectRatio: 1,
        borderRadius: 50,
        backgroundColor: '#CCCCCC',
        justifyContent: 'center',
        alignItems: 'center',
    },

    image: (width) => ({
        height: 60,
        width,
        resizeMode: 'contain'
    }),
}));

export default TopMenu;
