import React from 'react';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { EventParamList } from '../../typings/Params';
import Filters from '../../components/Filter';
import { useState } from 'react';
import TypeDropdown from './TypeDropdown';
import PriorityDropdown from './PriorityDropdown';
import OccurrenceTypeDropdown from './OccurrenceTypeDropdown';
import TagsDropdown from './TagsDropdown';
import ActingBodiesDropdown from './ActingBodiesDropdown';
import FilterDatePicker from '../../components/Filter/components/FilterDateInput';
import { translate } from '../../services/translate';
import moment from 'moment';
import FilterCheckBox from '../../components/Filter/components/FilterCheckBox';
import { View } from 'react-native';
import MyAppText from '../../components/MyAppText';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

interface Props {
    filters: EventParamList['Events'];
    setFilters: React.Dispatch<React.SetStateAction<EventParamList['Events']>>;
    authenticatedUser?: AuthenticatedUser;
}

function EventsFilter({ filters, setFilters, authenticatedUser }: Props) {
    const navigation = useNavigation<NavigationProp<EventParamList, 'Events'>>();
    const { styles } = useStyles(styleSheet);

    const [selectedTriggerTypeFilter, setSelectedTriggerTypeFilter] = useState<TriggerType | 'all' | 'manual'>(filters.triggerType ?? 'all');
    const [selectedPriorityFilter, setSelectedPriorityFilter] = useState<OccurrencePriorities | 'all'>(filters.priority ?? 'all');
    const [selectedOccurrenceTypeFilter, setSelectedOccurrenceTypeFilter] = useState<number>(filters.occurrenceTypeId ?? 0);
    const [selectedTagsIds, setSelectedTagsIds] = useState<number[]>(filters.tags ?? []);
    const [selectedActingBodyId, setSelectedActingBodyId] = useState<number>(filters.actingBodyId ?? 0);
    const [beginInDateFilter, setBeginInDateFilter] = useState(filters.beginIn ? new Date(filters.beginIn) : null);
    const [beginInHourFilter, setBeginInHourFilter] = useState(filters.beginIn ? moment(filters.beginIn).format('HH:mm') : '');
    const [endInDateFilter, setEndInDateFilter] = useState(filters.endIn ? new Date(filters.endIn) : null);
    const [endInHourFilter, setEndInHourFilter] = useState(filters.endIn ? moment(filters.endIn).format('HH:mm') : '');
    const [withDispatch, setWithDispatch] = useState<boolean>(filters.withDispatch ?? false);
    const [withoutDispatch, setWithoutDispatch] = useState<boolean>(filters.withoutDispatch ?? false);

    const handleCleanFilter = () => {
        setSelectedOccurrenceTypeFilter(0);
        setSelectedActingBodyId(0);
        setSelectedPriorityFilter('all');
        setSelectedTriggerTypeFilter('all');
        setSelectedTagsIds([]);
        setBeginInDateFilter(null);
        setBeginInHourFilter('');
        setEndInDateFilter(null);
        setEndInHourFilter('');
        setWithDispatch(false);
        setWithoutDispatch(false);
    };

    const handleFilter = () => {
        const formatDateTime = (date: Date | null, time: string) =>
            date ? moment(`${moment(date).format('DD/MM/YYYY')} ${time}`, 'DD/MM/YYYY HH:mm:ss').valueOf() : undefined;
        const beginIn = formatDateTime(beginInDateFilter, beginInHourFilter ? `${beginInHourFilter}:00` : '00:00:00');
        const endIn = formatDateTime(endInDateFilter, endInHourFilter ? `${endInHourFilter}:59` : '23:59:59');

        setFilters({
            ...filters,
            priority: selectedPriorityFilter !== 'all' ? selectedPriorityFilter : undefined,
            triggerType: selectedTriggerTypeFilter !== 'all' ? selectedTriggerTypeFilter : undefined,
            occurrenceTypeId: selectedOccurrenceTypeFilter !== 0 ? selectedOccurrenceTypeFilter : undefined,
            tags: selectedTagsIds.length ? selectedTagsIds : undefined,
            actingBodyId: selectedActingBodyId !== 0 ? selectedActingBodyId : undefined,
            beginIn: beginIn && !isNaN(beginIn) ? beginIn : undefined,
            endIn: endIn && !isNaN(endIn) ? endIn : undefined,
            withDispatch: withDispatch ? withDispatch : undefined,
            withoutDispatch: withoutDispatch ? withoutDispatch : undefined
        });

        navigation.setParams({
            priority: selectedPriorityFilter !== 'all' ? selectedPriorityFilter : undefined,
            triggerType: selectedTriggerTypeFilter !== 'all' ? selectedTriggerTypeFilter : undefined,
            occurrenceTypeId: selectedOccurrenceTypeFilter !== 0 ? selectedOccurrenceTypeFilter : undefined,
            tags: selectedTagsIds.length ? selectedTagsIds : undefined,
            actingBodyId: selectedActingBodyId !== 0 ? selectedActingBodyId : undefined,
            beginIn: beginIn && !isNaN(beginIn) ? beginIn : undefined,
            endIn: endIn && !isNaN(endIn) ? endIn : undefined,
            withDispatch: withDispatch ? withDispatch : undefined,
            withoutDispatch: withoutDispatch ? withoutDispatch : undefined
        });

    };

    return (
        <Filters onCleanFilters={handleCleanFilter} onFilters={handleFilter}>
            <TypeDropdown value={selectedTriggerTypeFilter} setValue={setSelectedTriggerTypeFilter} zIndex={8} />
            <PriorityDropdown value={selectedPriorityFilter} setValue={setSelectedPriorityFilter} zIndex={7} />
            <OccurrenceTypeDropdown value={selectedOccurrenceTypeFilter} setValue={setSelectedOccurrenceTypeFilter} zIndex={6} />
            <TagsDropdown value={selectedTagsIds} setValue={setSelectedTagsIds} zIndex={5} />
            <FilterDatePicker
                date={beginInDateFilter}
                onChangeDate={setBeginInDateFilter}
                time={beginInHourFilter}
                onChangeTime={setBeginInHourFilter}
                label={translate('beginIn')}
                zIndex={4}
            />
            <FilterDatePicker
                date={endInDateFilter}
                onChangeDate={setEndInDateFilter}
                time={endInHourFilter}
                onChangeTime={setEndInHourFilter}
                label={translate('endIn')}
                zIndex={3}
            />
            <View>
                <MyAppText style={styles.filterLabel}>{translate('withDispatch')}</MyAppText>
                <View style={{ flexDirection: 'row' }}>
                    <FilterCheckBox
                        label={translate('yes')}
                        value={withDispatch}
                        onChange={(value) => {
                            setWithDispatch(value);
                            if (value) {
                                setWithoutDispatch(!value);
                            }
                        }}
                    />
                    <FilterCheckBox
                        label={translate('no')}
                        value={withoutDispatch}
                        onChange={(value) => {
                            setWithoutDispatch(value);
                            if (value) {
                                setWithDispatch(!value);
                            }
                        }}
                    />
                </View>
            </View>

            {(authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_manage_all_acting_bodies_occurrences) &&
                <ActingBodiesDropdown value={selectedActingBodyId} setValue={setSelectedActingBodyId} zIndex={2} />
            }
        </Filters>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    filterLabel: {
        color: theme.colors.labelColor,
        fontSize: 16,
        height: 25,
    },
}));

export default EventsFilter;
