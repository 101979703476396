import React, { useEffect, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import MyAppText from '../../../components/MyAppText';
import { translate } from '../../../services/translate';
import { useNavigationState } from '@react-navigation/native';
import { licensePlateRecognitionService } from '../../../services/central-api/license-plate-recognition';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import { Tabs } from '../../../components/Tabs';
import DetectionDetails from './DetectionDetails';
import Detections from './Detections';
import DetectionRoutes from './DetectionRoutes';
import GoBackModal from './GoBackModal';

interface DetectionProps {
    navigation: Navigation;
    route: {
        params: {
            detectionId: string;
        };
    };
    page: 'live-events' | 'live-alerts' | 'search' | 'map';
}

export default function Detection({ navigation, route, page }: DetectionProps) {
    const { styles, theme } = useStyles(styleSheet);

    const [detection, setDetection] = useState<LprDetection>();
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [detectionRoutes, setDetectionRoutes] = useState<{ name: string; cameras: CameraMap[]; }[]>([]);

    const index = useNavigationState(state => state);

    async function getDetection(detectionId: string) {
        try {
            const detection = await licensePlateRecognitionService.getLprDetection(Number(detectionId));
            setDetection(detection);
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        getDetection(route.params.detectionId);
    }, [route]);


    function goBack() {
        if (index.routes.length > 1) {
            return navigation.goBack();
        } else {
            if (page == 'live-alerts') {
                window.location.href = '/LPR/live-alerts';
            }
            if (page == 'live-events') {
                window.location.href = '/LPR/live-events';
            }
            if (page == 'search') {
                window.location.href = '/LPR/search';
            }
            if (page == 'map' && detection) {
                window.location.href = `/cameras/map/${detection.camera.id}`;
            }
        }
    }

    if (!detection) {
        return <></>;
    }

    return (
        <View style={styles.container}>
            <View style={styles.header}>
                <TouchableOpacity onPress={() => {
                    if (detectionRoutes.length) {
                        setIsModalVisible(true);
                    } else {
                        goBack();
                    }
                }}>
                    <FontAwesomeIcon fontSize={22} icon={faArrowLeft} color={theme.colors.iconColor} />
                </TouchableOpacity>
                <MyAppText style={styles.headerText}>
                    {translate('detection')}
                </MyAppText>
            </View>
            <View style={styles.cardList}>
                <Tabs tabs={[{
                    key: 'vehicleData',
                    label: translate('vehicleData')
                }, {
                    key: 'detections',
                    label: translate('detections')
                }, {
                    key: 'routes',
                    label: translate('routes')
                }]}>
                    {({ selectedTab }) => {
                        if (selectedTab == 'vehicleData') {
                            return (<DetectionDetails navigation={navigation} detection={detection} onSave={() => getDetection(route.params.detectionId)} />);
                        } else if (selectedTab == 'detections') {
                            return (<Detections setDetectionRoutes={setDetectionRoutes} detectionRoutes={detectionRoutes} detection={detection} />);
                        } else if (selectedTab == 'routes') {
                            return (<DetectionRoutes detectionRoutes={detectionRoutes} />);
                        }
                    }}
                </Tabs>
            </View>
            <GoBackModal
                isModalVisible={isModalVisible}
                setModalVisible={setIsModalVisible}
                onConfirm={() => goBack()}
            />
        </View>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flex: 1,
        rowGap: 15
    },
    header: {
        flexDirection: 'row',
        minHeight: 65,
        zIndex: 2,
        flexWrap: 'wrap',
        gap: 5,
        alignItems: 'center'
    },
    headerText: {
        fontSize: 24,
        color: theme.colors.labelColor,
        fontWeight: 'bold'
    },
    cardList: {
        borderWidth: 1,
        borderRadius: 8,
        borderColor: theme.colors.dataTable.border,
        backgroundColor: theme.colors.dataTable.background,
        padding: 15,
        flex: 1,
    }
}));
