import React from 'react';
import {
    View,
    TouchableOpacity,
    ScrollView,
    ActivityIndicator,
    Image
} from 'react-native';

import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faVideo } from '@fortawesome/free-solid-svg-icons';
import { translate } from '../../services/translate';
import MyAppText from '../../components/MyAppText';
import personDefaultPicture from '../../../assets/person-default-picture.png';
import { Hoverable } from 'react-native-web-hover';
import { faCalendarCheck } from '@fortawesome/free-regular-svg-icons';
import LazyLoad from 'react-lazy-load';
import getBestContrastColor from '../../services/best-contrast-color';
import { createStyleSheet, useStyles } from 'react-native-unistyles';


interface EventListProps {
    events: FacialDetection[];
    navigation: Navigation;
    hasMoreResults: boolean;
    isLoading: boolean;
    isPolling: boolean;
    loadMore?: () => void;
    module: 'facial' | 'smart-search-facial';
    page: 'live-events' | 'live-alerts' | 'search';
}


export default function EventList({ events, navigation, isLoading, isPolling, hasMoreResults, loadMore, module, page }: EventListProps) {
    const { styles, theme } = useStyles(stylesheet);

    return (
        <View style={styles.container}>
            <View style={styles.cardList}>
                <View style={{ rowGap: 7 }}>
                    <View style={styles.header}>
                        <View style={{ flexBasis: '10%' }}>
                            <MyAppText style={styles.headerCell}>{translate('detection')}</MyAppText>
                        </View>
                        <View style={{ flexBasis: '10%' }}>
                            <MyAppText style={styles.headerCell}>{translate('reference')}</MyAppText>
                        </View>
                        <View style={{ flexBasis: '60%' }}>
                            <MyAppText style={styles.headerCell}>{translate('information')}</MyAppText>
                        </View>
                        <View style={{ flexBasis: '20%' }}>
                            <MyAppText style={styles.headerCell}>{translate('location')}</MyAppText>
                        </View>
                    </View>
                    <View style={styles.tableLine}></View>
                </View>
                <ScrollView>
                    <View style={{ gap: 10 }}>
                        {isPolling || isLoading ?
                            <View>
                                <ActivityIndicator size='small' color={theme.colors.loadingColor} />
                            </View>
                            : null
                        }
                        {events.length ?
                            events.map((event, eventIndex) => (

                                <TouchableOpacity onPress={() => {
                                    if (module == 'smart-search-facial' && page == 'search') {
                                        return navigation.navigate('SmartSearchEventDetail', {
                                            eventId: event.id,
                                        });
                                    }
                                    if (module == 'facial' && page == 'live-alerts') {
                                        return navigation.navigate('LiveAlertEventDetail', {
                                            eventId: event.id,
                                        });
                                    }
                                    if (module == 'facial' && page == 'live-events') {
                                        return navigation.navigate('LiveEventDetail', {
                                            eventId: event.id,
                                        });
                                    }
                                    if (module == 'facial' && page == 'search') {
                                        return navigation.navigate('SearchEventDetail', {
                                            eventId: event.id,
                                        });
                                    }
                                }} key={eventIndex} style={{ rowGap: 9 }}>
                                    <View style={styles.row}>
                                        <View style={{ flexBasis: '10%' }}>
                                            <LazyLoad>
                                                <img
                                                    style={{
                                                        height: 100,
                                                        width: 90,
                                                        borderRadius: 5,
                                                        overflow: 'hidden',
                                                        filter: page === 'live-events' || page === 'live-alerts' ? 'blur(5px)' : '',
                                                    }}
                                                    src={event.imageUrl}
                                                />
                                            </LazyLoad>
                                        </View>
                                        <View style={{ flexBasis: '10%' }}>
                                            <LazyLoad>
                                                <Image
                                                    style={{
                                                        height: 100,
                                                        width: 90,
                                                        borderRadius: 5,
                                                        overflow: 'hidden',
                                                    }}
                                                    source={event.person?.facePictures.length ? { uri: event.person.facePictures[0].imageUrl } : personDefaultPicture}
                                                />
                                            </LazyLoad>
                                        </View>
                                        <View style={{ flexBasis: '60%', rowGap: 10 }}>
                                            {event.person
                                                ? <>
                                                    <MyAppText style={{ color: theme.colors.fieldColor, fontWeight: '500', fontSize: 20 }}>
                                                        {event.person.name}
                                                    </MyAppText>
                                                    <View style={{ flexDirection: 'row' }}>
                                                        {event.person.markers.map((marker) =>
                                                            <View key={JSON.stringify(marker)} style={[styles.marker, { backgroundColor: marker.color }]}>
                                                                <MyAppText style={styles.markerText(marker.color)}>
                                                                    {marker.name}
                                                                </MyAppText>
                                                            </View>
                                                        )}
                                                    </View>
                                                </>
                                                : null
                                            }
                                            {event.confidence &&
                                                <View style={styles.locationView} >
                                                    <MyAppText style={styles.locationText}>
                                                        {translate('confidence')}: {(Number(event.confidence) * 100).toFixed(0)}%
                                                    </MyAppText>
                                                </View>
                                            }
                                        </View>
                                        <View style={{ flexBasis: '20%', rowGap: 10 }}>
                                            <View style={styles.locationView} >
                                                <FontAwesomeIcon icon={faCalendarCheck} fontSize={13} style={styles.locationIcon} />
                                                <MyAppText style={styles.locationText}>
                                                    {moment(new Date(event.time)).format('DD/MM/YYYY HH:mm:ss')}
                                                </MyAppText>
                                            </View>

                                            <View style={styles.locationView} >
                                                <FontAwesomeIcon icon={faLocationDot} fontSize={13} style={styles.locationIcon} />
                                                <MyAppText style={styles.locationText}>
                                                    {event.camera.address}
                                                </MyAppText>
                                            </View>

                                            <View style={styles.locationView} >
                                                <FontAwesomeIcon icon={faVideo} fontSize={13} style={styles.locationIcon} />
                                                <MyAppText style={styles.locationText}>
                                                    {event.camera.title}
                                                </MyAppText>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={styles.tableLine}></View>
                                </TouchableOpacity >
                            ))
                            : null
                        }

                        {events.length && hasMoreResults && !isLoading && !isPolling ?
                            <Hoverable style={styles.loadMore}>
                                {({ hovered }) => (
                                    <TouchableOpacity style={[styles.loadMore, {
                                        borderBottomWidth: hovered ? 1 : 0
                                    }]} onPress={loadMore}>
                                        <MyAppText style={styles.loadMoreText}>{translate('loadMore')}...</MyAppText>
                                    </TouchableOpacity>

                                )}
                            </Hoverable>
                            : null
                        }

                        {!events.length && !isLoading ?
                            <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                                <MyAppText style={{ color: theme.colors.labelColor, marginTop: 10 }}>
                                    {translate('noResults')}.
                                </MyAppText>
                            </View>
                            : null
                        }
                    </View>
                </ScrollView>
            </View>
        </View>);
}

const stylesheet = createStyleSheet(theme => ({
    container: {
        flex: 1,
        rowGap: 15,
        zIndex: -9999
    },
    header: {
        flexDirection: 'row',
    },
    headerCell: {
        fontSize: 13,
        color: theme.colors.labelColor
    },
    locationView: {
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: 5
    },
    locationIcon: {
        color: theme.colors.textColor,
    },
    locationText: {
        color: theme.colors.cardDetailText,
        fontWeight: '500',
    },
    row: {
        flexDirection: 'row',
        rowGap: 15
    },
    loadMoreText: {
        color: theme.colors.primaryButton.background,
        fontSize: 16,
    },
    loadMore: {
        borderColor: theme.colors.primaryButton.background,
        alignItems: 'center',
        justifyContent: 'center'
    },
    marker: {
        borderRadius: 8,
        height: 16,
        paddingLeft: 10,
        paddingRight: 10,
        marginRight: 8,
    },
    markerText: (tagColor: string) => ({
        fontWeight: '500',
        color: getBestContrastColor(tagColor),
        fontSize: 11
    }),
    cardList: {
        borderWidth: 1,
        borderRadius: 8,
        borderColor: theme.colors.borderColor,
        padding: 24,
        backgroundColor: theme.colors.backgroundColor,
        gap: 20,
        flex: 1
    },
    tableLine: {
        borderBottomColor: theme.colors.borderColor,
        borderBottomWidth: 1,
    },
}));
