import UnitsModal from './UnitsModal';
import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';
import { translate } from '../../../services/translate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MyAppText from '../../../components/MyAppText';
import { Pagination } from '../../../components/Pagination';
import { dispatchService } from '../../../services/central-api/dispatch';
import MyDataTable from '../../../components/MyDataTable';
import { faEdit, faEye, faFlagCheckered, faPause, faPlay, faPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import Toast from 'react-native-toast-message';
import { ClientError } from '../../../services/central-api/base-service';
import { RouteProp, useRoute } from '@react-navigation/native';
import { DispatchParamList } from '../../../typings/Params';
import moment from 'moment';
import UnitsModalReadOnly from './UnitsModalReadOnly';
import UnitsFilter from './UnitsFilter';
import useFilters from '../../../components/Filter/hooks/useFilters';
import FilterInput from '../../../components/Filter/components/FilterInput';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import CustomButton from '../../../components/CustomButton';
import FinishTeamModal from './FinishTeamModal';
import UnitPauseModal from './UnitPauseModal';

export default function Units({ navigation, authenticatedUser }: { navigation: Navigation; authenticatedUser?: AuthenticatedUser; }) {
    const { styles, theme } = useStyles(styleSheet);
    const route = useRoute<RouteProp<DispatchParamList, 'Units'>>();

    const [isModalVisible, setModalVisible] = useState(false);
    const [isReadOnlyModalVisible, setIsReadOnlyModalVisible] = useState(false);
    const [earlyFinishModal, setEarlyFinishModal] = useState(false);
    const [pauseModal, setPauseModal] = useState(false);
    const [selectedUnit, setSelectedUnit] = useState<PaginatedUnit>();
    const [units, setUnits] = useState<PaginatedUnit[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [assignments, setAssignments] = useState<DropdownResource[]>([]);

    const [filters, setFilters] = useFilters<DispatchParamList['Units']>({
        ...route.params,
        page: Math.max(+route.params.page - 1, 0),
        limit: Number(route.params.limit) || 25,
    });
    const [totalItems, setTotalItems] = useState(0);

    const [teamFilter, setTeamFilter] = useState(filters.teamFilter ?? '');

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == 'Enter') {
            navigation.setParams({
                ...filters,
                teamFilter
            });
            setFilters(old => ({
                ...old,
                teamFilter
            }));
        }
    }

    async function getUnits(): Promise<void> {
        try {
            setIsLoading(true);
            const response = await dispatchService.getPaginatedUnits(filters);
            setUnits(response.rows);
            setTotalItems(response.count);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getUnits();
        getAssignments();
    }, [filters]);

    async function getAssignments() {
        try {
            const assignments = await dispatchService.getSimplifiedAssignments();
            setAssignments(assignments);
        } catch (err) {
            console.error(err);
        }
    }

    const renderUnitContent = (unit: PaginatedUnit) => {
        const renderUnitUsers = () => {
            if (!unit.UnitUsers || unit.UnitUsers.length === 0) return null;
            return unit.UnitUsers.map((user, i) => {
                const assignmentName = assignments.find((assignment) => assignment.id === user.UnitUser.assignmentId)?.name || '';
                const userStatus = unit.users.findIndex(x => x.id === user.id) !== -1 ? translate('active') : translate('notActive');

                return (
                    <View key={i} style={{ flexDirection: 'row', alignItems: 'center', columnGap: 10 }}>
                        <FontAwesomeIcon icon={faUser} fontSize={16} color={theme.colors.iconColor} />
                        <MyAppText>{user.warName || user.name}</MyAppText> / <MyAppText>{user.role.name}</MyAppText>
                        {assignmentName ? <MyAppText>/  {assignmentName}</MyAppText> : <></>}
                        <MyAppText style={{ fontSize: 12, color: theme.colors.iconColor }} >({userStatus})</MyAppText>
                    </View>
                );
            });
        };

        return (
            <View style={{ paddingVertical: 10, paddingLeft: 50, flexDirection: 'column', rowGap: 10 }}>
                {renderUnitUsers()}
            </View>
        );
    };

    const renderStatus = (unit: PaginatedUnit) => {
        let customStyle = styles.green;
        let customName = 'active';

        if (unit.paused) {
            customName = 'operationalPause';
            customStyle = styles.purple;
        } else if (unit.displaced) {
            customName = 'displaced';
            customStyle = styles.turquoise;
        } else if (unit.definitiveStart && unit.definitiveFinish) {
            customName = 'finished';
            customStyle = styles.grey;
        } else if (!unit.definitiveStart && !unit.definitiveFinish && moment(unit.estimatedStart).toDate().getTime() > moment().toDate().getTime()) {
            customName = 'waitingActivation';
            customStyle = styles.yellow;
        } else if (!unit.definitiveStart && !unit.definitiveFinish && moment(unit.estimatedStart).toDate().getTime() < moment().toDate().getTime()) {
            customName = 'waitingActivationBeforeSchedule';
            customStyle = styles.orange;
        } else if (unit.definitiveStart && !unit.definitiveFinish && moment(unit.estimatedFinish).toDate().getTime() < moment().toDate().getTime()) {
            customName = 'activeAfterSchedule';
            customStyle = styles.red;
        }

        return <View style={styles.status}>
            <View style={[styles.circle, customStyle]} />
            <MyAppText>{translate(customName)}</MyAppText>
        </View>;
    };

    async function unpauseUnit(unit: PaginatedUnit) {
        try {
            if (unit.paused) {
                await dispatchService.pauseUnpauseUnit(unit.id, 'unpause');
                Toast.show({
                    type: 'sentinelxSuccess',
                    text1: translate('ActionSuccessfully'),
                });
                getUnits();
            }
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    const renderPauseButton = (unit: PaginatedUnit) => {
        if ((authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_pause_units) && !unit.displaced) {
            if (unit.definitiveStart && !unit.definitiveFinish && !unit.paused) {
                return <TouchableOpacity onPress={async () => {
                    setSelectedUnit(unit);
                    setPauseModal(true);
                }} style={styles.pauseButton}>
                    <FontAwesomeIcon fontSize={16} icon={faPause} color={theme.colors.backgroundColor} />
                    <MyAppText style={styles.pauseText}>{translate('pause')}</MyAppText>
                </TouchableOpacity>;
            } else if (unit.paused) {
                return <TouchableOpacity onPress={async () => {
                    await unpauseUnit(unit);
                }} style={styles.pauseButton}>
                    <FontAwesomeIcon fontSize={14} icon={faPlay} color={theme.colors.backgroundColor} />
                    <MyAppText style={styles.pauseText}>{translate('unpause')}</MyAppText>
                </TouchableOpacity>;
            }
            return <></>;
        }
        return <></>;
    };

    return (
        <View style={styles.container}>
            <View style={styles.headerContent}>
                <View style={{ flexGrow: 1, maxWidth: 500, minWidth: 350 }}>
                    <FilterInput
                        label={translate('searchUnit')}
                        value={teamFilter}
                        placeholder={translate('typeToSearch')}
                        onChange={text => {
                            setTeamFilter(text);
                        }}
                        onKeyPress={handleKeyDown}
                    />
                </View>
                <View style={{ flex: 1 }}></View>
                <View style={styles.buttonsContainer}>
                    <UnitsFilter
                        authenticatedUser={authenticatedUser}
                        filters={filters}
                        setFilters={setFilters}
                        teamFilter={teamFilter}
                        setTeamFilter={setTeamFilter} />

                    <CustomButton icon={faPlus} text={translate('create')} onPress={() => {
                        setSelectedUnit(undefined);
                        setModalVisible(true);
                    }} />
                </View>
            </View>
            <View style={{ flex: 1 }}>
                <View style={styles.cardList}>
                    <MyDataTable
                        columns={[
                            {
                                name: translate('Id'),
                                selector: row => row.id,
                                grow: 0
                            },
                            {
                                name: translate('name'),
                                selector: row => row.unitName?.name || '',
                                grow: 1,
                                wrap: true
                            },
                            {
                                name: translate('actingBody'),
                                selector: row => row.actingBodyUnit?.actingBody?.name || '',
                                wrap: true
                            },
                            {
                                name: translate('command'),
                                selector: row => row.actingBodyCommand?.name || '',
                                wrap: true
                            },
                            {
                                name: translate('unit'),
                                selector: row => row.actingBodyUnit?.name || '',
                                wrap: true
                            },
                            {
                                name: translate('estimatedStart'),
                                selector: row => moment(row.estimatedStart).format('DD/MM/YYYY HH:mm:ss'),
                                wrap: true,
                                maxWidth: '200px'
                            },
                            {
                                name: translate('estimatedFinish'),
                                selector: row => moment(row.estimatedFinish).format('DD/MM/YYYY HH:mm:ss'),
                                wrap: true,
                                maxWidth: '200px'
                            },
                            {
                                name: translate('status'),
                                cell: row =>
                                    <View style={{ width: '100%' }}>
                                        {renderStatus(row)}
                                    </View>
                            },
                            {
                                name: '',
                                cell: row =>
                                    <View style={{ width: '100%' }}>
                                        {renderPauseButton(row)}
                                    </View>
                            },
                            {
                                name: translate('action'),
                                button: true,
                                cell: row =>
                                    <View style={{ flexDirection: 'row', columnGap: 10 }}>
                                        <View>
                                            {!row.definitiveFinish ?
                                                <View style={{ flexDirection: 'row', columnGap: 10 }}>
                                                    <TouchableOpacity onPress={() => {
                                                        setSelectedUnit(row);
                                                        setModalVisible(true);
                                                    }}>
                                                        <FontAwesomeIcon icon={faEdit} fontSize={16} color={theme.colors.iconColor} />
                                                    </TouchableOpacity>
                                                    <TouchableOpacity onPress={() => {
                                                        setSelectedUnit(row);
                                                        setEarlyFinishModal(true);
                                                    }}>
                                                        <FontAwesomeIcon icon={faFlagCheckered} fontSize={16} color={theme.colors.iconColor} />
                                                    </TouchableOpacity>
                                                </View> :
                                                <View style={{ flexDirection: 'row', columnGap: 10 }}>
                                                    <TouchableOpacity onPress={() => {
                                                        setSelectedUnit(row);
                                                        setIsReadOnlyModalVisible(true);
                                                    }}>
                                                        <FontAwesomeIcon icon={faEye} fontSize={16} color={theme.colors.iconColor} />
                                                    </TouchableOpacity>
                                                </View>
                                            }
                                        </View>
                                    </View>
                            }
                        ]}
                        data={units}
                        progressPending={isLoading}
                        expandableRowsComponent={({ data }) => renderUnitContent(data)}
                        expandableRows={filters.status == 'finished' ? false : true}
                    />
                </View>
                <Pagination
                    currentPage={filters.page}
                    totalItems={totalItems}
                    setPage={page => {
                        setFilters(old => ({
                            ...old,
                            page
                        }));

                        navigation.setParams({
                            ...route.params,
                            page: page + 1
                        });
                    }}
                    pageSize={filters.limit}
                    setPageSize={pageSize => {
                        setFilters(old => ({
                            ...old,
                            limit: pageSize,
                            page: 0,
                        }));

                        navigation.setParams({
                            ...route.params,
                            limit: pageSize,
                            page: 0,
                        });
                    }}
                />
            </View>

            <UnitsModal
                onClose={() => {
                    setSelectedUnit(undefined);
                    setModalVisible(false);
                    getUnits();
                }}
                isModalVisible={isModalVisible}
                setModalVisible={setModalVisible}
                unitId={selectedUnit?.id}
                authenticatedUser={authenticatedUser}
            />
            <FinishTeamModal
                isModalVisible={earlyFinishModal}
                setModalVisible={setEarlyFinishModal}
                unit={selectedUnit}
                onClose={() => {
                    setSelectedUnit(undefined);
                    setEarlyFinishModal(false);
                    getUnits();
                }}
            />
            <UnitPauseModal
                isModalVisible={pauseModal}
                setModalVisible={setPauseModal}
                unit={selectedUnit}
                onClose={() => {
                    setSelectedUnit(undefined);
                    setPauseModal(false);
                    getUnits();
                }}
            />
            <UnitsModalReadOnly
                onClose={() => {
                    setSelectedUnit(undefined);
                    setIsReadOnlyModalVisible(false);
                    getUnits();
                }}
                unitId={selectedUnit?.id}
                isModalVisible={isReadOnlyModalVisible}
                setModalVisible={setIsReadOnlyModalVisible}
            />
        </View>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flex: 1,
        rowGap: 15
    },
    headerContent: {
        flexDirection: 'row',
        minHeight: 65,
        zIndex: 2,
        flexWrap: 'wrap',
        gap: 5,
        justifyContent: 'space-between'
    },
    cardList: {
        borderWidth: 1,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderColor: theme.colors.dataTable.border,
        backgroundColor: theme.colors.dataTable.background,
        paddingHorizontal: 10,
        flex: 1,
    },
    buttonsContainer: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        gap: 10,
    },
    circle: {
        width: 15,
        height: 15,
        borderRadius: 7.5,
    },
    green: {
        backgroundColor: 'green',
    },
    yellow: {
        backgroundColor: '#FFD700',
    },
    red: {
        backgroundColor: 'red',
    },
    orange: {
        backgroundColor: 'orange'
    },
    grey: {
        backgroundColor: 'grey'
    },
    purple: {
        backgroundColor: 'purple'
    },
    turquoise: {
        backgroundColor: 'turquoise'
    },
    status: {
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: 5
    },
    pauseButton: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.colors.primaryButton.background,
        borderRadius: 2,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        gap: 5,
        padding: 5,
        minWidth: 100,
        maxWidth: 100,
        maxHeight: 35
    },
    pauseText: {
        color: theme.colors.backgroundColor,
        fontSize: 12,
    },
}));

