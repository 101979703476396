import React, { useContext, useEffect, useRef, useState } from 'react';
import { ScrollView, View, TouchableOpacity, StyleSheet, Modal } from 'react-native';
import { translate } from '../../../services/translate';
import MyAppText from '../../MyAppText';
import { faLocationDot, faSearchPlus, faTimes, faVideo, faPlay, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faCalendarCheck } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import ThemeContext from '../../../context/Theme';
import getThemedColor from '../../../services/get-themed-color';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import OccurrenceVideoModal from './OccurrenceVideoModal';
import { dispatchService } from '../../../services/central-api/dispatch';
import { Hoverable } from 'react-native-web-hover';
import { WindowInformation } from '../../../services/window-information';

export default function OccurrenceAreaInvasion({
    occurrence, navigation
}: {
    occurrence: Occurrence;
    navigation: Navigation;
}) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const modalCanvasRef = useRef<HTMLCanvasElement>(null);
    const [isVideoModalVisible, setVideoModalVisible] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const windowInfo = WindowInformation();
    const [currentImage, setCurrentImage] = useState<{
        imageUrl: string;
        detections: {
            objectType: 'human';
            objectRect: { x: number; y: number; width: number; height: number; };
            regionPoints: { x: number; y: number; }[];
        }[];
    }>({ imageUrl: '', detections: [] });

    if (occurrence.triggerType != 'area_invasion') {
        return <></>;
    }
    const [currentImagePage, setCurrentImagePage] = useState(0);

    useEffect(() => {
        setCurrentImage({
            imageUrl: occurrence.resume.imageUrl,
            detections: occurrence.resume.detections
        });
    }, []);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        const image = new Image();

        image.onload = () => {
            canvas.width = image.width;
            canvas.height = image.height;
            ctx.drawImage(image, 0, 0);

            for (const detection of occurrence.resume.detections) {
                ctx.strokeStyle = 'red';
                ctx.lineWidth = 4;
                ctx.rect(detection.objectRect.x, detection.objectRect.y, detection.objectRect.width, detection.objectRect.height);
                ctx.stroke();

                ctx.strokeStyle = 'yellow';
                ctx.lineWidth = 4;
                ctx.beginPath();
                ctx.setLineDash([15, 15]);

                ctx.moveTo(detection.regionPoints[0].x, detection.regionPoints[0].y);
                for (let i = 1; i < detection.regionPoints.length; i++) {
                    ctx.lineTo(detection.regionPoints[i].x, detection.regionPoints[i].y);
                }
                ctx.closePath();
                ctx.stroke();
            }
        };

        image.src = occurrence.resume.imageUrl;
    });

    useEffect(() => {
        const canvas = modalCanvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        const image = new Image();

        image.onload = () => {
            canvas.width = image.width;
            canvas.height = image.height;
            ctx.drawImage(image, 0, 0);

            for (const detection of currentImage.detections) {
                ctx.strokeStyle = 'red';
                ctx.lineWidth = 4;
                ctx.rect(detection.objectRect.x, detection.objectRect.y, detection.objectRect.width, detection.objectRect.height);
                ctx.stroke();

                ctx.strokeStyle = 'yellow';
                ctx.lineWidth = 4;
                ctx.beginPath();
                ctx.setLineDash([15, 15]);

                ctx.moveTo(detection.regionPoints[0].x, detection.regionPoints[0].y);
                for (let i = 1; i < detection.regionPoints.length; i++) {
                    ctx.lineTo(detection.regionPoints[i].x, detection.regionPoints[i].y);
                }
                ctx.closePath();
                ctx.stroke();
            }
        };

        image.src = currentImage.imageUrl;
    }, [currentImage, modalCanvasRef, isModalVisible]);

    return (
        <>
            <ScrollView>
                <View style={{ gap: 20 }}>
                    <Hoverable>
                        {({ hovered }) => (<>
                            <canvas style={{ width: '100%' }} ref={canvasRef} />

                            {hovered &&
                                <View style={{
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: '#00000080',
                                }}>
                                    <TouchableOpacity
                                        style={{
                                            flex: 1,
                                            justifyContent: 'center'
                                        }}
                                        onPress={async () => {
                                            setIsModalVisible(true);
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faSearchPlus} fontSize={28} color={'#FFFFFF'} />
                                    </TouchableOpacity>
                                </View>
                            }

                        </>)}
                    </Hoverable>
                    <View style={{ gap: 3 }}>
                        <MyAppText style={styles.plateText}>
                            {translate('visualizedOn')}
                        </MyAppText>
                        <View style={styles.visualizedRow} >
                            <FontAwesomeIcon icon={faPlay} style={{ width: 22 }} color={getThemedColor(theme, '#58595B')} />
                            <TouchableOpacity onPress={() => setVideoModalVisible(true)}>
                                <MyAppText style={styles.watchVideoText}>
                                    {translate('watchVideo')}
                                </MyAppText>
                            </TouchableOpacity>
                        </View>
                        <View style={styles.visualizedRow} >
                            <FontAwesomeIcon icon={faVideo} style={{ width: 14 }} color={getThemedColor(theme, '#58595B')} />
                            <TouchableOpacity onPress={() => {
                                navigation.navigate('CameraPlayer', { id: occurrence.externalReference?.id });
                            }}>
                                <MyAppText style={styles.detectionInfoText}>
                                    {occurrence.externalReference?.title}
                                </MyAppText>
                            </TouchableOpacity>
                        </View>
                        <View style={styles.visualizedRow} >
                            <FontAwesomeIcon icon={faCalendarCheck} style={{ width: 14 }} color={getThemedColor(theme, '#58595B')} />
                            <MyAppText style={styles.detectionInfoText}>
                                {moment(new Date(occurrence.resume.time)).format('DD/MM/YYYY HH:mm:ss')}
                            </MyAppText>
                        </View>
                        <View style={styles.visualizedRow} >
                            <FontAwesomeIcon icon={faLocationDot} style={{ width: 14 }} color={getThemedColor(theme, '#58595B')} />
                            <MyAppText style={styles.detectionInfoText}>
                                {occurrence.externalReference?.address}
                            </MyAppText>
                        </View>
                    </View>
                </View >
                <OccurrenceVideoModal
                    occurrenceId={occurrence.id || 0}
                    isModalVisible={isVideoModalVisible}
                    setModalVisible={setVideoModalVisible}
                    occurrenceTime={new Date(occurrence.resume.time).getTime()}
                    apiService={dispatchService}
                />

                {/* Zoom image modal */}
                <Modal
                    animationType='fade'
                    transparent={true}
                    visible={isModalVisible}
                    onRequestClose={() => setIsModalVisible(false)}>
                    <View style={styles.modalContainer}>
                        <View style={styles.centeredView}>
                            <View style={[styles.modalBody, windowInfo.isMobile ? { width: '95%' } : { width: '60%' }]}>
                                <View style={{ alignItems: 'flex-end' }}>
                                    <TouchableOpacity
                                        style={{ width: 20, height: 20 }}
                                        onPress={() => setIsModalVisible(false)}
                                    >
                                        <FontAwesomeIcon icon={faTimes} style={{ width: 20 }} color={getThemedColor(theme, '#58595B')} />
                                    </TouchableOpacity>
                                </View>

                                <View style={{ flexDirection: 'row', gap: 5 }}>
                                    <TouchableOpacity
                                        style={{ alignSelf: 'center' }}
                                        onPress={() => {
                                            if (currentImagePage <= 0) {
                                                return;
                                            }
                                            setCurrentImagePage(currentImagePage - 1);
                                            setCurrentImage({
                                                imageUrl: occurrence.occurrenceImages.at(currentImagePage - 1)?.resume.imageUrl || '',
                                                detections: occurrence.occurrenceImages.at(currentImagePage - 1)?.resume.detections || [],
                                            });
                                        }}>
                                        <FontAwesomeIcon icon={faArrowLeft} color={getThemedColor(theme, '#222222')} />
                                    </TouchableOpacity>
                                    <TransformWrapper>
                                        <TransformComponent>
                                            <canvas style={{ width: '100%' }} ref={modalCanvasRef} />
                                        </TransformComponent>
                                    </TransformWrapper>
                                    <TouchableOpacity
                                        style={{ alignSelf: 'center' }}
                                        onPress={() => {
                                            if (currentImagePage >= occurrence.occurrenceImages.length - 1) {
                                                return;
                                            }
                                            setCurrentImagePage(currentImagePage + 1);
                                            setCurrentImage({
                                                imageUrl: occurrence.occurrenceImages.at(currentImagePage + 1)?.resume.imageUrl || '',
                                                detections: occurrence.occurrenceImages.at(currentImagePage + 1)?.resume.detections || [],
                                            });
                                        }}>
                                        <FontAwesomeIcon icon={faArrowRight} color={getThemedColor(theme, '#222222')} />
                                    </TouchableOpacity>
                                </View>
                                <MyAppText style={{ alignItems: 'center', textAlign: 'center' }}>{currentImagePage + 1}/{occurrence.occurrenceImages.length}</MyAppText>
                            </View>
                        </View>
                    </View>
                </Modal>
            </ScrollView>
            {occurrence.occurrenceImages.length > 1 ?
                <MyAppText style={{
                    position: 'absolute',
                    top: 5,
                    right: 5,
                    backgroundColor: '#D50000',
                    color: '#FFFFFF',
                    borderRadius: 10,
                    minWidth: 20,
                    height: 20,
                    paddingHorizontal: 3,
                    textAlign: 'center',
                }}>{occurrence.occurrenceImages.length}</MyAppText>
                : null}
        </>
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        visualizedRow: {
            flexDirection: 'row',
            alignItems: 'center',
            gap: 5
        },
        detectionInfoText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 12
        },
        plateText: {
            color: getThemedColor(theme, '#222222'),
            fontWeight: 'bold',
            fontSize: 14
        },
        plateInfoText: {
            color: getThemedColor(theme, '#222222'),
            fontWeight: 'bold',
            fontSize: 24
        },
        watchVideoText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 16,
            textDecorationLine: 'underline'
        },
        modalContainer: {
            flex: 1,
            backgroundColor: '#31313199',
        },
        centeredView: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        modalBody: {
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            flexDirection: 'column',
            borderRadius: 8,
            rowGap: 20,
            minWidth: 300,
            minHeight: 300,
            padding: 30,
        },
    });
}
