import React from 'react';
import Detection from '../lpr/Detection/Detection';

interface DetectionProps {
    navigation: Navigation;
    route: {
        params: {
            detectionId: string;
        };
    };
}

export default function MapLprDetection({ navigation, route }: DetectionProps) {
    return <Detection navigation={navigation} route={route} page={'map'} />;
}
