import React, { useEffect, useState } from 'react';
import {
    View,
    TouchableOpacity,
    FlatList
} from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';

import MyAppText from '../../../components/MyAppText';
import { translate } from '../../../services/translate';
import moment from 'moment';
import { crmService } from '../../../services/central-api/crm';
import ActMaintenanceRequestModal from './ActMaintenanceRequestModal';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import MaintenanceRequestModal from './MaintenanceRequestModal';

export default function MaintenanceRequests() {
    const { styles, theme } = useStyles(stylesheet);
    const [maintenanceRequests, setMaintenanceRequests] = useState<MaintenanceRequestSimplified[]>([]);

    const [pendingList, setPendingList] = useState<MaintenanceRequestSimplified[]>([]);
    const [ongoingList, setOngoingList] = useState<MaintenanceRequestSimplified[]>([]);
    const [concludedList, setConcludedList] = useState<MaintenanceRequestSimplified[]>([]);

    const [selectedEventId, setSelectedEventId] = useState(0);
    const [isCameraHistoryModalVisible, setIsCameraHistoryModalVisible] = useState(false);
    const [isActMaintenanceRequestModalVisible, setIsActMaintenanceRequestModalVisible] = useState(false);

    async function getEvents(): Promise<void> {
        try {
            const maintenanceRequests = await crmService.getMaintenanceRequests();
            setMaintenanceRequests(maintenanceRequests);
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        getEvents();
        const interval = setInterval(() => {
            if (!window.location.href.split('?')[0].endsWith('CRM/maintenance-requests')) {
                return;
            }
            getEvents();
        }, 30000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const pendingList = maintenanceRequests.filter((item) => !item.finish && !item.responsibleUser);
        const ongoingList = maintenanceRequests.filter((item) => !item.finish && item.responsibleUser);
        const concludedList = maintenanceRequests.filter((item) => item.finish);
        setPendingList(pendingList);
        setOngoingList(ongoingList);
        setConcludedList(concludedList);

    }, [maintenanceRequests]);

    function renderItem({ item }: { item: MaintenanceRequestSimplified; }) {
        return (
            <TouchableOpacity onPress={async () => {
                setSelectedEventId(item.id);

                if (!item.finish && !item.responsibleUser) {
                    return setIsActMaintenanceRequestModalVisible(true);
                }
                setIsCameraHistoryModalVisible(true);
            }}>
                <View style={styles.card(item.priority)}>
                    <View style={{ minWidth: 130, flex: 1 }}>
                        <MyAppText style={styles.itemLeftTitle}>
                            {item.camera.serialNo ? item.camera.serialNo : item.camera.title}
                        </MyAppText>
                        <MyAppText style={styles.itemLeftDate}>{translate('beginIn')}: {moment(item.start).format('DD/MM/YYYY HH:mm:ss')}</MyAppText>
                        <MyAppText style={styles.itemLeftDate}>{translate('endIn')}: {item.finish ? moment(item.finish).format('DD/MM/YYYY HH:mm:ss') : ''}</MyAppText>
                        <View style={styles.itemLeftUser} >
                            <FontAwesomeIcon icon={faUserCircle} fontSize={16} color={theme.colors.cardDetailText} />
                            <MyAppText style={styles.itemLeftUserText}>{item.responsibleUser?.name ? item.responsibleUser.warName || item.responsibleUser.name : translate('noResponsible')}</MyAppText>
                        </View>
                    </View>
                </View>

            </TouchableOpacity >
        );
    }

    function getListView(itemsList: MaintenanceRequestSimplified[], text: string) {
        return <View style={styles.list}>
            <View style={styles.listHeader}>
                <MyAppText style={styles.listHeaderText}>{translate(text)}</MyAppText>
                <MyAppText style={styles.listHeaderTotal}>{itemsList.length}</MyAppText>
            </View>
            <View style={styles.listItemsContainer}>
                <FlatList
                    data={itemsList}
                    keyExtractor={(item) => item?.id?.toString() || '0'}
                    renderItem={renderItem}
                    contentContainerStyle={{ gap: 10 }}
                />
            </View>
        </View>;
    }

    return (
        <>
            <View style={styles.container}>
                {getListView(pendingList, 'pending')}
                {getListView(ongoingList, 'ongoing')}
                {getListView(concludedList, 'concluded')}
            </View>
            <ActMaintenanceRequestModal
                maintenanceRequestId={selectedEventId}
                isVisible={isActMaintenanceRequestModalVisible}
                setIsVisible={setIsActMaintenanceRequestModalVisible}
                onSubmit={() => {
                    getEvents();
                }}
            />
            <MaintenanceRequestModal
                maintenanceRequestId={selectedEventId}
                isVisible={isCameraHistoryModalVisible}
                setIsVisible={setIsCameraHistoryModalVisible}
                onSubmit={() => {
                    getEvents();
                }}
            />
        </>
    );
}

const stylesheet = createStyleSheet(theme => ({
    container: {
        flex: 1,
        flexDirection: 'row',
        gap: 30
    },
    list: {
        flex: 1,
    },
    listHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 50,
    },
    listHeaderText: {
        fontSize: 20,
        fontWeight: 'bold',
        color: theme.colors.labelColor,
    },
    listHeaderTotal: {
        fontSize: 20,
        color: theme.colors.labelColor,
    },
    listItemsContainer: {
        flex: 1
    },
    card: (priority) => {
        return {
            borderWidth: 1,
            borderRadius: 8,
            borderColor: priority ? theme.colors.red : theme.colors.cardBorder,
            padding: 8,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: theme.colors.cardBackground,
            minHeight: 85,
            flexWrap: 'wrap',
            gap: 5
        };
    },
    itemLeftContent: {
        flex: 1,
        flexGrow: 5,
    },
    itemLeftTitle: {
        fontSize: 15,
        color: theme.colors.textColor
    },
    itemLeftDate: {
        fontSize: 12,
        color: theme.colors.cardDetailText
    },
    itemLeftUser: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 5
    },
    itemLeftUserText: {
        fontSize: 12,
        color: theme.colors.cardDetailText,
    },
    itemPriority: {
        justifyContent: 'center',
        alignItems: 'center',
        width: 64,
        height: 64,
    },
    itemPriorityText: {
        fontSize: 12
    },
    itemMaximumPriorityText: {
        fontSize: 12,
        color: theme.colors.red,
    },
    image: {
        width: 64,
        height: 64,
        borderRadius: 4,
        objectFit: 'contain',
    },
    facialBlurImage: {
        width: 64,
        height: 64,
        borderRadius: 4,
        objectFit: 'contain',
        filter: 'blur(5px)'
    },
    imageBox: {
        height: 64,
        width: 64,
        minWidth: 64,
        borderRadius: 4,
        justifyContent: 'center',
        alignItems: 'center'
    },
    imageInvasion: {
        width: 128,
        minWidth: 128,
        height: 64,
        borderRadius: 4,
        objectFit: 'contain',
    },
    imageBoxInvasion: {
        height: 64,
        width: 128,
        minWidth: 128,
        borderRadius: 4,
        justifyContent: 'center',
        alignItems: 'center'
    }
}));
