import React, { useContext, useEffect, useState } from 'react';
import { Modal, StyleSheet, TouchableOpacity, View } from 'react-native';
import { translate } from '../../services/translate';
import ThemeContext from '../../context/Theme';
import getThemedColor from '../../services/get-themed-color';
import MyAppText from '../../components/MyAppText';
import Toast from 'react-native-toast-message';
import { ClientError } from '../../services/central-api/base-service';
import PasswordValidator from 'password-validator';
import PasswordInput from '../../components/passwordInput';
import { centralAPI } from '../../services/central-api';

export default function Password() {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const [isFormValid, setIsFormValid] = useState(false);
    const [isModalVisible, setModalVisible] = useState(false);
    const [isValidMin, setIsValidMin] = useState(false);
    const [isValidDigits, setIsValidDigits] = useState(false);
    const [isValidUppercase, setIsValidUppercase] = useState(false);
    const [isValidLowercase, setIsValidLowercase] = useState(false);

    const validator = new PasswordValidator();
    validator.is().min(8).has().uppercase().has().lowercase().has().digits(2);

    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');


    useEffect(() => {
        if (oldPassword === '' || password === '' || confirmPassword === '') {
            return setIsFormValid(false);
        }

        if (confirmPassword !== password && (oldPassword !== '' || password !== '' || confirmPassword !== '')) {
            return setIsFormValid(false);
        }

        if (!validator.validate(password)) {
            return setIsFormValid(false);
        }

        return setIsFormValid(true);
    }, [oldPassword, password, confirmPassword]);

    useEffect(() => {
        const missingValidations = validator.validate(password, { list: true }) as string[];
        setIsValidMin(!missingValidations.includes('min'));
        setIsValidUppercase(!missingValidations.includes('uppercase'));
        setIsValidLowercase(!missingValidations.includes('lowercase'));
        setIsValidDigits(!missingValidations.includes('digits'));
    }, [password]);

    async function save() {
        try {
            await centralAPI.updateOwnUser({
                oldPassword,
                password,
                confirmPassword
            });
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
            window.location.href = '/login';

        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    return (
        <>
            <View style={styles.container}>
                <View style={styles.header}>
                    <TouchableOpacity
                        onPress={() => setModalVisible(true)}
                        disabled={!isFormValid}
                        style={[{
                            borderWidth: 0,
                            borderRadius: 2,
                            backgroundColor: getThemedColor(theme, '#000028'),
                            width: 128,
                            height: 40,
                            justifyContent: 'center',
                        }, !isFormValid ? styles.buttonDisabled : null]}
                    >
                        <MyAppText style={[{ color: getThemedColor(theme, '#FFFFFF'), alignSelf: 'center', fontSize: 16 }, !isFormValid ? styles.textDisabled : null]}>
                            {translate('save')}
                        </MyAppText>
                    </TouchableOpacity>
                </View>
                <View style={{ flex: 1 }}>
                    <View style={styles.cardList}>
                        <View style={{ rowGap: 20, maxWidth: 500 }}>
                            <PasswordInput
                                label={translate('oldPassword')}
                                placeholder=''
                                value={oldPassword}
                                onChangeText={setOldPassword}
                                invalid={() => false}
                            />
                            <View style={{ gap: 10 }}>
                                <MyAppText style={[styles.label, { fontSize: 16 }]}>{translate('passwordRequirements')}</MyAppText>
                                <View style={{ gap: 5 }}>
                                    <MyAppText style={[styles.label, password === '' ? null : isValidMin ? styles.valid : styles.invalid]}>{`\u2022 ${translate('atLeast8Characters')}`}</MyAppText>
                                    <MyAppText style={[styles.label, password === '' ? null : isValidDigits ? styles.valid : styles.invalid]}>{`\u2022 ${translate('atLeast2Digits')}`}</MyAppText>
                                    <MyAppText style={[styles.label, password === '' ? null : isValidUppercase ? styles.valid : styles.invalid]}>{`\u2022 ${translate('atLeastOneUppercase')}`}</MyAppText>
                                    <MyAppText style={[styles.label, password === '' ? null : isValidLowercase ? styles.valid : styles.invalid]}>{`\u2022 ${translate('atLeastOneLowercase')}`}</MyAppText>
                                </View>
                            </View>
                            <PasswordInput
                                label={translate('newPassword')}
                                placeholder=''
                                value={password}
                                onChangeText={setPassword}
                                invalid={() => false}
                            />
                            <PasswordInput
                                label={translate('confirmNewPassword')}
                                placeholder=''
                                value={confirmPassword}
                                onChangeText={setConfirmPassword}
                                invalid={() => password != confirmPassword}
                                errorMessage={translate('passwordsNotEqual')}
                            />
                        </View>
                    </View>
                </View>
            </View>
            <Modal visible={isModalVisible} animationType='fade' onRequestClose={() => setModalVisible(false)} transparent={true}>
                <View style={styles.modalContainer}>
                    <View style={styles.modalBody}>
                        <MyAppText style={styles.confirmationText}>{translate('resetPasswordClearSessions')}</MyAppText>
                        <View style={styles.buttonsView}>
                            <TouchableOpacity style={[styles.button, styles.buttonClose]} onPress={() => setModalVisible(false)}>
                                <MyAppText style={styles.buttonTextClose}>{translate('cancel')}</MyAppText>
                            </TouchableOpacity>
                            <TouchableOpacity style={[styles.button]} onPress={() => {
                                save();
                                setModalVisible(false);
                            }}>
                                <MyAppText style={styles.buttonTextDelete}>{translate('confirm')} </MyAppText>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </Modal>
        </>
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            flex: 1,
            rowGap: 15
        },
        header: {
            flexDirection: 'row',
            alignItems: 'flex-end',
            columnGap: 10,
            minHeight: 65,
            justifyContent: 'flex-end',
        },
        headerText: {
            fontSize: 24,
            color: getThemedColor(theme, '#58595B'),
            fontWeight: 'bold'
        },
        cardList: {
            borderWidth: 1,
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            padding: 24,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            flex: 1,
        },
        buttonDisabled: {
            borderWidth: 0,
            backgroundColor: getThemedColor(theme, '#CCCCCC')
        },
        textDisabled: {
            color: getThemedColor(theme, '#888888')
        },
        confirmationText: {
            fontSize: 20
        },
        modalContainer: {
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#31313199',
            flex: 1
        },
        modalBody: {
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            flexDirection: 'column',
            borderRadius: 4,
            padding: 25,
            rowGap: 20
        },
        buttonClose: {
            borderWidth: 1,
            borderColor: getThemedColor(theme, '#CCCCCC'),
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
        },
        buttonTextClose: {
            fontSize: 16,
            color: getThemedColor(theme, '#58595B'),
        },
        buttonsView: {
            flexDirection: 'row',
            alignItems: 'center',
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
            gap: 20
        },
        button: {
            borderRadius: 4,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: 120,
            height: 40,
            backgroundColor: getThemedColor(theme, '#000028'),
        },
        buttonTextDelete: {
            fontSize: 16,
            color: getThemedColor(theme, '#FFFFFF'),
        },
        label: {
            color: getThemedColor(theme, '#58595B'),
        },
        valid: {
            color: getThemedColor(theme, '#008E2C'),
        },
        invalid: {
            color: '#FF0027',
        }
    });
}
