import React, { useEffect, useState, useCallback, useContext } from 'react';
import {
    View,
    StyleSheet,
    TouchableOpacity,
    TextInput,
    NativeSyntheticEvent,
    TextInputKeyPressEventData,
} from 'react-native';


import moment from 'moment';
import { translate } from '../../services/translate';
import MyAppText from '../../components/MyAppText';
import MyDropDownPicker from '../../components/MyDropDownPicker';
import { MyCheckbox } from '../../components/MyCheckbox';
import ThemeContext from '../../context/Theme';
import getThemedColor from '../../services/get-themed-color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { GetLprDetectionsParameters, licensePlateRecognitionService } from '../../services/central-api/license-plate-recognition';
import EventList from '../../components/lpr/EventList';
import { RouteProp, useRoute } from '@react-navigation/native';
import { LPRParamList } from '../../typings/Params';

interface SearchParams {
    navigation: Navigation;
    authenticatedUser?: AuthenticatedUser;
}


export default function Search({ navigation }: SearchParams) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);

    const route = useRoute<RouteProp<LPRParamList, 'LprSearch'>>();

    const [hasMoreResults, setHasMoreResults] = useState(false);
    const [isCameraPickerOpen, setIsCameraPickerOpen] = useState(false);
    const [filters, setFilters] = useState<GetLprDetectionsParameters>({
        page: Math.max(route.params.page - 1, 0),
        limit: 100,
        cameras: route.params.cameras ?? [],
        irregularSituation: route.params.irregularSituation,
        beggingIn: route.params.beggingIn ? new Date(route.params.beggingIn).toISOString() : undefined,
        endIn: route.params.endIn ? new Date(route.params.endIn).toISOString() : undefined,
        isMotorcycle: route.params.isMotorcycle,
        plate: route.params.plate
    });
    const [, updateState] = useState({});
    const forceUpdate = useCallback(() => updateState({}), []);

    const [beggingInDateFilter, setBeggingInDateFilter] = useState(filters.beggingIn ? moment(filters.beggingIn).format('DD/MM/YYYY') : '');
    const [beggingInHourFilter, setBeggingInHourFilter] = useState(filters.beggingIn ? moment(filters.beggingIn).format('HH:mm') : '');
    const [endInDateFilter, setEndInDateFilter] = useState(filters.endIn ? moment(filters.endIn).format('DD/MM/YYYY') : '');
    const [endInHourFilter, setEndInHourFilter] = useState(filters.endIn ? moment(filters.endIn).format('HH:mm') : '');
    const [plateFilter, setPlateFilter] = useState(filters.plate);
    const [irregularSituationFilter, setIrregularSituationFilter] = useState(filters.irregularSituation || false);
    const [isMotorcycleFilter, setIsMotorcycleFilter] = useState(filters.isMotorcycle || false);
    const [selectedCameraFilter, setSelectedCameraFilter] = useState<string[]>(filters.cameras ?? []);
    const [selectableCameraFilter, setSelectableCameraFilter] = useState<{ label: string, value: string; }[]>([]);

    async function getCameras() {
        try {
            const cameras = await licensePlateRecognitionService.getCameras();

            setSelectableCameraFilter(cameras.map((camera) => {
                return {
                    value: camera.id,
                    label: camera.title
                };
            }));
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getCameras();
    }, []);

    useEffect(() => {
        getDetections();
    }, [filters]);

    const [isLoading, setIsLoading] = useState(false);
    const [occurrences, setOccurrences] = useState<LprDetection[]>([]);

    async function getDetections() {
        try {
            setIsLoading(true);

            const newOccurrences = await licensePlateRecognitionService.getDetections(filters);
            if (newOccurrences.length < filters.limit) {
                setHasMoreResults(false);
            } else {
                setHasMoreResults(true);
            }
            if (filters.page == 0) {
                occurrences.length = 0;
            }

            occurrences.push(...newOccurrences);
            setOccurrences(occurrences);
            forceUpdate();
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }

    }

    function useFilters() {
        setIsCameraPickerOpen(false);

        const beggingIn = moment(`${beggingInDateFilter} ${beggingInHourFilter}`, 'DD/MM/YYYY HH:mm').valueOf();
        const endIn = moment(`${endInDateFilter} ${endInHourFilter}:59`, 'DD/MM/YYYY HH:mm:ss').valueOf();

        setFilters({
            ...filters,
            page: 0,
            plate: plateFilter,
            cameras: selectedCameraFilter,
            irregularSituation: irregularSituationFilter,
            isMotorcycle: isMotorcycleFilter,
            beggingIn: beggingIn ? new Date(beggingIn).toISOString() : undefined,
            endIn: endIn ? new Date(endIn).toISOString() : undefined,
        });

        navigation.setParams({
            page: 1,
            plate: plateFilter,
            irregularSituation: irregularSituationFilter,
            isMotorcycle: isMotorcycleFilter,
            beggingIn: beggingIn || undefined,
            endIn: endIn || undefined,
            cameras: selectedCameraFilter
        });
    }

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == 'Enter') {
            useFilters();
        }
    }

    return (
        <View style={styles.container}>
            <View style={styles.header}>
                <View style={{ flex: 1, minWidth: 200, zIndex: 2 }}>
                    <MyAppText style={styles.filterText}>{translate('cameras')}</MyAppText>
                    <MyDropDownPicker
                        open={isCameraPickerOpen}
                        value={selectedCameraFilter}
                        items={selectableCameraFilter}
                        setOpen={setIsCameraPickerOpen}
                        setValue={setSelectedCameraFilter}
                        multiple={true}
                        mode='BADGE'
                        searchable={true}
                        borderColor='#888'
                    />
                </View>
                <View>
                    <MyAppText style={styles.filterText}>{translate('plate')}</MyAppText>
                    <TextInput
                        style={styles.filterInput}
                        value={plateFilter}
                        onKeyPress={handleKeyDown}
                        onChangeText={(value) => setPlateFilter(value.toUpperCase())}
                    />
                </View>
                <View style={{ maxWidth: 190, minWidth: 190 }}>
                    <MyAppText style={styles.filterText}>{translate('beginIn')}</MyAppText>
                    <View style={{ flexDirection: 'row', columnGap: 5 }}>
                        <TextInput
                            style={[styles.filterInput, { maxWidth: 115 }]}
                            value={beggingInDateFilter}
                            onKeyPress={handleKeyDown}
                            onChangeText={(value) => setBeggingInDateFilter(value)}
                        />
                        <TextInput
                            style={[styles.filterInput, { maxWidth: 70 }]}
                            value={beggingInHourFilter}
                            onKeyPress={handleKeyDown}
                            onChangeText={(value) => setBeggingInHourFilter(value)}
                        />
                    </View>
                </View>
                <View style={{ maxWidth: 190, minWidth: 190 }}>
                    <MyAppText style={styles.filterText}>{translate('endIn')}</MyAppText>
                    <View style={{ flexDirection: 'row', columnGap: 5 }}>
                        <TextInput
                            style={[styles.filterInput, { maxWidth: 115 }]}
                            value={endInDateFilter}
                            onKeyPress={handleKeyDown}
                            onChangeText={(value) => setEndInDateFilter(value)}
                        />
                        <TextInput
                            style={[styles.filterInput, { maxWidth: 70 }]}
                            value={endInHourFilter}
                            onKeyPress={handleKeyDown}
                            onChangeText={(value) => setEndInHourFilter(value)}
                        />
                    </View>
                </View>
                <View style={{ justifyContent: 'flex-end' }}>
                    <View style={{ flexDirection: 'column' }}>
                        <MyCheckbox
                            style={{ height: 25 }}
                            label={translate('irregularSituation')}
                            checked={irregularSituationFilter}
                            setChecked={setIrregularSituationFilter}
                        />
                        <MyCheckbox
                            style={{ height: 25 }}
                            label={translate('motorcycleOnly')}
                            checked={isMotorcycleFilter}
                            setChecked={setIsMotorcycleFilter}
                        />
                    </View>
                </View>
                <View>
                    <MyAppText style={styles.filterText} />
                    <TouchableOpacity onPress={useFilters} style={styles.filterButton}>
                        <FontAwesomeIcon icon={faSearch} fontSize={16} color={getThemedColor(theme, '#FFFFFF')} />
                        <MyAppText style={styles.filterButtonText}>{translate('toSearch')}</MyAppText>
                    </TouchableOpacity>
                </View>
            </View>
            <EventList
                events={occurrences}
                navigation={navigation}
                loadMore={() => setFilters({ ...filters, page: filters.page + 1 })}
                hasMoreResults={hasMoreResults}
                isLoading={isLoading}
                isPolling={false}
                page='search'
            />
        </View>
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        filterText: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 16,
            height: 25,
            paddingBottom: 5
        },
        filterInput: {
            fontFamily: 'Open Sans',
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderWidth: 1,
            borderRadius: 4,
            borderColor: getThemedColor(theme, '#888888'),
            height: 40,
            minHeight: 40,
            fontSize: 16,
            color: getThemedColor(theme, '#222222'),
            padding: 10
        },
        filterButton: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: 30,
            height: 40,
            backgroundColor: getThemedColor(theme, '#000028'),
            borderRadius: 2,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        },
        filterButtonText: {
            color: getThemedColor(theme, '#FFFFFF'),
            fontSize: 16,
            paddingLeft: 10
        },
        container: {
            flex: 1,
            rowGap: 15
        },
        header: {
            minHeight: 65,
            flexDirection: 'row',
            columnGap: 30,
            flexWrap: 'wrap',
            rowGap: 10
        }
    });
}
