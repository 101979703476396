import React, { useContext, useEffect, useState } from 'react';
import { centralAPI } from '../services/central-api';
import { ScrollView, View, StyleSheet, TouchableOpacity } from 'react-native';
import RenderHtml from 'react-native-render-html';
import getThemedColor from '../services/get-themed-color';
import ThemeContext from '../context/Theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import MyAppText from '../components/MyAppText';
import { translate } from '../services/translate';
import { useNavigationState } from '@react-navigation/native';
import UserInformationModal from '../components/UserInformationModal';
import { useAuth } from '../context/Auth';

interface SourceProps {
    html: string;
}

export default function TermsOfUse({ navigation }: { navigation: Navigation; }) {
    const { theme } = useContext(ThemeContext);
    const { user: authenticatedUser } = useAuth();
    const index = useNavigationState(state => state);
    const [showDropDown, setShowDropDown] = useState(false);
    const [source, setSource] = useState<SourceProps>({
        html: ''
    });

    const styles = getStyles(theme);

    async function getTermsOfUse() {
        try {
            const termsOfUse = await centralAPI.getTermsOfUse();
            setSource({ html: termsOfUse.termsOfUse });
        } catch (err) {
            console.error(err);
        }
    }

    function goBack() {

        if (index.routes.length > 1) {
            return navigation.goBack();
        }

        window.location.href = '/menu';
    }

    async function acceptTermsOfUse() {
        try {
            await centralAPI.acceptTermsOfUse();
            window.location.href = '/menu';
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        getTermsOfUse();
    }, []);

    return (
        <>
            <View style={styles.container}>
                <View style={styles.header}>
                    <View style={{ alignItems: 'center', flexDirection: 'row', flexGrow: 1, minWidth: 200, columnGap: 10 }}>
                        {!authenticatedUser || authenticatedUser.acceptedTermsOfUse ?
                            <TouchableOpacity onPress={goBack}>
                                <FontAwesomeIcon fontSize={22} icon={faArrowLeft} color={getThemedColor(theme, '#000028')} />
                            </TouchableOpacity> : null
                        }
                        <MyAppText style={{ fontSize: 24, color: getThemedColor(theme, '#58595B'), fontWeight: 'bold' }}>
                            {translate('termsOfUse')}
                        </MyAppText>
                    </View>
                </View>
                <View style={styles.cardList}>
                    <ScrollView >
                        <View style={{ flex: 1, maxWidth: 1000, alignSelf: 'center' }}>
                            <RenderHtml
                                source={source}
                                baseStyle={{
                                    color: getThemedColor(theme, '#58595B'),
                                }}
                            />
                            {authenticatedUser && !authenticatedUser.acceptedTermsOfUse ?
                                <TouchableOpacity
                                    style={{
                                        backgroundColor: getThemedColor(theme, '#008E2C'),
                                        borderRadius: 2,
                                        height: 40,
                                        justifyContent: 'center',
                                    }}
                                    onPress={acceptTermsOfUse}
                                >
                                    <MyAppText style={{
                                        color: getThemedColor(theme, '#FFFFFF'),
                                        alignSelf: 'center',
                                        fontSize: 16
                                    }}>{translate('iAcceptTermsOfUse')}</MyAppText>
                                </TouchableOpacity>
                                : null}
                        </View>
                    </ScrollView>
                </View>
            </View>
            <UserInformationModal acceptedTermsOfUse={authenticatedUser?.acceptedTermsOfUse || false}
                showDropDown={showDropDown} setShowDropDown={setShowDropDown} navigation={navigation} />
        </>
    );
}


function getStyles(theme: Theme) {

    return StyleSheet.create({
        container: {
            rowGap: 15,
            flex: 1,
        },
        header: {
            flexDirection: 'row',
            alignItems: 'center',
            gap: 10,
            flexWrap: 'wrap',
            minHeight: 65
        },
        cardList: {
            borderWidth: 1,
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            padding: 24,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            flex: 1,
        },
    });
}
