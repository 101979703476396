import React from 'react';
import LateralMenu from '../LateralMenu';
import { faClipboardList, faIdBadge, faSearch, faTag, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { translate } from '../../services/translate';
import logo from '../../../assets/logo_facial.png';
import collapsedLogo from '../../../assets/facial_icon.png';

export default function FacialLateralMenu({ navigation, open, authenticatedUser, setOpen, selectedMenu }: LateralMenuProps) {

    return (
        <LateralMenu
            topImage={logo}
            collapsedTopImage={collapsedLogo}
            open={open}
            authenticatedUser={authenticatedUser}
            setOpen={setOpen}
            items={[{
                icon: faClipboardList,
                text: translate('liveEvents'),
                action: () => navigation.navigate('Facial', { screen: 'LiveEvents' }),
                selected: selectedMenu == 'live-events'
            }, {
                icon: faTriangleExclamation,
                text: translate('liveAlerts'),
                action: () => navigation.navigate('Facial', { screen: 'LiveAlerts' }),
                selected: selectedMenu == 'live-alerts'
            }, {
                icon: faSearch,
                text: translate('search'),
                action: () => navigation.navigate('Facial', { screen: 'Search' }),
                selected: selectedMenu == 'search'
            }, {
                icon: faIdBadge,
                text: translate('register'),
                action: () => navigation.navigate('Facial', { screen: 'PeopleList' }),
                selected: selectedMenu == 'people'
            }, {
                icon: faTag,
                text: translate('markers'),
                action: () => navigation.navigate('Facial', { screen: 'Markers' }),
                selected: selectedMenu == 'markers'
            }]}
        />
    );
}
