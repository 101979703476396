import React from 'react';
import { OccurrenceDetail } from '../../components/occurrence/OccurrenceDetail';


interface EventsOccurrenceDetailParams {
    navigation: Navigation;
    authenticatedUser?: AuthenticatedUser;
    route: {
        params: {
            occurrenceId: string;
        };
    };
}

export default function EventsOccurrenceDetail({ navigation, route, authenticatedUser }: EventsOccurrenceDetailParams) {
    return (
        <OccurrenceDetail navigation={navigation} authenticatedUser={authenticatedUser} occurrenceId={route.params.occurrenceId} page={'events'} />
    );
}
