import React from 'react';
import Detection from './Detection';

interface DetectionProps {
    navigation: Navigation;
    route: {
        params: {
            detectionId: string;
        };
    };
}

export default function LiveAlertLprDetection({ navigation, route }: DetectionProps) {
    return <Detection navigation={navigation} route={route} page={'live-alerts'} />;
}
