import React from 'react';
import LateralMenu from '../LateralMenu';
import { faBuilding, faCog, faIdBadge, faRecycle, faSitemap, faTag, faToolbox, faUserGroup, faVideo, faWifi } from '@fortawesome/free-solid-svg-icons';
import { translate } from '../../services/translate';
import logo from '../../../assets/logo_access.png';
import collapsedLogo from '../../../assets/access_icon.png';

export default function AccessLateralMenu({ navigation, authenticatedUser, open, setOpen, selectedMenu }: LateralMenuProps) {
    const items = [];

    if (authenticatedUser?.isAdmin || authenticatedUser?.permissions.access_edit_user_settings) {
        items.push({
            icon: faIdBadge,
            text: translate('users'),
            action: () => navigation.navigate('Access', { screen: 'Users' }),
            selected: selectedMenu == 'users'
        });
    }

    if (authenticatedUser?.isAdmin || authenticatedUser?.permissions.access_management_user_tags) {
        items.push({
            icon: faTag,
            text: translate('userTags'),
            action: () => navigation.navigate('Access', { screen: 'UserTags' }),
            selected: selectedMenu == 'user-tags'
        });
    }

    if (authenticatedUser?.isAdmin || authenticatedUser?.permissions.access_edit_permission_level_settings) {
        items.push({
            icon: faUserGroup,
            text: translate('permissionLevels'),
            action: () => navigation.navigate('Access', { screen: 'PermissionLevels' }),
            selected: selectedMenu == 'permission-levels'
        });
    }

    if (authenticatedUser?.isAdmin || authenticatedUser?.permissions.access_edit_group_settings) {
        items.push({
            icon: faVideo,
            text: translate('groups'),
            action: () => navigation.navigate('Access', { screen: 'Groups' }),
            selected: selectedMenu == 'groups'
        });
    }

    if (authenticatedUser?.isAdmin) {
        items.push({
            icon: faBuilding,
            text: translate('actingBodies'),
            action: () => navigation.navigate('Access', { screen: 'ActingBodies' }),
            selected: selectedMenu == 'acting-bodies'
        }, {
            icon: faToolbox,
            text: translate('installationCompanies'),
            action: () => navigation.navigate('Access', { screen: 'InstallationCompanies' }),
            selected: selectedMenu == 'installation-companies'
        }, {
            icon: faWifi,
            text: translate('internetCompanies'),
            action: () => navigation.navigate('Access', { screen: 'InternetCompanies' }),
            selected: selectedMenu == 'internet-companies'
        }, {
            icon: faSitemap,
            text: translate('ptzPriority'),
            action: () => navigation.navigate('Access', { screen: 'PtzPriority' }),
            selected: selectedMenu == 'ptz-priority'
        });
    }

    if (authenticatedUser?.isAdmin || authenticatedUser?.permissions.access_edit_turnstile) {
        items.push({
            icon: faRecycle,
            text: translate('turnstiles'),
            action: () => navigation.navigate('Access', { screen: 'Turnstiles' }),
            selected: selectedMenu == 'turnstiles'
        });
    }

    if (authenticatedUser?.isAdmin || authenticatedUser?.permissions.access_edit_platform_settings) {
        items.push({
            icon: faCog,
            text: translate('platformSettings'),
            action: () => navigation.navigate('Access', { screen: 'PlatformSettings' }),
            selected: selectedMenu == 'platform-settings'
        });
    }

    return (
        <LateralMenu
            topImage={logo}
            collapsedTopImage={collapsedLogo}
            open={open}
            setOpen={setOpen}
            items={items}
            authenticatedUser={authenticatedUser}
        />
    );
}
