import React, { useContext, useEffect, useState } from 'react';
import { View, TouchableOpacity, StyleSheet, Modal } from 'react-native';
import MosaicEditModal from './MosaicEditModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan, faEdit } from '@fortawesome/free-regular-svg-icons';

import { translate } from '../../services/translate';
import getThemedColor from '../../services/get-themed-color';
import ThemeContext from '../../context/Theme';
import DeleteModal from '../../components/DeleteModal';
import Toast from 'react-native-toast-message';
import { Pagination } from '../../components/Pagination';
import { cameraService } from '../../services/central-api/cameras';
import MyDataTable from '../../components/MyDataTable';
import { ClientError } from '../../services/central-api/base-service';
import { RouteProp, useRoute } from '@react-navigation/native';
import { CamerasParamList } from '../../typings/Params';
import CustomButton from '../../components/CustomButton';

export default function Mosaics({ navigation }: { navigation: Navigation; }) {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const route = useRoute<RouteProp<CamerasParamList, 'Mosaic'>>();

    const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
    const [mosaic, setMosaic] = useState<Mosaic>();
    const [mosaicsList, setMosaicsList] = useState<Mosaic[]>([]);
    const [isModalVisible, setModalVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [filters, setFilters] = useState({
        page: Math.max(route.params.page - 1, 0),
        limit: Number(route.params.limit) || 25,
    });
    const [totalItems, setTotalItems] = useState(0);

    async function getMosaicsList() {
        try {
            setIsLoading(true);
            const response = await cameraService.getMosaics(filters);
            setMosaicsList(response.rows);
            setTotalItems(response.count);

        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getMosaicsList();
    }, []);

    function handleFormClose() {
        setMosaic(undefined);
        setModalVisible(false);
        getMosaicsList();
    }

    function handleFormNew() {
        setMosaic(undefined);
        setModalVisible(true);
    }

    function handleEditClick(mosaic: Mosaic) {
        setMosaic(mosaic);
        setModalVisible(true);
    }

    function handleOpenClick(mosaic: Mosaic) {
        navigation.navigate('MosaicDetail', { id: mosaic.id });
    }

    function showDeleteConfirmationDialog(mosaic: Mosaic) {
        setMosaic(mosaic);
        setDeleteModalVisible(true);
    }

    async function confirmDelete(mosaic: Mosaic) {
        try {
            if (mosaic?.id) {
                await cameraService.deleteMosaic(mosaic.id);
                Toast.show({
                    type: 'sentinelxSuccess',
                    text1: translate('ActionSuccessfully'),
                });

                setDeleteModalVisible(false);
                getMosaicsList();
            }
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    return (
        <View style={styles.container}>
            <View style={styles.headerContent}>
                <View style={{ justifyContent: 'flex-end' }}>
                    <CustomButton icon={faPlus} text={translate('create')} onPress={handleFormNew} />
                </View>
            </View>
            <View style={{ flex: 1 }}>
                <View style={styles.cardList}>
                    <MyDataTable
                        onRowClicked={handleOpenClick}
                        columns={[
                            {
                                name: translate('title'),
                                selector: row => row.title,
                                wrap: true,
                            },
                            {
                                name: translate('action'),
                                button: true,
                                cell: row =>
                                    <View style={{ flexDirection: 'row', columnGap: 10 }}>
                                        <TouchableOpacity onPress={() => handleEditClick(row)}>
                                            <FontAwesomeIcon icon={faEdit} fontSize={16} color={getThemedColor(theme, '#58595B')} />
                                        </TouchableOpacity>
                                        <TouchableOpacity onPress={() => showDeleteConfirmationDialog(row)}>
                                            <FontAwesomeIcon icon={faTrashCan} fontSize={16} color={getThemedColor(theme, '#58595B')} />
                                        </TouchableOpacity>
                                    </View>
                            }
                        ]}
                        data={mosaicsList}
                        progressPending={isLoading}
                    />
                </View>
                <Pagination
                    totalItems={totalItems}
                    currentPage={filters.page}
                    pageSize={filters.limit}
                    setPageSize={pageSize => {
                        setFilters(old => ({
                            ...old,
                            limit: pageSize,
                            page: 0,
                        }));

                        navigation.setParams({
                            ...route.params,
                            limit: pageSize,
                            page: 0,
                        });
                    }}
                    setPage={page => {
                        setFilters(old => ({
                            ...old,
                            page
                        }));

                        navigation.setParams({
                            ...route.params,
                            page: page + 1
                        });
                    }}
                />
            </View>

            <Modal transparent={true} visible={isModalVisible} animationType='fade' onRequestClose={() => setModalVisible(false)}>
                <View style={styles.modalContainer}>
                    <View style={styles.centeredView}>
                        <MosaicEditModal
                            onClose={handleFormClose}
                            mosaic={mosaic}
                        />
                    </View>
                </View>
            </Modal>

            <DeleteModal
                setModalVisible={setDeleteModalVisible}
                isModalVisible={isDeleteModalVisible}
                itemName={String(mosaic?.title) || ''}
                onSubmit={async () => {
                    if (mosaic) {
                        await confirmDelete(mosaic);
                    }
                }}
            />
        </View>
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            flex: 1,
            rowGap: 15
        },
        headerContent: {
            flexDirection: 'row',
            justifyContent: 'flex-end',
            minHeight: 65
        },
        modalContainer: {
            flex: 1,
            backgroundColor: '#31313199'
        },
        cardList: {
            borderWidth: 1,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            // borderColor: theme.colors.dataTable.border,
            // backgroundColor: theme.colors.dataTable.background,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            paddingHorizontal: 10,
            flex: 1,
        },
        centeredView: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
    });
}
