import React, { useState } from 'react';
import { TouchableOpacity, View, Image, ImageSourcePropType } from 'react-native';
import { translate } from '../services/translate';
import MyAppText from '../components/MyAppText';
import { WindowInformation } from '../services/window-information';
import UserInformationModal from '../components/UserInformationModal';
import { ScrollView } from 'react-native-web-hover';
import { createStyleSheet, UnistylesRuntime, useStyles } from 'react-native-unistyles';
import CustomButton from '../components/CustomButton';

interface MenuComponentProps {
    navigation: Navigation;
    authenticatedUser?: AuthenticatedUser;
}

interface MenuItem {
    action: () => void;
    icon: ImageSourcePropType;
    text: string;
    disabled: boolean;
}

export default function Menu({ authenticatedUser, navigation }: MenuComponentProps) {
    const { styles } = useStyles(styleSheet);
    const windowInfo = WindowInformation();
    const [showDropDown, setShowDropDown] = useState(false);

    const menuItems: MenuItem[] = [{
        action: () => navigation.navigate('Cameras'),
        icon: require('../../assets/cameras_icon.png'),
        text: translate('cameras'),
        disabled: false
    },
    {
        action: () => navigation.navigate('Facial'),
        icon: require('../../assets/facial_icon.png'),
        text: translate('facial'),
        disabled: !(authenticatedUser?.isAdmin || authenticatedUser?.permissions.facial_module_access)
    },
    {
        action: () => navigation.navigate('SmartSearchFacial'),
        icon: require('../../assets/smart_search_facial_icon.png'),
        text: translate('smartSearchFacial'),
        disabled: !(authenticatedUser?.isAdmin || authenticatedUser?.permissions.smart_search_module_access)
    },
    {
        action: () => navigation.navigate('LPR'),
        icon: require('../../assets/lpr_icon.png'),
        text: translate('lpr'),
        disabled: !(authenticatedUser?.isAdmin || authenticatedUser?.permissions.lpr_module_access)
    },
    {
        action: () => navigation.navigate('Events'),
        icon: require('../../assets/events_icon.png'),
        text: translate('events'),
        disabled: !(authenticatedUser?.isAdmin || authenticatedUser?.permissions.events_module_access)
    },
    {
        action: () => {
            if (authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_module_access) {
                return navigation.navigate('Dispatch', { params: {} });
            }
            if (authenticatedUser?.permissions.dispatch_manage_team_names) {
                return navigation.navigate('Dispatch');
            }

            if (authenticatedUser?.permissions.dispatch_manage_units || authenticatedUser?.permissions.dispatch_manage_acting_body_units) {
                return navigation.navigate('Dispatch', {
                    screen: 'Units'
                });
            }
        },
        icon: require('../../assets/dispatch_icon.png'),
        text: translate('dispatch'),
        disabled: !(authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_module_access || authenticatedUser?.permissions.dispatch_manage_units || authenticatedUser?.permissions.dispatch_manage_acting_body_units)
    },
    {
        action: () => navigation.navigate('GcmAgent'),
        icon: require('../../assets/app_agent_icon.png'),
        text: translate('appAgent'),
        disabled: !(authenticatedUser?.isAdmin || authenticatedUser?.permissions.receive_occurrences || authenticatedUser?.permissions.search_people_by_picture)
    },
    {
        action: () => navigation.navigate('GuardianApp'),
        icon: require('../../assets/guardian_app_icon.png'),
        text: translate('guardianApp'),
        disabled: !(authenticatedUser?.isAdmin || authenticatedUser?.permissions.guardian_app_module_access)
    },
    {
        action: () => {
            if (authenticatedUser?.isAdmin || authenticatedUser?.permissions.access_edit_user_settings) {
                return navigation.navigate('Access');
            }
            if (authenticatedUser?.permissions.access_management_user_tags) {
                return navigation.navigate('Access', {
                    screen: 'UserTags'
                });
            }
            if (authenticatedUser?.permissions.access_edit_permission_level_settings) {
                return navigation.navigate('Access', {
                    screen: 'PermissionLevels'
                });
            }
            if (authenticatedUser?.permissions.access_edit_group_settings) {
                return navigation.navigate('Access', {
                    screen: 'Groups'
                });
            }
            if (authenticatedUser?.permissions.access_edit_turnstile) {
                return navigation.navigate('Access', {
                    screen: 'Turnstiles'
                });
            }
            if (authenticatedUser?.permissions.access_edit_platform_settings) {
                return navigation.navigate('Access', {
                    screen: 'PlatformSettings'
                });
            }
        },
        icon: require('../../assets/access_icon.png'),
        text: translate('access'),
        disabled: !(authenticatedUser?.isAdmin
            || authenticatedUser?.permissions.access_edit_user_settings
            || authenticatedUser?.permissions.access_management_user_tags
            || authenticatedUser?.permissions.access_edit_permission_level_settings
            || authenticatedUser?.permissions.access_edit_group_settings
            || authenticatedUser?.permissions.access_edit_platform_settings
            || authenticatedUser?.permissions.access_edit_turnstile
        )
    },
    {
        action: () => navigation.navigate('CRM'),
        icon: require('../../assets/crm_icon.png'),
        text: 'CRM',
        disabled: !(authenticatedUser?.isAdmin || authenticatedUser?.permissions.crm_module_access)
    },
    {
        action: () => navigation.navigate('Logs'),
        icon: require('../../assets/logs_icon.png'),
        text: 'Logs',
        disabled: !(authenticatedUser?.isAdmin || authenticatedUser?.permissions.logs_module_access)
    },
    {
        action: () => navigation.navigate('Dashboard'),
        icon: require('../../assets/dashboard_icon.png'),
        text: translate('dashboard'),
        disabled: !(authenticatedUser?.isAdmin || authenticatedUser?.permissions.dashboard_module_access)
    }];

    return (
        <>
            <View style={styles.container}>
                <View style={{ paddingHorizontal: windowInfo.width < 580 ? 10 : 80 }}>
                    {authenticatedUser ?
                        <MyAppText style={styles.welcomeText}>{translate('welcome') + ', ' + (authenticatedUser?.warName || authenticatedUser?.name) + '.'}</MyAppText>
                        : <></>
                    }
                </View>
                <ScrollView contentContainerStyle={styles.scrollContent(windowInfo.width < 580)}>
                    {menuItems.filter(item => !item.disabled).map((item, itemIndex) => (
                        <TouchableOpacity key={itemIndex} onPress={item.action} style={styles.card(windowInfo.width)}>
                            <View style={styles.iconBox}>
                                <Image source={item.icon} style={styles.icon} />
                            </View>
                            <View style={styles.textContent}>
                                <MyAppText style={styles.text}>{item.text}</MyAppText>
                                <CustomButton onPress={item.action} text={translate('starting')} type='secondary' />
                            </View>
                        </TouchableOpacity>
                    ))}
                </ScrollView>
            </View>
            <UserInformationModal acceptedTermsOfUse={authenticatedUser?.acceptedTermsOfUse || false} showDropDown={showDropDown} setShowDropDown={setShowDropDown} navigation={navigation} />
        </>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        rowGap: 40,
        paddingTop: 40
    },
    welcomeText: {
        fontSize: 24,
        fontWeight: 'bold',
        color: theme.colors.labelColor
    },
    card: (width: number) => ({
        backgroundColor: theme.colors.backgroundColor,
        borderRadius: 4,
        flexDirection: 'row',
        alignItems: 'center',
        padding: 15,
        height: 90,
        gap: 15,
        width: width < 406 ? 335 : 406,
        borderWidth: 1,
        borderColor: theme.colors.borderColor
    }),
    iconBox: {
        height: 54,
        width: 54,
        borderRadius: 4,
        backgroundColor: UnistylesRuntime.themeName === 'light' ? '#000028' : '#222426',
        justifyContent: 'center',
        alignItems: 'center'
    },
    icon: {
        height: 36,
        width: 36,
        objectFit: 'contain',
    },
    text: {
        color: theme.colors.labelColor,
        fontSize: 16,
        fontWeight: 'bold',
        whiteSpace: 'break-spaces'
    },
    scrollContent: (smallPadding: boolean) => ({
        paddingHorizontal: smallPadding ? 10 : 80,
        rowGap: 20,
        columnGap: 16,
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start'
    }),
    textContent: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
        justifyContent: 'space-between'
    }
}));
