import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import MyAppText from '../../../components/MyAppText';
import { translate } from '../../../services/translate';
import moment from 'moment';
import MyDataTable from '../../../components/MyDataTable';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import { DashboardAgent, dashboardSummaryService } from '../../../services/central-api/dashboard-summary';
import { TextMask } from 'react-native-masked-text';
import { Pagination } from '../../../components/Pagination';
import { RouteProp, useRoute } from '@react-navigation/native';
import { DashBoardParamList } from '../../../typings/Params';
import useFilters from '../../../components/Filter/hooks/useFilters';
import Filter from './Filters';
import Map from '../../../components/Map';
import { WindowInformation } from '../../../services/window-information';
import { ONLINE_AGENT_TIME_MINUTES } from '../../../services/central-api/dispatch';

export default function DashboardAgents({ navigation, authenticatedUser }: { navigation: Navigation; authenticatedUser?: AuthenticatedUser; }) {
    const { styles, theme } = useStyles(styleSheet);
    const windowInfo = WindowInformation();

    const route = useRoute<RouteProp<DashBoardParamList, 'DashboardAgents'>>();

    const [filters, setFilters] = useFilters<DashBoardParamList['DashboardAgents']>({
        ...route.params,
        page: Math.max(+route.params.page - 1, 0),
        limit: Number(route.params.limit) || 25,
    });

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [agents, setAgents] = useState<DashboardAgent[]>([]);
    const [activeAgents, setActiveAgents] = useState<number>(0);
    const [totalItems, setTotalItems] = useState(0);
    const [agentPositions, setAgentPositions] = useState<UnitMapPosition[]>([]);

    async function getAgents() {
        try {
            setIsLoading(true);
            const resp = await dashboardSummaryService.getAgentsSummary(filters);
            setAgents(resp.rows);
            setActiveAgents(resp.activeAgents);
            setTotalItems(resp.count);

            const positions: UnitMapPosition[] = [];
            for (const user of resp.rows) {
                if (user.agentPosition && user.agentPosition.positionUpdatedAt) {
                    positions.push({
                        id: user.agentPosition.id,
                        latitude: user.agentPosition.latitude,
                        longitude: user.agentPosition.longitude,
                        positionUpdatedAt: new Date(user.agentPosition.positionUpdatedAt),
                        userId: user.id,
                        user: {
                            id: user.id,
                            name: user.name,
                            phone: user.phone,
                            warName: user.warName,
                            unit: {
                                id: user.unit.id,
                                unitName: user.unit.unitName
                            }
                        }
                    });
                }
            }
            setAgentPositions(positions);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getAgents();
    }, []);

    return (
        <View style={styles.container}>
            <View style={styles.headerContent}>
                <MyAppText style={styles.title} >{translate('agents')} </MyAppText>
                <View style={{ flex: 1 }}></View>
                <View style={styles.buttonsContainer}>
                    <Filter filters={filters} setFilters={setFilters} authenticatedUser={authenticatedUser} />
                </View>
            </View>
            <View style={{ flex: 1, gap: 5, flexDirection: 'row' }}>
                <View style={{ flex: 1, flexGrow: 2, rowGap: 5 }}>
                    <View style={{ flexDirection: 'row', gap: 20 }}>
                        <View style={styles.rows}>
                            <MyAppText style={styles.number}>{activeAgents} </MyAppText>
                            <View style={{ flexDirection: 'row', alignItems: 'baseline' }}>
                                <MyAppText style={styles.text}>{translate('online')} </MyAppText>
                                <View style={[styles.coloredDot, { backgroundColor: '#00BA39' }]}></View>
                            </View>
                        </View>
                        <View style={styles.rows}>
                            <MyAppText style={styles.number}>{totalItems - activeAgents} </MyAppText>
                            <View style={{ flexDirection: 'row', alignItems: 'baseline' }}>
                                <MyAppText style={styles.text}>{translate('offline')} </MyAppText>
                                <View style={[styles.coloredDot, { backgroundColor: '#FF0027' }]}></View>
                            </View>
                        </View>
                        <View style={styles.rows}>
                            <MyAppText style={styles.number}>{totalItems} </MyAppText>
                            <MyAppText style={styles.text}>{translate('total')} </MyAppText>
                        </View>
                    </View>
                    <View style={{ flex: 1 }}>
                        <View style={styles.cardList}>
                            <MyDataTable
                                columns={[
                                    {
                                        name: translate('name'),
                                        selector: row => row.warName || row.name,
                                        wrap: true,
                                        grow: 3
                                    },
                                    {
                                        name: translate('phone'),
                                        cell: row => <View style={{ width: '100%' }}>
                                            <TextMask
                                                style={{ color: theme.colors.textColor, fontFamily: 'Open Sans' }}
                                                type={'cel-phone'}
                                                value={row.phone} />
                                        </View>,
                                    },
                                    {
                                        name: translate('lastUpdate'),
                                        cell: row =>
                                            <View style={{ width: '100%' }}>
                                                {row.agentPosition ?
                                                    <View style={{ flexDirection: 'row', gap: 5, alignItems: 'center' }}>
                                                        <View style={[styles.coloredDot, { backgroundColor: moment(row.agentPosition.positionUpdatedAt) > moment().subtract(ONLINE_AGENT_TIME_MINUTES, 'minutes') ? '#00BA39' : '#FF0027' }]}></View>
                                                        <MyAppText>{moment(row.agentPosition.positionUpdatedAt).format('DD/MM/YYYY HH:mm')} </MyAppText>
                                                    </View> : <></>
                                                }
                                            </View>,
                                        wrap: true,
                                    },
                                ]}
                                data={agents}
                                progressPending={isLoading}
                            />
                        </View>
                        <Pagination
                            totalItems={totalItems}
                            currentPage={filters.page}
                            pageSize={filters.limit}
                            setPageSize={pageSize => {
                                setFilters(old => ({
                                    ...old,
                                    limit: pageSize,
                                    page: 0,
                                }));

                                navigation.setParams({
                                    ...route.params,
                                    limit: pageSize,
                                    page: 0,
                                });
                            }}
                            setPage={page => {
                                setFilters(old => ({
                                    ...old,
                                    page
                                }));

                                navigation.setParams({
                                    ...route.params,
                                    page: page + 1
                                });
                            }}
                        />
                    </View>
                </View>
                {!windowInfo.isMobile ?
                    <View style={{ flex: 1 }}>
                        <Map agentPositions={agentPositions} shouldOmitUnitsAndAgents={false} />
                    </View>
                    : null
                }
            </View>
        </View>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flex: 1,
        rowGap: 15
    },
    headerContent: {
        flexDirection: 'row',
        minHeight: 40,
        zIndex: 2,
        flexWrap: 'wrap',
        gap: 5,
        justifyContent: 'space-between'
    },
    cardList: {
        borderWidth: 1,
        borderRadius: 8,
        borderColor: theme.colors.borderColor,
        backgroundColor: theme.colors.backgroundColor,
        paddingHorizontal: 10,
        flex: 1
    },
    buttonsContainer: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        gap: 10,
    },
    title: {
        fontFamily: 'Open Sans',
        color: theme.colors.labelColor,
        fontSize: 20,
        fontWeight: 'bold'
    },
    text: {
        fontSize: 15,
        color: theme.colors.labelColor,
        whiteSpace: 'break-spaces'
    },
    rows: {
        flexDirection: 'column',
        flex: 1
    },
    number: {
        fontSize: 35,
        fontWeight: 'bold'
    },
    coloredDot: {
        width: 8,
        height: 8,
        borderRadius: 50
    },
}));
