import React from 'react';
import LateralMenu from '../LateralMenu';
import { faVenus } from '@fortawesome/free-solid-svg-icons';
import { translate } from '../../services/translate';
import logo from '../../../assets/logo_guardian_app.png';
import collapsedLogo from '../../../assets/guardian_app_icon.png';

export default function GuardianAppLateralMenu({ navigation, authenticatedUser, open, setOpen, selectedMenu }: LateralMenuProps) {
    const items = [];

    if (authenticatedUser?.isAdmin || authenticatedUser?.permissions.guardian_app_module_access) {
        items.push({
            icon: faVenus,
            text: translate('users'),
            action: () => navigation.navigate('GuardianApp', { screen: 'Users' }),
            selected: selectedMenu == 'users'
        });
    }

    return (
        <LateralMenu
            topImage={logo}
            collapsedTopImage={collapsedLogo}
            open={open}
            setOpen={setOpen}
            items={items}
        />
    );
}
