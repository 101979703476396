import React, { useContext, useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import MyAppText from '../../components/MyAppText';
import { translate } from '../../services/translate';
import moment from 'moment';
import MyDropDownPicker from '../../components/MyDropDownPicker';
import { cameraService, GetCamerasParameters } from '../../services/central-api/cameras';
import ThemeContext from '../../context/Theme';
import getThemedColor from '../../services/get-themed-color';
import { accessService } from '../../services/central-api/access';
import MyDataTable from '../../components/MyDataTable';
import { WindowInformation } from '../../services/window-information';
import { dashboardSummaryService, GetCamerasSummary } from '../../services/central-api/dashboard-summary';

export default function DashboardCameras() {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const windowInfo = WindowInformation();

    const [summary, setSummary] = useState<GetCamerasSummary>({
        lprOnline: 0,
        lprOffline: 0,
        pinnedOnline: 0,
        pinnedOffline: 0,
        ptzOnline: 0,
        ptzOffline: 0,
        online: 0,
        offline: 0,
        createdToday: 0,
        panoramicOnline: 0,
        panoramicOffline: 0,
        groupedCoordinatedCount: 0,
    });
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [cameras, setCameras] = useState<DashboardCamera[]>([]);

    // Camera type picker
    const [isCameraTypePickerOpen, setIsCameraTypePickerOpen] = useState<boolean>(false);
    const [selectedCameraTypeFilter, setSelectedCameraTypeFilter] = useState<CameraType | 'all'>('all');
    const [selectableCamerasTypeFilter, setSelectableCamerasTypeFilter] = useState<{ label: string, value: CameraType | 'all'; }[]>([]);

    // Installation Company picker
    const [isInstallationCompanyPickerOpen, setIsInstallationCompanyPickerOpen] = useState<boolean>(false);
    const [selectableInstallationCompanyFilter, setSelectableInstallationCompanyFilter] = useState<{ label: string, value: number; }[]>([]);
    const [selectedInstallationCompanyFilter, setSelectedInstallationCompanyFilter] = useState<number>(0);


    // Date picker
    const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false);
    const [selectableDateFilter, setSelectableDateFilter] = useState<{ label: string, value: string; }[]>([]);
    const [selectedDateFilter, setSelectedDateFilter] = useState<string>('today');

    const [filters, setFilters] = useState<GetCamerasParameters>({
        types: [],
        installationCompanyId: undefined,
        orderBy: 'createdAt',
        sortOrder: 'DESC',
        beginIn: moment().startOf('day').toDate().getTime(),
        endIn: moment().endOf('day').toDate().getTime(),
        hideChildren: false,
    });

    async function getCamerasList(filters: GetCamerasParameters) {
        try {
            setIsLoading(true);
            const cameras = await cameraService.getCameras(filters);
            setCameras(cameras);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    async function setPickers() {
        try {
            const res = await accessService.getInstallationCompaniesSimplified();
            let companies = [];
            companies.push({ label: translate('all'), value: 0 });
            companies = companies.concat(res.filter((company) => !company.deletedAt).map((company) => {
                return {
                    value: company.id,
                    label: company.name
                };
            }));
            setSelectableInstallationCompanyFilter(companies);
            setSelectableCamerasTypeFilter([
                { label: translate('all'), value: 'all' },
                { label: translate('ptz'), value: 'ptz' },
                { label: translate('lpr'), value: 'lpr' },
                { label: translate('pinned'), value: 'pinned' },
                { label: translate('panoramic'), value: 'panoramic' }
            ]);
            setSelectableDateFilter([
                { label: translate('today'), value: 'today' },
                { label: translate('last7Days'), value: 'last7Days' },
                { label: translate('last15Days'), value: 'last15Days' },
                { label: translate('last30Days'), value: 'last30Days' },
                { label: translate('last60Days'), value: 'last60Days' },
                { label: translate('last90Days'), value: 'last90Days' },
            ]);
        } catch (err) {
            console.error(err);
        }
    }

    async function getCamerasSummary() {
        try {
            const camerasSummary = await dashboardSummaryService.getCamerasSummary(filters.beginIn || moment().toDate().getTime());
            setSummary(camerasSummary);
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        setPickers();
    }, []);

    useEffect(() => {
        getCamerasList(filters);
        getCamerasSummary();
    }, [filters]);

    return (
        <View style={styles.container}>
            <MyAppText style={styles.title} >{translate('installedCameras')} </MyAppText>
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: 20 }}>
                <View style={{ flex: 2, flexDirection: 'row', justifyContent: 'space-between', minWidth: 250 }}>
                    <View style={styles.columns}>
                        <View style={styles.rows}>
                            <MyAppText style={styles.number}>{summary.createdToday} </MyAppText>
                            <MyAppText style={styles.text}>{translate(selectedDateFilter)} </MyAppText>
                        </View>
                        <View style={styles.rows}>
                            <MyAppText style={styles.number}>{summary.online} </MyAppText>
                            <View style={{ flexDirection: 'row', alignItems: 'baseline' }}>
                                <MyAppText style={styles.text}>{translate('online')} </MyAppText>
                                <View style={[styles.coloredDot, { backgroundColor: '#00BA39' }]}></View>
                            </View>
                        </View>
                    </View>
                    <View style={styles.columns}>
                        <View style={styles.rows}>
                            <MyAppText style={styles.number}>{summary.online + summary.offline} </MyAppText>
                            <MyAppText style={styles.text}>{translate('total')} </MyAppText>
                        </View>
                        <View style={styles.rows}>
                            <MyAppText style={styles.number}>{summary.offline} </MyAppText>
                            <View style={{ flexDirection: 'row', alignItems: 'baseline' }}>
                                <MyAppText style={styles.text}>{translate('offline')} </MyAppText>
                                <View style={[styles.coloredDot, { backgroundColor: '#FF0027' }]}></View>
                            </View>
                        </View>
                    </View>
                    <View style={styles.columns}>
                        <View style={styles.rows}>
                            <MyAppText style={styles.number}>{summary.groupedCoordinatedCount} </MyAppText>
                            <MyAppText style={styles.text}>{translate('uniqueInstallationLocations')} </MyAppText>
                        </View>
                        <View style={styles.rows}></View>
                    </View>
                </View>
                {!windowInfo.isMobile ?
                    <View style={styles.card}>
                        <View style={styles.cameraTableRow}>
                            <View style={styles.tableRows}>
                                <MyAppText style={styles.tableText}>{translate('type')} </MyAppText>
                            </View>
                            <View style={styles.tableRows}>
                                <MyAppText style={styles.tableText}>{translate('amount')}</MyAppText>
                            </View>
                            <View style={styles.tableRows}>
                                <View style={{ flexDirection: 'row', alignItems: 'baseline' }}>
                                    <MyAppText style={styles.text}>{translate('online')} </MyAppText>
                                    <View style={[styles.coloredDot, { backgroundColor: '#00BA39' }]}></View>
                                </View>
                            </View>
                            <View style={styles.tableRows}>
                                <View style={{ flexDirection: 'row', alignItems: 'baseline' }}>
                                    <MyAppText style={styles.text}>{translate('offline')} </MyAppText>
                                    <View style={[styles.coloredDot, { backgroundColor: '#FF0027' }]}></View>
                                </View>
                            </View>
                        </View>
                        <View style={styles.tableLine}></View>
                        <View style={styles.cameraTableRow}>
                            <View style={styles.tableRows}>
                                <MyAppText style={styles.tableText}>{translate('pinned')} </MyAppText>
                            </View>
                            <View style={styles.tableRows}>
                                <MyAppText style={styles.tableText}>{summary.pinnedOffline + summary.pinnedOnline}</MyAppText>
                            </View>
                            <View style={styles.tableRows}>
                                <View style={{ flexDirection: 'row', alignItems: 'baseline' }}>
                                    <MyAppText style={styles.tableText}>{summary.pinnedOnline}</MyAppText>
                                    <MyAppText style={styles.tablePercentage}>({Math.round((summary.pinnedOnline / (summary.pinnedOffline + summary.pinnedOnline > 0 ? summary.pinnedOffline + summary.pinnedOnline : 1)) * 100)})%</MyAppText>
                                </View>
                            </View>
                            <View style={styles.tableRows}>
                                <View style={{ flexDirection: 'row', alignItems: 'baseline' }}>
                                    <MyAppText style={styles.tableText}>{summary.pinnedOffline}</MyAppText>
                                    <MyAppText style={styles.tablePercentage}>({Math.round((summary.pinnedOffline / (summary.pinnedOffline + summary.pinnedOnline > 0 ? summary.pinnedOffline + summary.pinnedOnline : 1)) * 100)})%</MyAppText>
                                </View>
                            </View>
                        </View>
                        <View style={styles.tableLine}></View>
                        <View style={styles.cameraTableRow}>
                            <View style={styles.tableRows}>
                                <MyAppText style={styles.tableText}>{translate('lpr')} </MyAppText>
                            </View>
                            <View style={styles.tableRows}>
                                <MyAppText style={styles.tableText}>{summary.lprOffline + summary.lprOnline}</MyAppText>
                            </View>
                            <View style={styles.tableRows}>
                                <View style={{ flexDirection: 'row', alignItems: 'baseline' }}>
                                    <MyAppText style={styles.tableText}>{summary.lprOnline}</MyAppText>
                                    <MyAppText style={styles.tablePercentage}>({Math.round((summary.lprOnline / (summary.lprOffline + summary.lprOnline > 0 ? summary.lprOffline + summary.lprOnline : 1)) * 100)})%</MyAppText>
                                </View>
                            </View>
                            <View style={styles.tableRows}>
                                <View style={{ flexDirection: 'row', alignItems: 'baseline' }}>
                                    <MyAppText style={styles.tableText}>{summary.lprOffline}</MyAppText>
                                    <MyAppText style={styles.tablePercentage}>({Math.round((summary.lprOffline / (summary.lprOffline + summary.lprOnline > 0 ? summary.lprOffline + summary.lprOnline : 1)) * 100)})%</MyAppText>
                                </View>
                            </View>
                        </View>
                        <View style={styles.tableLine}></View>
                        <View style={styles.cameraTableRow}>
                            <View style={styles.tableRows}>
                                <MyAppText style={styles.tableText}>{translate('ptz')} </MyAppText>
                            </View>
                            <View style={styles.tableRows}>
                                <MyAppText style={styles.tableText}>{summary.ptzOffline + summary.ptzOnline}</MyAppText>
                            </View>
                            <View style={styles.tableRows}>
                                <View style={{ flexDirection: 'row', alignItems: 'baseline' }}>
                                    <MyAppText style={styles.tableText}>{summary.ptzOnline}</MyAppText>
                                    <MyAppText style={styles.tablePercentage}>({Math.round((summary.ptzOnline / (summary.ptzOffline + summary.ptzOnline > 0 ? summary.ptzOffline + summary.ptzOnline : 1)) * 100)})%</MyAppText>
                                </View>
                            </View>
                            <View style={styles.tableRows}>
                                <View style={{ flexDirection: 'row', alignItems: 'baseline' }}>
                                    <MyAppText style={styles.tableText}>{summary.ptzOffline}</MyAppText>
                                    <MyAppText style={styles.tablePercentage}>({Math.round((summary.ptzOffline / (summary.ptzOffline + summary.ptzOnline > 0 ? summary.ptzOffline + summary.ptzOnline : 1)) * 100)})%</MyAppText>
                                </View>
                            </View>
                        </View>
                        <View style={styles.tableLine}></View>
                        <View style={styles.cameraTableRow}>
                            <View style={styles.tableRows}>
                                <MyAppText style={styles.tableText}>{translate('panoramic')} </MyAppText>
                            </View>
                            <View style={styles.tableRows}>
                                <MyAppText style={styles.tableText}>{summary.panoramicOffline + summary.panoramicOnline}</MyAppText>
                            </View>
                            <View style={styles.tableRows}>
                                <View style={{ flexDirection: 'row', alignItems: 'baseline' }}>
                                    <MyAppText style={styles.tableText}>{summary.panoramicOnline}</MyAppText>
                                    <MyAppText style={styles.tablePercentage}>({Math.round((summary.panoramicOnline / (summary.panoramicOffline + summary.panoramicOnline > 0 ? summary.panoramicOffline + summary.panoramicOnline : 1)) * 100)})%</MyAppText>
                                </View>
                            </View>
                            <View style={styles.tableRows}>
                                <View style={{ flexDirection: 'row', alignItems: 'baseline' }}>
                                    <MyAppText style={styles.tableText}>{summary.panoramicOffline}</MyAppText>
                                    <MyAppText style={styles.tablePercentage}>({Math.round((summary.panoramicOffline / (summary.panoramicOffline + summary.panoramicOnline > 0 ? summary.panoramicOffline + summary.panoramicOnline : 1)) * 100)})%</MyAppText>
                                </View>
                            </View>
                        </View>
                    </View> : undefined}
            </View>
            <View style={styles.cameraTable}>
                <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: 10, alignItems: 'center', zIndex: 2 }}>
                    <MyAppText style={[styles.title, { flexGrow: 1 }]}>{translate('lastInstalledCameras')}</MyAppText>
                    <View style={{ maxWidth: 230, minWidth: 230, zIndex: 4, rowGap: 5 }}>
                        <MyAppText style={styles.text}>{translate('period')}</MyAppText>
                        <MyDropDownPicker
                            open={isDatePickerOpen}
                            value={selectedDateFilter}
                            items={selectableDateFilter}
                            setOpen={setIsDatePickerOpen}
                            setValue={(value) => {
                                const selected = value(selectedDateFilter);
                                setSelectedDateFilter(selected);

                                let start, finish;
                                if (selected === 'last7Days') {
                                    start = moment().subtract(7, 'days').startOf('day');
                                    finish = moment().endOf('day');
                                } else if (selected === 'last15Days') {
                                    start = moment().subtract(15, 'days').startOf('day');
                                    finish = moment().endOf('day');
                                } else if (selected === 'last30Days') {
                                    start = moment().subtract(30, 'days').startOf('day');
                                    finish = moment().endOf('day');
                                } else if (selected === 'last60Days') {
                                    start = moment().subtract(60, 'days').startOf('day');
                                    finish = moment().endOf('day');
                                } else if (selected === 'last90Days') {
                                    start = moment().subtract(90, 'days').startOf('day');
                                    finish = moment().endOf('day');
                                } else {
                                    start = moment().startOf('day');
                                    finish = moment().endOf('day');
                                }
                                setFilters({ ...filters, beginIn: start.toDate().getTime(), endIn: finish.toDate().getTime() });
                            }}
                            height={32}
                            searchable={true}
                            borderColor={getThemedColor(theme, '#888888')}
                        />
                    </View>
                    <View style={{ maxWidth: 230, minWidth: 230, zIndex: 3, rowGap: 5 }}>
                        <MyAppText style={styles.text}>{translate('installationCompany')}</MyAppText>
                        <MyDropDownPicker
                            open={isInstallationCompanyPickerOpen}
                            value={selectedInstallationCompanyFilter}
                            items={selectableInstallationCompanyFilter}
                            setOpen={setIsInstallationCompanyPickerOpen}
                            setValue={(value) => {
                                setSelectedInstallationCompanyFilter(value);
                                const selected = value(selectedInstallationCompanyFilter);
                                if (selected !== 'all') {
                                    setFilters({ ...filters, installationCompanyId: selected });
                                } else {
                                    setFilters({ ...filters, installationCompanyId: undefined });
                                }
                            }}
                            height={32}
                            searchable={true}
                            borderColor={getThemedColor(theme, '#888888')}
                        />
                    </View>
                    <View style={{ maxWidth: 230, minWidth: 230, zIndex: 2, rowGap: 5 }}>
                        <MyAppText style={styles.text}>{translate('cameraType')}</MyAppText>
                        <MyDropDownPicker
                            open={isCameraTypePickerOpen}
                            value={selectedCameraTypeFilter}
                            items={selectableCamerasTypeFilter}
                            setOpen={setIsCameraTypePickerOpen}
                            setValue={(value) => {
                                setSelectedCameraTypeFilter(value);
                                const selected = value(selectedCameraTypeFilter);
                                if (selected !== 'all') {
                                    setFilters({ ...filters, types: [selected] });
                                } else {
                                    setFilters({ ...filters, types: [] });
                                }
                            }}
                            height={32}
                            borderColor={getThemedColor(theme, '#888888')}
                        />
                    </View>
                </View>
                <MyDataTable
                    onRowClicked={(row) => {
                        window.open(`/cameras/map/${row.id}`);
                    }}
                    columns={[
                        {
                            name: translate('serialNo'),
                            selector: row => row.serialNo || '',
                            wrap: true
                        },
                        {
                            name: translate('title'),
                            selector: row => row.title,
                            wrap: true
                        },
                        {
                            name: translate('installationCompany'),
                            selector: row => row.installationCompany?.name || '',
                            wrap: true
                        },
                        {
                            name: translate('status'),
                            cell: row =>
                                <MyAppText style={[styles.cell, row.disabledAt ? { color: getThemedColor(theme, '#B5B5B5') } : row.isConnected ? {} : { color: '#FF0027' }]}>
                                    {row.disabledAt ? translate('disabled') : row.isConnected ? translate('online') : translate('offline')}
                                </MyAppText>
                        },
                        {
                            name: translate('type'),
                            selector: row => translate(row.type),
                        },
                        {
                            name: translate('registeredAt'),
                            selector: row => moment(row.createdAt).format('DD/MM/YYYY HH:mm'),
                            wrap: true
                        },
                    ]}
                    data={cameras}
                    progressPending={isLoading}
                />
            </View>
        </View>
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            flex: 1,
            rowGap: 15
        },
        title: {
            fontFamily: 'Open Sans',
            color: getThemedColor(theme, '#58595B'),
            fontSize: 20,
            fontWeight: 'bold'
        },
        rows: {
            flexDirection: 'column',
            flex: 1
        },
        number: {
            fontSize: 35,
            fontWeight: 'bold'
        },
        text: {
            fontSize: 15,
            color: getThemedColor(theme, '#58595B'),
            whiteSpace: 'break-spaces'
        },
        card: {
            borderWidth: 1,
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            padding: 20,
            flexGrow: 3,
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
        },
        tableRows: {
            flex: 1,
            justifyContent: 'space-evenly',
        },
        tableText: {
            color: getThemedColor(theme, '#58595B'),
            margin: 5,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        },
        tableLine: {
            borderBottomColor: getThemedColor(theme, '#E6E6E6'),
            borderBottomWidth: 1,
        },
        tablePercentage: {
            color: getThemedColor(theme, '#58595B'),
            fontSize: 10,
        },
        coloredDot: {
            width: 8,
            height: 8,
            borderRadius: 50
        },
        cameraTable: {
            borderWidth: 1,
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            padding: 10,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            gap: 10,
            flex: 1
        },
        cameraFilters: {
            flexDirection: 'row',
            alignItems: 'center',
            columnGap: 8,
            flexWrap: 'wrap'
        },
        columns: {
            flex: 1,
            flexDirection: 'column'
        },
        cell: {
            fontSize: 14,
            color: getThemedColor(theme, '#58595B'),
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        },
        cameraTableRow: {
            flexDirection: 'row',
            flex: 1
        }
    });
}
