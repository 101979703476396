import React, { useEffect, useState } from 'react';
import { TagPageContent } from '../../components/TagPageContent';
import Toast from 'react-native-toast-message';
import { translate } from '../../services/translate';
import { View, StyleSheet } from 'react-native';
import { accessService } from '../../services/central-api/access';

interface TagsParams {
    authenticatedUser?: AuthenticatedUser;
}

export default function UserTags({ authenticatedUser }: TagsParams) {
    const styles = getStyles();
    const [loading, setLoading] = useState(false);
    const [tags, setTags] = useState<Tag[]>([]);

    async function getTags() {
        try {
            setLoading(true);
            const tags = await accessService.getUserTags();
            setTags(tags);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getTags();
    }, []);

    async function deleteTag(tag: Tag) {
        try {
            if (tag.id) {
                await accessService.deleteUserTag(tag.id);
            }
        } catch (error) {
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    async function createTag(params: CreateTag) {
        const newTag = await accessService.createUserTag(params);
        return newTag;
    }

    async function updateTag(id: number, params: UpdateTag) {
        await accessService.updateUserTag(id, params);
    }

    return (
        <View style={styles.container}>
            <TagPageContent
                tags={tags}
                setTags={setTags}
                getTags={getTags}
                loading={loading}
                deleteTag={deleteTag}
                createTag={createTag}
                updateTag={updateTag}
                hasRequiredPermission={authenticatedUser?.isAdmin || authenticatedUser?.permissions.access_management_user_tags}
                associatedLabel={translate('associatedUsers')}
            />
        </View>
    );
}

function getStyles() {
    return StyleSheet.create({
        container: {
            flex: 1,
        },
    });
}
