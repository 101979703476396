import React from 'react';
import { ActivityIndicator, TouchableOpacity, View } from 'react-native';
import { useEffect, useState } from 'react';
import { cameraService, GetPaginatedVideoAnalysisFilters } from '../../../services/central-api/cameras';
import MyDataTable from '../../../components/MyDataTable';
import { PaginatedResource } from '../../../typings/Paginated';
import moment from 'moment';
import { translate } from '../../../services/translate';
import { Pagination } from '../../../components/Pagination';
import MyAppText from '../../../components/MyAppText';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import Filters from './Filters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faEdit, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { RouteProp, useRoute } from '@react-navigation/native';
import { CamerasParamList } from '../../../typings/Params';

interface Props {
    navigation: Navigation;
    authenticatedUser?: AuthenticatedUser;
}

function VideoAnalysis({ navigation, authenticatedUser }: Props) {
    const { styles, theme } = useStyles(styleSheet);

    const [data, setData] = useState<PaginatedResource<VideoAnalysis>>({
        count: 0,
        rows: []
    });

    const [isLoading, setIsLoading] = useState(true);

    const route = useRoute<RouteProp<CamerasParamList, 'VideoAnalysis'>>();

    const [filters, setFilters] = useState<GetPaginatedVideoAnalysisFilters>({
        textFilter: route.params.textFilter,
        status: route.params?.status,
        startDate: route.params.startDate,
        endDate: route.params.endDate,
        requesterUserId: route.params?.requesterUserId,
        page: Math.max(route.params.page - 1, 0),
        limit: Number(route.params.limit) || 25,
    });
    const [totalItems, setTotalItems] = useState(0);

    const getVideoAnalysis = async () => {
        try {
            setIsLoading(true);
            const data = await cameraService.getPaginatedVideoAnalysis(filters);

            setTotalItems(data.count);

            setData(data);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleOnFilter = (newFilters: Omit<GetPaginatedVideoAnalysisFilters, 'limit' | 'page'>) => {
        navigation.setParams(newFilters);
    };

    useEffect(() => {
        getVideoAnalysis();
    }, []);

    return (
        <>
            <View style={styles.header}>
                <Filters onFilter={handleOnFilter} filterValues={filters} />
            </View>

            <View style={{ flex: 1 }}>
                <View style={styles.cardList}>
                    <MyDataTable
                        progressPending={isLoading}
                        columns={[
                            {
                                name: 'ID',
                                selector: row => row.id,
                                grow: 0
                            },
                            {
                                name: translate('status'),
                                cell: row =>
                                    <View style={{ flexDirection: 'row', columnGap: 5, alignItems: 'center' }}>
                                        {row.status == 'pending' ?
                                            <ActivityIndicator size={16} color={theme.colors.loadingColor} /> :
                                            <FontAwesomeIcon style={styles.statusStyle(row.status)} fontSize={16} icon={row.status == 'success' ? faCheckCircle : faTimesCircle} />}

                                        <MyAppText>{translate(row.status)}</MyAppText>
                                    </View>,
                                wrap: true,
                            },
                            {
                                name: translate('analyzedCamera'),
                                selector: row => row.camera.title,
                                wrap: true,
                            },
                            {
                                name: translate('requester'),
                                selector: row => row.user.name,
                                wrap: true,
                            },
                            {
                                name: translate('requestAnalysisDate'),
                                selector: row => moment(row.createdAt).format('DD/MM/YYYY'),
                                wrap: true
                            },
                            {
                                name: translate('hours'),
                                selector: row => moment(row.createdAt).format('HH:mm')
                            },
                            {
                                name: translate('actions'),
                                cell: row => (
                                    <View>
                                        {(authenticatedUser?.isAdmin || authenticatedUser?.permissions.camera_watch_video_analysis) && row.status === 'success' ?
                                            <TouchableOpacity onPress={() => navigation.navigate('VideoAnalysisDetails', { id: row.id })} style={{ flexBasis: '5%', alignItems: 'flex-start', justifyContent: 'center' }}>
                                                <FontAwesomeIcon icon={faEdit} fontSize={16} color={theme.colors.iconColor} />
                                            </TouchableOpacity> : undefined}
                                    </View>
                                ),
                                wrap: true,
                                button: true
                            }
                        ]}
                        data={data.rows}
                    />
                </View>

                <Pagination
                    totalItems={totalItems}
                    currentPage={filters.page}
                    pageSize={filters.limit}
                    setPageSize={pageSize => {
                        setFilters(old => ({
                            ...old,
                            limit: pageSize,
                            page: 0,
                        }));

                        navigation.setParams({
                            ...route.params,
                            limit: pageSize,
                            page: 0,
                        });
                    }}
                    setPage={page => {
                        setFilters(old => ({
                            ...old,
                            page
                        }));

                        navigation.setParams({
                            ...route.params,
                            page: page + 1
                        });
                    }}
                />
            </View>
        </>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    header: {
        zIndex: 2,
        minHeight: 65,
    },
    cardList: {
        borderWidth: 1,
        borderRadius: 8,
        borderColor: theme.colors.borderColor,
        backgroundColor: theme.colors.backgroundColor,
        paddingHorizontal: 10,
        flex: 1
    },

    statusStyle: (status: VideoAnalysisStatus) => {
        if (status === 'success') {
            return {
                color: '#017B27'
            };
        } else if (status === 'failed') {
            return {
                color: '#B40119'
            };
        }

        return {};
    },
}));

export default VideoAnalysis;
