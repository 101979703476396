import React, { useEffect, useState } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faDownload, faGear } from '@fortawesome/free-solid-svg-icons';
import Mustache from 'mustache';
import { useNavigationState } from '@react-navigation/native';
import { centralAPI } from '../../../services/central-api';
import TimelineWrapper from '../../../components/TimelineWrapper';
import PtzControls from '../../../components/PtzControls';
import VideoPlayer from '../../../components/VideoPlayer';
import MyAppText from '../../../components/MyAppText';
import { cameraService } from '../../../services/central-api/cameras';
import { translate } from '../../../services/translate';
import NewAnalysisModal from '../VideoAnalysis/NewAnalysisModal';
import MaintenanceRequestModal from '../MaintenanceRequest/MaintenanceRequestModal';
import DownloadModal from './DownloadModal';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import 'react-calendar-timeline/lib/Timeline.css';
import getBestContrastColor from '../../../services/best-contrast-color';

interface CameraPlayerProps {
    navigation: Navigation;
    authenticatedUser?: AuthenticatedUser,
    route: {
        params?: {
            id: string;
        };
    };
}

export default function CameraPlayer({ navigation, authenticatedUser, route }: CameraPlayerProps) {
    const { styles, theme } = useStyles(styleSheet);

    const [camera, setCamera] = useState<Camera>();
    const [videoPlayer, setVideoPlayer] = React.useState<VideoPlayer>();
    const [cursorTime, setCursorTime] = useState<number>(new Date().getTime());
    const [recordList, setRecordList] = useState<CameraRecordList>();
    const [isLive, setIsLive] = useState(true);
    const [currentRecord, setCurrentRecord] = useState<CameraRecord>();
    const [watermark, setWatermark] = useState<string>('');
    const index = useNavigationState(state => state);
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        if (!authenticatedUser || !route) return;

        if (route?.params?.id) {
            try {
                getCamera(route.params.id);
                getTimeline(route.params.id);
                setWatermark(String(authenticatedUser.id));
            } catch (err) {
                console.error(err);
                goBackAction();
            }

            // ping backend so the token doesn't expire
            const interval = setInterval(async () => {
                // idk why but this effect is being called even when accessing others pages
                // this is a guarantee that the interval will only work when the page is active
                if (!window.location.href.endsWith('cameras/map/' + route.params?.id)) {
                    return;
                }

                if (camera?.type === 'ptz') {
                    await cameraService.watchingPtzCamera({ cameraId: camera.id });
                }

                await centralAPI.checkAuthentication();
            }, 30000);
            return () => clearInterval(interval);
        }
    }, [route, authenticatedUser]);

    useEffect(() => {
        if (!camera) {
            return;
        }

        cameraService.watchingPtzCamera({ cameraId: camera.id });

        // ping backend so the token doesn't expire
        const interval = setInterval(async () => {
            // idk why but this effect is being called even when accessing others pages
            // this is a guarantee that the interval will only work when the page is active
            if (!window.location.href.endsWith('cameras/map/' + route.params?.id)) {
                return;
            }

            if (camera?.type === 'ptz') {
                await cameraService.watchingPtzCamera({ cameraId: camera.id });
            }

        }, 30000);
        return () => clearInterval(interval);
    }, [route, camera]);


    async function getCamera(id: string) {
        try {
            const cameraRes = await cameraService.getCamera(id);
            setCamera(cameraRes);
        } catch (err) {
            console.error(err);
        }
    }

    async function getTimeline(id: string) {
        try {
            const timeline = await cameraService.getTimeline(id);
            setRecordList(timeline);
            Mustache.parse(timeline.shotTemplate);
            Mustache.parse(timeline.videoTemplate);
        } catch (err) {
            console.error(err);
        }
    }

    const videoPlayerRef = React.useCallback((videoPlayer: VideoPlayer) => {
        setVideoPlayer(videoPlayer);
    }, []);

    useEffect(() => {
        (async () => {
            try {
                if (!camera?.streamUrl) return;
                if (!videoPlayer) return;

                const token = await centralAPI.getToken();

                videoPlayer.setSrc(camera.streamUrl, { Authorization: `Bearer ${token}` });
            } catch (err) {
                console.error(err);
            }
        })();
    }, [camera, videoPlayer]);

    useEffect(() => {
        const interval = setInterval(async () => {
            try {
                const now = new Date();
                if (isLive) {
                    setCursorTime(now.getTime());
                } else {
                    if (videoPlayer && currentRecord) {
                        const time = videoPlayer.getTime();
                        const date = new Date((time * 1000) + currentRecord.start);
                        setCursorTime(date.getTime());
                    }
                }
            } catch (err) {
                console.error(err);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [videoPlayer, currentRecord, isLive]);

    function setTimeLineAt(moment: number) {
        setCursorTime(moment);

        if (!videoPlayer) {
            return;
        }

        setIsLive(false);
        videoPlayer.setIsLive(false);

        if (!recordList) {
            return;
        }

        const cameraRecord = recordList.videos.findLast((record: CameraRecord) => {
            return record.finish > moment && record.start <= moment;
        });

        if (!cameraRecord) {
            return;
        }

        setCurrentRecord(cameraRecord);
        videoPlayer.setSrc(Mustache.render(recordList.videoTemplate, cameraRecord));
        videoPlayer.setTimeAt((moment - cameraRecord.start) / 1000);
        videoPlayer.onVideoEnded(async () => {
            try {
                const index = recordList.videos.indexOf(cameraRecord) + 1;
                if (index < recordList.videos.length) {
                    setTimeLineAt(recordList.videos[index].start);
                } else {
                    if (!camera?.streamUrl) {
                        return;
                    }
                    const token = await centralAPI.getToken();

                    setCursorTime(new Date().getTime());
                    videoPlayer.setSrc(camera.streamUrl, { Authorization: `Bearer ${token}` });
                    setCurrentRecord(undefined);
                    setIsLive(true);
                    videoPlayer.setIsLive(true);
                }
            } catch (err) {
                console.error(err);
            }
        });
    }

    function onRecordClick(moment: number) {
        setTimeLineAt(moment);
    }

    function goBackAction() {
        if (index.routes.length > 1) {
            return navigation.goBack();
        }
        window.location.href = '/cameras/map';
    }

    if (!camera) {
        return (
            <></>
        );
    }

    return (
        <>
            <View style={styles.content}>
                <View style={styles.header}>
                    <View style={[styles.headerLeft]}>
                        <TouchableOpacity onPress={goBackAction}>
                            <FontAwesomeIcon fontSize={22} icon={faArrowLeft} color={theme.colors.iconColor} />
                        </TouchableOpacity>
                        <View style={{ flexDirection: 'row', columnGap: 20 }}>
                            <View style={{ justifyContent: 'center' }}>
                                <View style={styles.buttons}>
                                    {!camera.thirdPartyCamera
                                        ? <MaintenanceRequestModal authenticatedUser={authenticatedUser} cameraId={camera?.id} />
                                        : null
                                    }
                                    {(authenticatedUser?.isAdmin || authenticatedUser?.permissions.camera_download_video) && !camera.thirdPartyCamera ?
                                        <TouchableOpacity style={styles.downloadButton} onPress={() => setIsModalVisible(true)}>
                                            <FontAwesomeIcon fontSize={16} icon={faDownload} color={theme.colors.backgroundColor} />
                                            <MyAppText style={styles.downloadButtonText}>{translate('downloadVideo')}</MyAppText>
                                        </TouchableOpacity>
                                        : null
                                    }
                                    {(authenticatedUser?.isAdmin || authenticatedUser?.permissions.camera_video_analysis) && !camera.thirdPartyCamera ?
                                        <NewAnalysisModal cameraId={camera?.id} /> : null
                                    }
                                </View>

                            </View>
                            <View style={{ justifyContent: 'center' }}>
                                {authenticatedUser?.isAdmin || authenticatedUser?.permissions.crm_module_access ?
                                    <TouchableOpacity onPress={() => window.open(`/CRM/cameras/details/${camera.id}`)}>
                                        <FontAwesomeIcon icon={faGear} fontSize={16} color={theme.colors.textColor} />
                                    </TouchableOpacity> : null
                                }
                            </View>
                        </View>
                    </View>
                </View>
                <View style={styles.body}>
                    <View style={styles.bodyLeft}>
                        <View style={styles.bodyLeftTop} >
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                <View style={{ flexDirection: 'row', gap: 5, minHeight: 30 }}>
                                    {camera.tags ?
                                        camera.tags.map((tag) =>
                                            <View key={tag.id} style={[styles.marker, { backgroundColor: tag.color }]}>
                                                <MyAppText style={styles.markerText(tag.color)}>
                                                    {tag.name}
                                                </MyAppText>
                                            </View>
                                        ) : null
                                    }
                                </View>
                                {camera.thirdPartyCamera ?
                                    <View>
                                        <MyAppText>{translate('integration')}: {camera.thirdPartyCamera.origin}</MyAppText>
                                    </View>
                                    : null}
                            </View>
                            <VideoPlayer
                                camera={camera}
                                watermark={watermark}
                                onSetLive={async (videoPlayer) => {
                                    try {
                                        if (!camera?.streamUrl) {
                                            return;
                                        }
                                        const token = await centralAPI.getToken();
                                        videoPlayer.setSrc(camera.streamUrl, { Authorization: `Bearer ${token}` });
                                        setCurrentRecord(undefined);
                                        setIsLive(true);
                                        videoPlayer.setIsLive(true);
                                    } catch (err) {
                                        console.error(err);
                                    }
                                }}
                                ref={videoPlayerRef}
                            />
                        </View>
                        <View style={styles.bodyLeftBottom}>
                            {cursorTime && recordList ?
                                <TimelineWrapper
                                    cursor={cursorTime}
                                    recordList={recordList}
                                    onRecordClick={onRecordClick}
                                />
                                : null}
                        </View>
                    </View>
                    {camera.type == 'ptz' && camera.hasPtzPermission ?
                        <View style={styles.bodyRight}>
                            <PtzControls camera={camera} />
                        </View>
                        : null}
                </View>
            </View>
            <DownloadModal
                cameraId={camera.id}
                isModalVisible={isModalVisible}
                setModalVisible={setIsModalVisible}
            />
        </>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    content: {
        flex: 1,
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        columnGap: 10,
        padding: 10
    },
    headerLeft: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    body: {
        flex: 1,
        flexDirection: 'row',
        flexGrow: 1,
        padding: 16,
        backgroundColor: theme.colors.backgroundColor,
        borderRadius: 8,
        borderColor: theme.colors.borderColor,
        borderWidth: 1,
        gap: 10
    },
    bodyLeft: {
        flex: 1,
        flexGrow: 4
    },
    bodyLeftTop: {
        flex: 1,
        flexGrow: 5
    },
    bodyLeftBottom: {
        flex: 1,
    },
    bodyRight: {
        flex: 1,
        borderLeftColor: theme.colors.borderColor,
        borderLeftWidth: 1
    },
    marker: {
        borderRadius: 8,
        height: 16,
        paddingLeft: 10,
        paddingRight: 10,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    markerText: (tagColor: string) => ({
        fontWeight: '500',
        color: getBestContrastColor(tagColor),
        fontSize: 11
    }),
    downloadButton: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 30,
        height: 40,
        backgroundColor: theme.colors.primaryButton.background,
        borderRadius: 2,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        justifyContent: 'center'
    },
    downloadButtonText: {
        color: theme.colors.backgroundColor,
        fontSize: 16,
        paddingLeft: 10
    },
    buttons: {
        flexDirection: 'row',
        gap: 10
    }
}));
