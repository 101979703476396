import React, { useContext, useEffect, useState } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import getThemedColor from '../services/get-themed-color';
import ThemeContext from '../context/Theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import MyAppText from '../components/MyAppText';
import { translate } from '../services/translate';
import { NavigationProp, useNavigation, useNavigationState } from '@react-navigation/native';
import UserInformationModal from '../components/UserInformationModal';
import { RootStackParamList } from '../typings/Params';


interface ContactUsComponentProps {
    navigation: Navigation;
    authenticatedUser?: AuthenticatedUser;
    route: object;
}

export default function ContactUs({ authenticatedUser }: ContactUsComponentProps) {
    const { theme } = useContext(ThemeContext);
    const index = useNavigationState(state => state);
    const [showDropDown, setShowDropDown] = useState<boolean>(false);

    const styles = getStyles(theme);

    const navigation = useNavigation<NavigationProp<RootStackParamList>>();

    useEffect(() => {
        loadMovidesk();
    }, []);

    function goBack() {
        if (index.routes.length > 1) {
            return navigation.goBack();
        }
        window.location.href = '/menu';
    }

    async function loadMovidesk() {
        const env = document.createElement('script');
        env.type = 'text/javascript';
        env.innerHTML = `var mdChatClient=${process.env.MOVIDESK_KEY};`;

        const widget = document.createElement('script');
        widget.src = 'https://chat.movidesk.com/Scripts/chat-widget.min.js';
        widget.async = true;

        document.body.appendChild(env);
        document.body.appendChild(widget);
    }

    return (
        <>
            <View style={styles.container}>
                <View style={styles.header}>
                    <View style={{ alignItems: 'center', flexDirection: 'row', flexGrow: 1, minWidth: 200, columnGap: 10 }}>
                        <TouchableOpacity onPress={goBack}>
                            <FontAwesomeIcon fontSize={22} icon={faArrowLeft} color={getThemedColor(theme, '#000028')} />
                        </TouchableOpacity>
                        <MyAppText style={{ fontSize: 24, color: getThemedColor(theme, '#58595B'), fontWeight: 'bold' }}>
                            {translate('contactUs')}
                        </MyAppText>
                    </View>
                </View>
                <View style={styles.cardList}>
                    <MyAppText>{translate('movideskInformationPage')}</MyAppText>
                </View>
            </View>
            <UserInformationModal acceptedTermsOfUse={authenticatedUser?.acceptedTermsOfUse || false}
                showDropDown={showDropDown} setShowDropDown={setShowDropDown} navigation={navigation} />
        </>
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            rowGap: 15,
            flex: 1
        },
        header: {
            flexDirection: 'row',
            alignItems: 'center',
            gap: 10,
            flexWrap: 'wrap',
            minHeight: 65
        },
        cardList: {
            borderWidth: 1,
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            padding: 24,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            flex: 1,
        },
    });
}
