import React, { useEffect, useState } from 'react';
import { View, TouchableOpacity, ActivityIndicator, NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';
import { translate } from '../../../services/translate';
import { Pagination } from '../../../components/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faDownload, faPlus, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import MyDataTable from '../../../components/MyDataTable';
import MyAppText from '../../../components/MyAppText';
import Toast from 'react-native-toast-message';
import { crmService } from '../../../services/central-api/crm';
import { dispatchService } from '../../../services/central-api/dispatch';
import { RouteProp, useRoute } from '@react-navigation/native';
import { DispatchParamList } from '../../../typings/Params';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import '../../../styles/date-ranger-picker.css';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import useFilters from '../../../components/Filter/hooks/useFilters';
import FilterInput from '../../../components/Filter/components/FilterInput';
import Filter from './Filters';
import CustomButton from '../../../components/CustomButton';
import ReportModal from './ReportModal';
import getBestContrastColor from '../../../services/best-contrast-color';

export default function Reports({ navigation, authenticatedUser }: { navigation: Navigation; authenticatedUser?: AuthenticatedUser; }) {
    const { styles, theme } = useStyles(styleSheet);
    const route = useRoute<RouteProp<DispatchParamList, 'DispatchReports'>>();

    const [filters, setFilters] = useFilters<DispatchParamList['DispatchReports']>({
        ...route.params,
        page: Math.max(+route.params.page - 1, 0),
        limit: Number(route.params.limit) || 25,
    });
    const [textFilter, setTextFilter] = useState(filters.textFilter ?? '');

    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [isModalVisible, setModalVisible] = useState<boolean>(false);
    const [reports, setReports] = useState<OccurrenceReport[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [totalItems, setTotalItems] = useState(0);

    async function getReports() {
        try {
            const response = await dispatchService.getReports(filters);
            setReports(response.rows);
            setTotalItems(response.count);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getReports();
        const interval = setInterval(async () => {
            // idk why but this effect is being called even when accessing others pages
            // this is a guarantee that the interval will work only when the page is active
            if (!window.location.href.endsWith('dispatch/reports')) {
                return;
            }
            getReports();
        }, 15000);

        return () => clearInterval(interval);
    }, [filters]);

    function handleFormClose() {
        setModalVisible(false);
        getReports();
    }

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == 'Enter') {
            navigation.setParams({
                ...filters,
                textFilter
            });
            setFilters(old => ({
                ...old,
                textFilter
            }));
        }
    }

    return (
        <View style={styles.container}>
            <View style={styles.headerContent}>
                <View style={{ flexGrow: 1, maxWidth: 500, minWidth: 350 }}>
                    <FilterInput
                        label={translate('searchReport')}
                        value={textFilter}
                        placeholder={translate('typeToSearch')}
                        onChange={text => {
                            setTextFilter(text);
                        }}
                        onKeyPress={handleKeyDown}
                    />
                </View>
                <View style={{ flex: 1 }}></View>
                <View style={styles.buttonsContainer}>
                    <Filter
                        authenticatedUser={authenticatedUser}
                        filters={filters}
                        setFilters={setFilters}
                        textFilter={textFilter}
                        setTextFilter={setTextFilter} />
                    {
                        (authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_generate_report) &&
                        <CustomButton icon={faPlus} text={translate('create')} onPress={() => {
                            setModalVisible(true);
                        }} />
                    }
                </View>
            </View>
            <View style={{ flex: 1 }}>
                <View style={styles.cardList}>
                    <MyDataTable
                        columns={[
                            {
                                name: translate('name'),
                                selector: row => row.name,
                                wrap: true
                            },
                            {
                                name: translate('user'),
                                selector: row => row.user?.name || '',
                                wrap: true
                            },
                            {
                                name: 'Token',
                                selector: row => row.token,
                                wrap: true
                            },
                            {
                                name: translate('status'),
                                cell: row =>
                                    <View style={{ flexDirection: 'row', columnGap: 5, alignItems: 'center', width: '100%' }}>
                                        {row.status == 'pending' ?
                                            <ActivityIndicator size={16} color={theme.colors.iconColor} /> :
                                            <FontAwesomeIcon
                                                style={row.status == 'success' ? styles.success : styles.failed}
                                                fontSize={16}
                                                icon={row.status == 'success' ? faCheckCircle : faTimesCircle} />
                                        }
                                        <MyAppText style={row.status == 'success' ? styles.success : row.status == 'failed' ? styles.failed : {}}>
                                            {translate(row.status)}
                                        </MyAppText>
                                    </View>
                            },
                            {
                                name: translate('filters'),
                                cell: row => <View style={{ width: '100%' }}>
                                    <View style={{ flexDirection: 'column', rowGap: 5 }}>
                                        <MyAppText>{translate('format')}: {row.filters.isPdf ? 'PDF' : 'XLSX'}</MyAppText>
                                        {row.filters.startDate && <MyAppText>{translate('start')}: {moment(row.filters.startDate).format('DD/MM/YYYY')}</MyAppText>}
                                        {row.filters.endDate && <MyAppText>{translate('end')}: {moment(row.filters.endDate).format('DD/MM/YYYY')}</MyAppText>}
                                        {
                                            (authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_manage_all_acting_bodies_reports)
                                            && row.actingBody && <MyAppText>{translate('actingBody')}: {row.actingBody.name}</MyAppText>
                                        }
                                        {row.filters.assumedBy && <MyAppText>{translate('assumedBy')}: {row.filters.assumedBy}</MyAppText>}
                                        {row.filters.receivedBy && <MyAppText>{translate('receivedBy')}: {row.filters.receivedBy}</MyAppText>}
                                        {row.filters.transferredBy && <MyAppText>{translate('transferredBy')}: {row.filters.transferredBy}</MyAppText>}
                                        {row.filters.finishedBy && <MyAppText>{translate('finishedBy')}: {row.filters.finishedBy}</MyAppText>}
                                        {row.filters.actingBodyCommands && <MyAppText>{translate('commands')}: {row.filters.actingBodyCommands.join(',')}</MyAppText>}
                                    </View>
                                </View>
                            },
                            {
                                name: translate('createdAt'),
                                selector: row => moment(row.createdAt).format('DD/MM/YYYY HH:mm:ss'),
                            },
                            {
                                name: translate('actingBody'),
                                selector: row => row.actingBody ? row.actingBody.name : '',
                            },
                            {
                                name: translate('tags'),
                                cell: row =>
                                    <View style={{ flexDirection: 'row', flexWrap: 'wrap', width: '100%', gap: 5, paddingVertical: 5 }}>
                                        {row.tags ?
                                            row.tags.map((tag) =>
                                                <View key={tag.id} style={[styles.tagsMarker, { backgroundColor: tag.color }]}>
                                                    <MyAppText style={styles.markerText(tag.color)}>
                                                        {tag.name}
                                                    </MyAppText>
                                                </View>
                                            ) : null
                                        }
                                    </View>
                            },
                            {
                                name: translate('action'),
                                button: true,
                                cell: row =>
                                    <View>
                                        {authenticatedUser?.permissions.dispatch_download_report || authenticatedUser?.isAdmin ?
                                            <TouchableOpacity disabled={isDownloading} onPress={async () => {
                                                try {
                                                    setIsDownloading(true);
                                                    const video = await crmService.getReport(row.id);
                                                    window.open(video.resultUrl);
                                                } catch (error) {
                                                    Toast.show({
                                                        type: 'sentinelxError',
                                                        text1: translate('unexpectedError'),
                                                    });
                                                } finally {
                                                    setIsDownloading(false);
                                                }
                                            }}>
                                                {row.status === 'success' ?
                                                    <FontAwesomeIcon fontSize={16} color={theme.colors.iconColor} icon={faDownload}></FontAwesomeIcon> : null
                                                }
                                            </TouchableOpacity> : undefined}
                                    </View>
                            }
                        ]}
                        data={reports}
                        progressPending={isLoading}
                    />
                </View>
                <Pagination
                    currentPage={filters.page}
                    totalItems={totalItems}
                    setPage={page => {
                        setFilters(old => ({
                            ...old,
                            page
                        }));

                        navigation.setParams({
                            ...route.params,
                            page: page + 1
                        });
                    }}
                    pageSize={filters.limit}
                    setPageSize={pageSize => {
                        setFilters(old => ({
                            ...old,
                            limit: pageSize,
                            page: 0,
                        }));

                        navigation.setParams({
                            ...route.params,
                            limit: pageSize,
                            page: 0,
                        });
                    }}
                />
            </View>

            <ReportModal
                isModalVisible={isModalVisible}
                setModalVisible={setModalVisible}
                authenticatedUser={authenticatedUser}
                onClose={handleFormClose}
            />
        </View>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flex: 1,
        rowGap: 15
    },
    headerContent: {
        flexDirection: 'row',
        minHeight: 65,
        zIndex: 2,
        flexWrap: 'wrap',
        gap: 5,
        justifyContent: 'space-between'
    },
    cardList: {
        borderWidth: 1,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderColor: theme.colors.dataTable.border,
        backgroundColor: theme.colors.dataTable.background,
        paddingHorizontal: 10,
        flex: 1,
    },
    modalContainer: {
        flex: 1,
        backgroundColor: '#31313199'
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonsContainer: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        gap: 10,
    },
    success: {
        color: '#017B27'
    },
    failed: {
        color: '#B40119'
    },
    tagsMarker: {
        borderRadius: 8,
        minHeight: 16,
        paddingLeft: 10,
        paddingRight: 10,
        maxWidth: '100%'
    },
    markerText: (tagColor: string) => ({
        fontWeight: '500',
        color: getBestContrastColor(tagColor),
        fontSize: 11
    }),
}));
