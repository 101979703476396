import React, { useEffect, useState } from 'react';
import { View, TouchableOpacity, ActivityIndicator, NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';
import { translate } from '../../../services/translate';
import { Pagination } from '../../../components/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faDownload, faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import MyDataTable from '../../../components/MyDataTable';
import MyAppText from '../../../components/MyAppText';
import Toast from 'react-native-toast-message';
import { crmService } from '../../../services/central-api/crm';
import { licensePlateRecognitionService } from '../../../services/central-api/license-plate-recognition';
import { RouteProp, useRoute } from '@react-navigation/native';
import { LPRParamList } from '../../../typings/Params';
import ReportModal from './ReportModal';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import FilterInput from '../../../components/Filter/components/FilterInput';
import CustomButton from '../../../components/CustomButton';

export default function Reports({ navigation }: { navigation: Navigation; }) {
    const { styles, theme } = useStyles(styleSheet);
    const route = useRoute<RouteProp<LPRParamList, 'LprReports'>>();

    // filters
    const [filters, setFilters] = useState<{ page: number; textFilter?: string, limit: number; }>({
        page: Math.max(+route.params.page - 1, 0),
        textFilter: route.params.textFilter ?? '',
        limit: Number(route.params.limit) || 25,
    });
    const [totalItems, setTotalItems] = useState(0);

    const [reports, setReports] = useState<PlateDetectionsReport[]>([]);
    const [textFilter, setTextFilter] = useState(filters.textFilter ?? '');
    const [isDownloading, setIsDownloading] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isModalVisible, setModalVisible] = useState<boolean>(false);

    async function getReports() {
        try {
            const response = await licensePlateRecognitionService.getReports(filters);
            setReports(response.rows);
            setTotalItems(response.count);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getReports();
        const interval = setInterval(async () => {
            // idk why but this effect is being called even when accessing others pages
            // this is a guarantee that the interval will work only when the page is active
            if (!window.location.href.endsWith('LPR/reports')) {
                return;
            }
            getReports();
        }, 15000);

        return () => clearInterval(interval);
    }, [filters]);

    function handleFilters() {
        setFilters({
            ...filters,
            page: 0,
            textFilter,
        });

        navigation.setParams({
            page: 1,
            textFilter,
        });
    }

    function handleFormClose() {
        setModalVisible(false);
        getReports();
    }

    function handleKeyDown(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
        if (e.nativeEvent.key == 'Enter') {
            handleFilters();
        }
    }

    return (
        <View style={styles.container}>
            <View style={styles.headerContent}>
                <View style={{ flexGrow: 1, minWidth: 350, maxWidth: 500 }}>
                    <FilterInput
                        label={translate('searchReport')}
                        value={textFilter}
                        onChange={text => {
                            setTextFilter(text);
                        }}
                        placeholder={translate('typeToSearch')}
                        onKeyPress={handleKeyDown}
                    />
                </View>
                <View style={styles.buttonsContainer}>
                    <CustomButton icon={faSearch} text={translate('toSearch')} onPress={handleFilters} />
                    <CustomButton icon={faDownload} text={translate('detectionsReport')} onPress={() => setModalVisible(true)} />
                </View>
            </View>
            <View style={{ flex: 1 }}>
                <View style={styles.cardList}>
                    <MyDataTable
                        columns={[
                            {
                                name: translate('name'),
                                selector: row => row.name,
                                wrap: true
                            },
                            {
                                name: translate('user'),
                                selector: row => row.user?.name || '',
                                wrap: true
                            },
                            {
                                name: 'Token',
                                selector: row => row.token,
                                wrap: true
                            },
                            {
                                name: translate('status'),
                                cell: row =>
                                    <View style={{ flexDirection: 'row', columnGap: 5, alignItems: 'center', width: '100%' }}>
                                        {row.status == 'pending' ?
                                            <ActivityIndicator size={16} color={theme.colors.iconColor} /> :
                                            <FontAwesomeIcon style={row.status == 'success' ? styles.success : styles.failed} fontSize={16} icon={row.status == 'success' ? faCheckCircle : faTimesCircle}></FontAwesomeIcon>
                                        }

                                        <MyAppText style={row.status == 'success' ? styles.success : row.status == 'failed' ? styles.failed : {}}>{translate(row.status)}</MyAppText>
                                    </View>
                            },
                            {
                                name: translate('filters'),
                                wrap: true,
                                cell: row => <View style={{ width: '100%' }}>
                                    {row.type == 'plate_detections' ?
                                        <View style={{ flexDirection: 'column', rowGap: 5 }}>
                                            <MyAppText>{translate('start')}: {moment(row.filters.startDate).format('DD/MM/YYYY HH:mm')}</MyAppText>
                                            <MyAppText>{translate('end')}: {moment(row.filters.endDate).format('DD/MM/YYYY  HH:mm')}</MyAppText>
                                            {row.filters.plate ? <MyAppText>{translate('plate')}: {row.filters.plate}</MyAppText> : undefined}
                                            {row.filters.camerasIds ? <MyAppText>{translate('cameras')}: {row.filters.camerasIds}</MyAppText> : undefined}
                                        </View> : <></>}
                                </View>
                            },
                            {
                                name: translate('createdAt'),
                                selector: row => moment(row.createdAt).format('DD/MM/YYYY HH:mm:ss'),
                            },
                            {
                                name: translate('action'),
                                button: true,
                                cell: row =>
                                    <TouchableOpacity disabled={isDownloading} onPress={async () => {
                                        try {
                                            setIsDownloading(true);
                                            const video = await crmService.getReport(row.id);
                                            window.open(video.resultUrl);
                                        } catch (error) {
                                            Toast.show({
                                                type: 'sentinelxError',
                                                text1: translate('unexpectedError'),
                                            });
                                        } finally {
                                            setIsDownloading(false);
                                        }
                                    }}>
                                        {row.status === 'success' ? <FontAwesomeIcon fontSize={16} color={theme.colors.iconColor} icon={faDownload}></FontAwesomeIcon> : null}
                                    </TouchableOpacity>
                            }
                        ]}
                        data={reports}
                        progressPending={isLoading}
                    />
                </View>
                <Pagination
                    totalItems={totalItems}
                    currentPage={filters.page}
                    pageSize={filters.limit}
                    setPageSize={pageSize => {
                        setFilters(old => ({
                            ...old,
                            limit: pageSize,
                            page: 0,
                        }));

                        navigation.setParams({
                            ...route.params,
                            limit: pageSize,
                            page: 0,
                        });
                    }}
                    setPage={page => {
                        setFilters(old => ({
                            ...old,
                            page
                        }));

                        navigation.setParams({
                            ...route.params,
                            page: page + 1
                        });
                    }}
                />
            </View>

            <ReportModal
                isModalVisible={isModalVisible}
                setModalVisible={setModalVisible}
                onClose={handleFormClose}
            />
        </View>
    );
}

const styleSheet = createStyleSheet((theme) => ({
    container: {
        flex: 1,
        rowGap: 15
    },
    headerContent: {
        flexDirection: 'row',
        minHeight: 65,
        zIndex: 2,
        flexWrap: 'wrap',
        gap: 5,
        justifyContent: 'space-between'
    },
    cardList: {
        borderWidth: 1,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderColor: theme.colors.dataTable.border,
        backgroundColor: theme.colors.dataTable.background,
        paddingHorizontal: 10,
        flex: 1,
    },
    buttonsContainer: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        gap: 10,
    },
    success: {
        color: '#017B27'
    },
    failed: {
        color: '#B40119'
    },
}));
