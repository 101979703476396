import React, { useEffect, useState } from 'react';
import { View, Modal, ScrollView } from 'react-native';
import Toast from 'react-native-toast-message';
import FormActions from '../../../components/formActions';
import FormInput from '../../../components/formInput';
import { ClientError } from '../../../services/central-api/base-service';
import { guardianAppService } from '../../../services/central-api/guardian-app';
import { WindowInformation } from '../../../services/window-information';
import { translate } from '../../../services/translate';
import { createStyleSheet, useStyles } from 'react-native-unistyles';
import MyPhoneInput from '../../../components/MyPhoneInput';
import { TextInputMask } from 'react-native-masked-text';
import MyAppText from '../../../components/MyAppText';

interface Props {
    setModalVisible: (value: boolean) => void;
    isModalVisible: boolean;
    guardianWomanId: number | null;
    onSubmit: () => void;
    onClose: () => void;
}

export default function EditModal({ isModalVisible, setModalVisible, guardianWomanId, onSubmit, onClose }: Props) {
    const { styles, theme } = useStyles(styleSheet);
    const windowInfo = WindowInformation();

    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [imei, setImei] = useState('');
    const [document, setDocument] = useState('');
    const [email, setEmail] = useState('');
    const [birth, setBirth] = useState('');
    const [address, setAddress] = useState('');
    const [complement, setComplement] = useState<string>('');
    const [aggressorName, setAggressorName] = useState<string>('');

    function isInvalid(value: string) {
        return value == '';
    }

    function isFormValid() {
        return !isInvalid(name) &&
            !isInvalid(phone) &&
            !isInvalid(imei) &&
            !isInvalid(document) &&
            !isInvalid(email) &&
            !isInvalid(birth) &&
            !isInvalid(address);
    }

    async function getGuardianWoman(guardianWomanId: number) {
        try {
            const woman = await guardianAppService.getGuardianWomanById(guardianWomanId);
            setName(woman.name);
            setPhone(woman.phone);
            setImei(woman.imei);
            setDocument(woman.document);
            setEmail(woman.email);
            setBirth(woman.birth);
            setAddress(woman.address);
            setComplement(woman.complement ?? '');
            setAggressorName(woman.aggressorName ?? '');
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        if (guardianWomanId) {
            getGuardianWoman(guardianWomanId);
        }
    }, [guardianWomanId]);

    function clearModal() {
        setName('');
        setPhone('');
        setImei('');
        setDocument('');
        setEmail('');
        setBirth('');
        setAddress('');
        setComplement('');
        setAggressorName('');
    }

    async function formSubmit() {
        try {
            if (guardianWomanId) {
                await guardianAppService.editGuardianWoman({
                    id: guardianWomanId,
                    name,
                    phone,
                    imei,
                    document,
                    email,
                    birth,
                    address,
                    complement,
                    aggressorName
                });
                Toast.show({
                    type: 'sentinelxSuccess',
                    text1: translate('ActionSuccessfully'),
                });
                onSubmit();
                setModalVisible(false);
                setTimeout(clearModal, 500);
                return;
            }

            await guardianAppService.createGuardianWoman({
                name, phone, imei, document, email, birth, address, complement, aggressorName
            });
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
            onSubmit();
            setModalVisible(false);
            setTimeout(clearModal, 500);
        } catch (err) {
            if (err instanceof ClientError) {
                return Toast.show({
                    type: 'sentinelxError',
                    text1: translate(err.message),
                });
            }

            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    return (
        <Modal transparent={true} visible={isModalVisible} animationType='fade' onRequestClose={() => setModalVisible(false)}>
            <View style={styles.modalContainer}>
                <View style={styles.centeredView}>
                    <View style={[styles.formContainer, windowInfo.isMobile ? { width: '95%' } : { width: '30%' }]}>
                        <ScrollView contentContainerStyle={{ padding: 10, gap: 10 }}>
                            <FormInput
                                label={translate('name')}
                                placeholder={translate('name')}
                                value={name}
                                onChangeText={setName}
                                invalid={isInvalid}
                            />
                            <MyPhoneInput
                                label={translate('phone')}
                                placeholder={translate('phone')}
                                value={phone}
                                onChangeText={setPhone}
                                invalid={(value: string) => {
                                    return value == '' || value.length < 10;
                                }}
                            />
                            <FormInput
                                label={`${translate('phone')} IMEI`}
                                placeholder={`${translate('phone')} IMEI`}
                                value={imei}
                                onChangeText={setImei}
                                invalid={isInvalid}
                            />
                            <View style={{ gap: 5 }}>
                                <MyAppText>CPF</MyAppText>
                                <TextInputMask
                                    placeholder='000.000.000-00'
                                    value={document}
                                    includeRawValueInChangeText={true}
                                    onChangeText={(maskedText, rawText) => {
                                        setDocument(rawText || '');
                                    }}
                                    type={'cpf'}
                                    style={[styles.input, { color: document != '' ? theme.colors.textColor : theme.colors.disabledText }]}
                                />
                            </View>
                            <FormInput
                                label={translate('email')}
                                placeholder={translate('email')}
                                value={email}
                                onChangeText={setEmail}
                                invalid={isInvalid}
                            />
                            <View style={{ gap: 5 }}>
                                <MyAppText>{translate('birth')}</MyAppText>
                                <TextInputMask
                                    value={birth}
                                    placeholder='__/__/____'
                                    onChangeText={setBirth}
                                    keyboardType={'numeric'}
                                    type={'datetime'}
                                    options={{
                                        format: 'DD/MM/YYYY'
                                    }}
                                    style={[styles.input, { color: birth != '' ? theme.colors.textColor : theme.colors.disabledText }]}
                                />
                            </View>
                            <FormInput
                                label={translate('address')}
                                placeholder={translate('address')}
                                value={address}
                                onChangeText={setAddress}
                                invalid={isInvalid}
                            />
                            <FormInput
                                label={translate('complement')}
                                placeholder={translate('complement')}
                                value={complement}
                                onChangeText={setComplement}
                                invalid={() => false}
                            />
                            <FormInput
                                label={translate('aggressorName')}
                                placeholder={translate('aggressorName')}
                                value={aggressorName}
                                onChangeText={setAggressorName}
                                invalid={() => false}
                            />
                        </ScrollView>
                        <FormActions
                            onSubmit={formSubmit}
                            onClose={() => {
                                setModalVisible(false);
                                onClose();
                                setTimeout(clearModal, 500);
                            }}
                            disabled={!isFormValid()} />
                    </View>
                </View>
            </View>
        </Modal>
    );
}

const styleSheet = createStyleSheet(theme => ({
    formContainer: {
        backgroundColor: theme.colors.backgroundColor,
        borderRadius: 4,
        padding: 20,
        rowGap: 25,
        flexDirection: 'column',
        minWidth: 300,
        minHeight: 300,
        maxHeight: 680,
    },
    input: {
        fontSize: 16,
        fontFamily: 'Open Sans',
        backgroundColor: theme.colors.backgroundColor,
        borderColor: theme.colors.borderColor,
        borderWidth: 1,
        height: 40,
        padding: 10,
        borderRadius: 4,
        flex: 1
    },
    modalContainer: {
        flex: 1,
        backgroundColor: '#31313199'
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    row: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        columnGap: 30,
        minHeight: 'auto',
        rowGap: 20,
    }
}));
