import React, { useEffect } from 'react';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { FacialParamList } from '../../../typings/Params';
import Filters from '../../../components/Filter';
import FilterInput from '../../../components/Filter/components/FilterInput';
import { translate } from '../../../services/translate';
import { useState } from 'react';
import FilterCheckBox from '../../../components/Filter/components/FilterCheckBox';
import FilterDropdown from '../../../components/Filter/components/FilterDropdown';
import { facialService } from '../../../services/central-api/facial';

interface Props {
    filters: FacialParamList['PeopleList'];
    setFilters: React.Dispatch<React.SetStateAction<FacialParamList['PeopleList']>>;
    textFilter: string;
    setTextFilter: React.Dispatch<React.SetStateAction<string>>;
}

export default function PeopleFilter({ filters, setFilters, textFilter, setTextFilter }: Props) {
    const navigation = useNavigation<NavigationProp<FacialParamList, 'PeopleList'>>();

    const [onlyActive, setOnlyActive] = useState<boolean>(filters.onlyActive ?? true);
    const [selectedMarkersIds, setSelectedMarkersIds] = useState<number[]>(filters.markers ?? []);

    const handleCleanFilter = () => {
        setTextFilter('');
        setOnlyActive(true);
        setSelectedMarkersIds([]);
    };

    const handleFilter = () => {
        setFilters({
            ...filters,
            name: textFilter,
            page: 0,
            onlyActive: onlyActive,
            markers: selectedMarkersIds.length ? selectedMarkersIds : undefined,
        });

        navigation.setParams({
            name: textFilter,
            page: 1,
            onlyActive: onlyActive,
            markers: selectedMarkersIds.length ? selectedMarkersIds : undefined,
        });

    };

    const [items, setItems] = useState<{ label: string, value: number; }[]>([]);

    async function getFacialMarkers() {
        try {
            const markers = await facialService.getFacialMarkers();
            setItems(markers.map((marker) => {
                return {
                    value: marker.id || 0,
                    label: marker.name
                };
            }));
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        getFacialMarkers();
    }, []);

    return (
        <Filters onCleanFilters={handleCleanFilter} onFilters={handleFilter}>
            <FilterInput
                label={translate('searchByName')}
                placeholder={translate('typeToSearch')}
                value={textFilter}
                onChange={text => {
                    setTextFilter(text);
                }}
            />
            <FilterCheckBox
                label={translate('onlyActive')}
                value={onlyActive}
                onChange={setOnlyActive}
            />
            <FilterDropdown
                label={translate('markers')}
                items={items}
                setValue={setSelectedMarkersIds}
                value={selectedMarkersIds}
                multiple={true}
                mode='BADGE'
                zIndex={8}
            />
        </Filters>
    );
}



