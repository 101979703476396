import React, { useState } from 'react';
import Filters from '../../../components/Filter';
import FilterInput from '../../../components/Filter/components/FilterInput';
import { translate } from '../../../services/translate';
import { NavigationProp, RouteProp, useNavigation } from '@react-navigation/native';
import { DispatchParamList } from '../../../typings/Params';
import ActingBodiesDropdown from './ActingBodiesDropdown';
import TagsDropdown from './TagsDropdown';

interface Props {
    filters: DispatchParamList['DispatchReports'];
    setFilters: React.Dispatch<React.SetStateAction<DispatchParamList['DispatchReports']>>;
    authenticatedUser?: AuthenticatedUser;
    textFilter: string;
    setTextFilter: (value: string) => void;
}

function Filter({ authenticatedUser, filters, setFilters, textFilter, setTextFilter }: Props) {
    const navigation = useNavigation<NavigationProp<RouteProp<DispatchParamList, 'DispatchReports'>>>();

    const [actingBodyId, setActingBodyId] = useState<number>(filters.actingBodyId ?? 0);
    const [selectedTagsIds, setSelectedTagsIds] = useState<number[]>(filters.tags ?? []);

    const handleCleanFilter = () => {
        setTextFilter('');
        setActingBodyId(0);
        setSelectedTagsIds([]);
    };

    const handleFilter = () => {
        navigation.setParams({
            ...filters,
            page: 1,
            textFilter,
            tags: selectedTagsIds.length ? selectedTagsIds : undefined,
            actingBodyId: actingBodyId !== 0 ? actingBodyId : undefined,
        });

        setFilters({
            ...filters,
            page: 1,
            textFilter,
            tags: selectedTagsIds.length ? selectedTagsIds : undefined,
            actingBodyId: actingBodyId !== 0 ? actingBodyId : undefined,
        });
    };

    return (
        <Filters onCleanFilters={handleCleanFilter} onFilters={handleFilter}>
            <FilterInput
                label={translate('typeToSearch')}
                value={textFilter}
                placeholder={translate('typeToSearch')}
                onChange={setTextFilter}
            />

            {(authenticatedUser?.isAdmin || authenticatedUser?.permissions.dispatch_manage_all_acting_bodies_reports) &&
                <ActingBodiesDropdown includeAllOption={true} value={actingBodyId} setValue={setActingBodyId} zIndex={3} />
            }
            <TagsDropdown value={selectedTagsIds} setValue={setSelectedTagsIds} zIndex={2} />
        </Filters>
    );
}


export default Filter;
