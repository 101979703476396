import { UnistylesRegistry } from 'react-native-unistyles';

export const breakpoints = {
    xs: 0,
    md: 768,
    lg: 1224,
} as const;

// Light (Classic design by Jorge)
export const lightTheme = {
    colors: {
        container: {
            background: '#F7F7F7',
        },
        sidebar: {
            background: '#000028',
            item: {
                hoverBackground: '#040745',
                selectedBackground: '#FFFFFF',
                text: '#FFFFFF',
                textSelected: '#000028',
            }
        },
        primaryButton: {
            background: '#000028',
            text: '#FFFFFF',

            hoverBackground: '#040745',
            hoverText: '#FFFFFF',

            disabledBackground: '#CCCCCC',
            disabledText: '#888888',
        },
        secondaryButton: {
            background: '#FFFFFF',
            borderColor: '#CCCCCC',
            text: '#222222',

            hoverBackground: '#E5E5E5',
            hoverBorderColor: '#CCCCCC',
            hoverText: '#181A1B',

            disabledBackground: '#FFFFFF',
            disabledBorderColor: '#CCCCCC',
            disabledText: '#222222',
        },
        paginationBar: {
            background: '#F9F9F9',
            border: '#E6E6E6',
            arrowButton: {
                border: '#CCCCCC',
                hoverBackground: '#CCCCCC',
                icon: '#222222',
                disabledIcon: '#CCCCCC',
            }
        },
        dataTable: {
            background: '#FFFFFF',
            border: '#E6E6E6',
            oddRowBackground: '#FAFAFA',
            actionIcon: '#58595B',
            loading: '#000000',
            noDataMessage: '#58595B',
        },
        marker: {
            text: '#000000',
        },
        backgroundColor: '#FFFFFF',
        topMenu: '#FFFFFF',
        borderColor: '#E6E6E6',
        color: '#FFFFFF',
        disabledText: '#888888',
        loadingColor: '#000000',
        fieldColor: '#000000',
        iconColor: '#58595B',
        labelColor: '#58595B',
        placeholder: '#CBCBCB',
        textColor: '#222222',
        fieldBorderColor: '#CCCCCC',
        red: '#D50000',
        green: '#008E2C',
        cardBorder: '#EEEEEE',
        cardBackground: '#FFFFFF',
        cardDetailText: '#888888',
    },
} as const;

// Light (designed by Eduardo)
export const lightAltTheme = {
    colors: {
        container: {
            background: '#F7F7F7',
        },
        sidebar: {
            background: '#232426',
            item: {
                hoverBackground: '#3C3C3D',
                selectedBackground: '#3C3C3D',
                text: '#FFFFFF',
                textSelected: '#FFFFFF',
            }
        },
        primaryButton: {
            background: '#835E00',
            text: '#FFFFFF',

            hoverBackground: '#986D00',
            hoverText: '#FFFFFF',

            disabledBackground: '#D7C79F',
            disabledText: '#FFFFFF',
        },
        secondaryButton: {
            background: '#FFFFFF',
            borderColor: '#835E00',
            text: '#835E00',

            hoverBackground: '#FFFFFF',
            hoverBorderColor: '#986D00',
            hoverText: '#986D00',

            disabledBackground: '#FFFFFF',
            disabledBorderColor: '#D7C79F',
            disabledText: '#D7C79F',
        },
        paginationBar: {
            background: '#F9F9F9',
            border: '#E6E6E6',
            arrowButton: {
                border: '#CCCCCC',
                hoverBackground: '#CCCCCC',
                icon: '#222222',
                disabledIcon: '#CCCCCC',
            }
        },
        dataTable: {
            background: '#FFFFFF',
            border: '#E6E6E6',
            oddRowBackground: '#FAFAFA',
            actionIcon: '#58595B',
            loading: '#000000',
            noDataMessage: '#58595B',
        },
        marker: {
            text: '#000000',
        },
        backgroundColor: '#FFFFFF',
        topMenu: '#FFFFFF',
        borderColor: '#E6E6E6',
        color: '#FFFFFF',
        disabledText: '#888888',
        loadingColor: '#000000',
        fieldColor: '#000000',
        iconColor: '#58595B',
        labelColor: '#58595B',
        placeholder: '#CBCBCB',
        textColor: '#222222',
        fieldBorderColor: '#CCCCCC',
        red: '#D50000',
        green: '#008E2C',
        cardBorder: '#EEEEEE',
        cardBackground: '#FFFFFF',
        cardDetailText: '#888888',
    },
} as const;

// Dark
export const darkTheme = {
    colors: {
        container: {
            background: '#1D1F20',
        },
        sidebar: {
            background: '#232426',
            item: {
                hoverBackground: '#3C3C3D',
                selectedBackground: '#FFFFFF',
                text: '#FFFFFF',
                textSelected: '#232426',
            }
        },
        primaryButton: {
            background: '#FFFFFF',
            text: '#181A1B',

            hoverBackground: '#E5E5E5',
            hoverText: '#181A1B',

            disabledBackground: '#3F4447',
            disabledText: '#888888',
        },
        secondaryButton: {
            background: '#181A1B',
            borderColor: '#3F4447',
            text: '#D3CFC9',

            hoverBackground: '#26292B',
            hoverBorderColor: '#3F4447',
            hoverText: '#D3CFC9',

            disabledBackground: '#181A1B',
            disabledBorderColor: '#3F4447',
            disabledText: '#D3CFC9',
        },
        paginationBar: {
            background: '#1D1F20',
            border: '#373C3E',
            arrowButton: {
                border: '#3F4447',
                hoverBackground: '#3F4447',
                icon: '#D3CFC9',
                disabledIcon: '#3F4447',
            }
        },
        dataTable: {
            background: '#181A1B',
            border: '#373C3E',
            oddRowBackground: '#1D1F20',
            actionIcon: '#D3CFC9',
            loading: '#FFFFFF',
            noDataMessage: '#D3CFC9',
        },
        marker: {
            text: '#FFFFFF',
        },
        backgroundColor: '#181A1B',
        topMenu: '#181A1B',
        borderColor: '#373C3E',
        color: '#181A1B',
        disabledText: '#888888',
        loadingColor: '#FFFFFF',
        fieldColor: '#FFFFFF',
        iconColor: '#D3CFC9',
        labelColor: '#D3CFC9',
        placeholder: '#3F4447',
        textColor: '#D3CFC9',
        fieldBorderColor: '#3F4447',
        red: '#D50000',
        green: '#00BA39',
        cardBorder: '#2A2A2A',
        cardBackground: '#181A1B',
        cardDetailText: '#888888',
    },
} as const;


type AppThemes = {
    light: typeof lightTheme;
    dark: typeof darkTheme;
    lightAlt: typeof lightAltTheme;
};

// if you defined breakpoints
type AppBreakpoints = typeof breakpoints;

// override library types
declare module 'react-native-unistyles' {
    export interface UnistylesBreakpoints extends AppBreakpoints { }

    export interface UnistylesThemes extends AppThemes { }
}

UnistylesRegistry
    .addBreakpoints(breakpoints)
    .addThemes({
        light: lightTheme,
        dark: darkTheme,
        lightAlt: lightAltTheme,
    });
