import React, { useContext, useEffect, useState } from 'react';
import {
    View,
    StyleSheet,
    TouchableOpacity,
    FlatList
} from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';


import MyAppText from '../../../components/MyAppText';
import { translate } from '../../../services/translate';
import moment from 'moment';
import ThemeContext from '../../../context/Theme';
import getThemedColor from '../../../services/get-themed-color';
import { crmService } from '../../../services/central-api/crm';
import CameraHistoryModal from '../CameraHistory/CameraHistoryModal';
import ActOfflineEventModal from './ActOfflineEventModal';


export default function OfflineEvents() {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const [offlineEvents, setOfflineEvents] = useState<OfflineEvent[]>([]);

    const [pendingList, setPendingList] = useState<OfflineEvent[]>([]);
    const [ongoingList, setOngoingList] = useState<OfflineEvent[]>([]);
    const [concludedList, setConcludedList] = useState<OfflineEvent[]>([]);

    const [selectedEventId, setSelectedEventId] = useState(0);
    const [isCameraHistoryModalVisible, setIsCameraHistoryModalVisible] = useState(false);
    const [isActOfflineEventModalVisible, setIsActOfflineEventModalVisible] = useState(false);

    async function getEvents(): Promise<void> {
        try {
            const events = await crmService.getOfflineEvents();
            setOfflineEvents(events);
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        getEvents();
        const interval = setInterval(() => {
            if (!window.location.href.split('?')[0].endsWith('CRM/offline-events')) {
                return;
            }
            getEvents();
        }, 30000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const pendingList = offlineEvents.filter((item) => !item.reason && !item.responsibleUser);
        const ongoingList = offlineEvents.filter((item) => !item.reason && item.responsibleUser);
        const concludedList = offlineEvents.filter((item) => item.reason);
        setPendingList(pendingList);
        setOngoingList(ongoingList);
        setConcludedList(concludedList);

    }, [offlineEvents]);

    function renderItem({ item }: { item: OfflineEvent; }) {
        return (
            <TouchableOpacity onPress={async () => {
                setSelectedEventId(item.id);

                if (!item.reason && !item.responsibleUser) {
                    return setIsActOfflineEventModalVisible(true);
                }
                setIsCameraHistoryModalVisible(true);
            }}>
                <View style={styles.card}>
                    <View style={{ minWidth: 130, flex: 1 }}>
                        <MyAppText style={styles.itemLeftTitle}>
                            {item.camera.serialNo ? item.camera.serialNo : item.camera.title}
                        </MyAppText>
                        <MyAppText style={styles.itemLeftDate}>{translate('beginIn')}: {moment(item.start).format('DD/MM/YYYY HH:mm:ss')}</MyAppText>
                        <MyAppText style={styles.itemLeftDate}>{translate('endIn')}: {item.finish ? moment(item.finish).format('DD/MM/YYYY HH:mm:ss') : ''}</MyAppText>
                        <MyAppText style={styles.itemLeftTitle}>{item.reason ? translate(item.reason) : ''}</MyAppText>
                        <View style={styles.itemLeftUser} >
                            <FontAwesomeIcon icon={faUserCircle} fontSize={16} color={getThemedColor(theme, '#888888')} />
                            <MyAppText style={styles.itemLeftUserText}>{item.responsibleUser?.name ? item.responsibleUser.warName || item.responsibleUser.name : translate('noResponsible')}</MyAppText>
                        </View>
                    </View>
                </View>

            </TouchableOpacity >
        );
    }

    function getListView(itemsList: OfflineEvent[], text: string) {
        return <View style={styles.list}>
            <View style={styles.listHeader}>
                <MyAppText style={styles.listHeaderText}>{translate(text)}</MyAppText>
                <MyAppText style={styles.listHeaderTotal}>{itemsList.length}</MyAppText>
            </View>
            <View style={styles.listItemsContainer}>
                <FlatList
                    data={itemsList}
                    keyExtractor={(item) => item?.id?.toString() || '0'}
                    renderItem={renderItem}
                    contentContainerStyle={{ gap: 10 }}
                />
            </View>
        </View>;
    }

    return (
        <>
            <View style={styles.container}>
                {getListView(pendingList, 'pending')}
                {getListView(ongoingList, 'ongoing')}
                {getListView(concludedList, 'concluded')}
            </View>
            <ActOfflineEventModal
                historyId={selectedEventId}
                isVisible={isActOfflineEventModalVisible}
                setIsVisible={setIsActOfflineEventModalVisible}
                onSubmit={() => {
                    getEvents();
                }}
            />
            <CameraHistoryModal
                historyId={selectedEventId}
                isVisible={isCameraHistoryModalVisible}
                setIsVisible={setIsCameraHistoryModalVisible}
                onSubmit={() => {
                    getEvents();
                }}
            />
        </>
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            flex: 1,
            flexDirection: 'row',
            gap: 30
        },
        list: {
            flex: 1,
        },
        listHeader: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 50,
        },
        listHeaderText: {
            fontSize: 20,
            fontWeight: 'bold',
            color: getThemedColor(theme, '#58595B'),
        },
        listHeaderTotal: {
            fontSize: 20,
            color: getThemedColor(theme, '#58595B'),
        },
        listItemsContainer: {
            flex: 1
        },
        card: {
            borderWidth: 1,
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#EEEEEE'),
            padding: 8,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            minHeight: 85,
            flexWrap: 'wrap',
            gap: 5
        },
        itemLeftContent: {
            flex: 1,
            flexGrow: 5,
        },
        itemLeftTitle: {
            fontSize: 15,
            color: getThemedColor(theme, '#222222')
        },
        itemLeftDate: {
            fontSize: 12,
            color: getThemedColor(theme, '#888888')
        },
        itemLeftUser: {
            flexDirection: 'row',
            alignItems: 'center',
            gap: 5
        },
        itemLeftUserText: {
            fontSize: 12,
            color: getThemedColor(theme, '#888888'),
        },
        itemPriority: {
            justifyContent: 'center',
            alignItems: 'center',
            width: 64,
            height: 64,
        },
        itemPriorityText: {
            fontSize: 12
        },
        itemMaximumPriorityText: {
            fontSize: 12,
            color: getThemedColor(theme, '#D50000')
        },
        image: {
            width: 64,
            height: 64,
            borderRadius: 4,
            objectFit: 'contain',
        },
        facialBlurImage: {
            width: 64,
            height: 64,
            borderRadius: 4,
            objectFit: 'contain',
            filter: 'blur(5px)'
        },
        imageBox: {
            height: 64,
            width: 64,
            minWidth: 64,
            borderRadius: 4,
            justifyContent: 'center',
            alignItems: 'center'
        },
        imageInvasion: {
            width: 128,
            minWidth: 128,
            height: 64,
            borderRadius: 4,
            objectFit: 'contain',
        },
        imageBoxInvasion: {
            height: 64,
            width: 128,
            minWidth: 128,
            borderRadius: 4,
            justifyContent: 'center',
            alignItems: 'center'
        }
    });
}
