import React, { PropsWithoutRef, useContext, useEffect, useState } from 'react';
import DropDownPicker, { DropDownPickerProps, LanguageType } from 'react-native-dropdown-picker';
import { getDefaultLanguage } from '../services/translate';
import { ColorValue } from 'react-native';
import colors from '../services/colors';
import ThemeContext from '../context/Theme';
import getThemedColor from '../services/get-themed-color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

export type ValueType = string | number | boolean;

export default function MyDropDownPicker(props: PropsWithoutRef<DropDownPickerProps<ValueType>> & {
    borderColor?: ColorValue;
    placeholderColor?: ColorValue;
    height?: number;
}) {
    const { theme } = useContext(ThemeContext);
    const [dropDownLanguage, setDropDownLanguage] = useState<LanguageType>('PT');

    let height = 40;
    if (props.height) {
        height = props.height;
    }

    useEffect(() => {
        (async () => {
            const language = await getDefaultLanguage();
            setDropDownLanguage(language.split('-')[0].toUpperCase() as LanguageType);
        })();
    }, []);

    return <DropDownPicker
        {...props}
        language={dropDownLanguage}
        badgeDotColors={colors}
        searchWithRegionalAccents
        badgeTextStyle={{
            color: '#222222'
        }}
        listItemContainerStyle={{
            flex: 1,
            padding: 5,
            minHeight: 32
        }}
        style={[props.style, {
            height: height,
            minHeight: height,
            borderWidth: 1,
            borderRadius: 4,
            flex: 1,
            borderColor: props.borderColor || getThemedColor(theme, '#CCCCCC'),
            backgroundColor: props.disabled ? getThemedColor(theme, '#EEEEEE') : getThemedColor(theme, '#FFFFFF')
        }]}
        placeholderStyle={{
            color: props.placeholderColor || getThemedColor(theme, '#CBCBCB')
        }}
        textStyle={{
            fontSize: 16,
            color: getThemedColor(theme, '#222222'),
            fontFamily: 'Open Sans',
        }}
        dropDownContainerStyle={{
            borderColor: props.borderColor || getThemedColor(theme, '#CCCCCC'),
            borderRadius: 4,
            backgroundColor: props.disabled ? getThemedColor(theme, '#EEEEEE') : getThemedColor(theme, '#FFFFFF')
        }}
        searchTextInputStyle={{
            borderColor: props.borderColor || getThemedColor(theme, '#CCCCCC'),
            borderRadius: 4,
            color: getThemedColor(theme, '#222222'),
        }}
        ArrowDownIconComponent={() => <FontAwesomeIcon icon={faChevronDown} color={getThemedColor(theme, '#222222')} />}
        ArrowUpIconComponent={() => <FontAwesomeIcon icon={faChevronUp} color={getThemedColor(theme, '#222222')} />}
        TickIconComponent={() => <FontAwesomeIcon icon={faCheck} color={getThemedColor(theme, '#222222')} />}
        searchContainerStyle={{
            borderBottomColor: props.borderColor || getThemedColor(theme, '#CCCCCC')
        }} />;
}
