import React from 'react';
import { OccurrenceDetail } from '../../components/occurrence/OccurrenceDetail';


interface DispatchOccurrenceDetailParams {
    navigation: Navigation;
    authenticatedUser?: AuthenticatedUser;
    route: {
        params?: {
            occurrenceId: string;
        };
    };
}

export default function DispatchOccurrenceDetail({ navigation, route, authenticatedUser }: DispatchOccurrenceDetailParams) {
    return (
        <OccurrenceDetail navigation={navigation} authenticatedUser={authenticatedUser} occurrenceId={route.params?.occurrenceId} page={'dispatch'} />
    );
}
