import React, { useContext, useEffect, useState } from 'react';
import { View, StyleSheet, TouchableOpacity, ScrollView, Modal } from 'react-native';
import MyAppText from '../../components/MyAppText';
import { translate } from '../../services/translate';
import ThemeContext from '../../context/Theme';
import getThemedColor from '../../services/get-themed-color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { TextInputMask } from 'react-native-masked-text';
import { ptzPriorityService } from '../../services/central-api/ptz-priority';
import FormActions from '../../components/formActions';
import MyDropDownPicker from '../../components/MyDropDownPicker';
import { accessService } from '../../services/central-api/access';
import Toast from 'react-native-toast-message';
import CustomButton from '../../components/CustomButton';

export default function PtzPriority() {
    const { theme } = useContext(ThemeContext);
    const styles = getStyles(theme);
    const [usersPriorities, setUsersPriorities] = useState<(PtzPriority & { textPriority: string; })[]>([]);
    const [isUserModalVisible, setUserModalVisible] = useState(false);
    const [selectedUser, setSelectedUser] = useState(0);
    const [userDropdownOpen, setUserDropdownOpen] = useState(false);
    const [users, setUsers] = useState<AccessSimplifiedUserData[]>([]);
    const [priority, setPriority] = useState('');
    const [isListDirty, setListDirty] = useState(false);

    async function getPtzPriorities() {
        try {
            const ptzPriorities = await ptzPriorityService.getPtzPriorities();
            setUsersPriorities(ptzPriorities.map(pp => {
                return { ...pp, textPriority: String(pp.priority) };
            }));
        } catch (err) {
            console.error(err);
        }
    }

    async function getUsers() {
        try {
            const users = await accessService.getAccessAllUsers({});
            setUsers(users.filter(user => !usersPriorities.find(up => up.userId == user.id)));
        } catch (err) {
            console.error(err);
        }
    }

    async function savePtzPriorities() {
        try {
            await ptzPriorityService.savePtzPriorities(usersPriorities.map(up => {
                return {
                    userId: up.userId,
                    priority: up.priority
                };
            }));
            setListDirty(false);
            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
        } catch (err) {
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    useEffect(() => {
        getPtzPriorities();
    }, []);

    useEffect(() => {
        getUsers();
    }, [usersPriorities]);


    async function addUser() {
        try {

            await ptzPriorityService.addPtzPriority({ priority: Number(priority), userId: selectedUser });
            await getPtzPriorities();
            setSelectedUser(0);
            setPriority('');
            setUserModalVisible(false);

            Toast.show({
                type: 'sentinelxSuccess',
                text1: translate('ActionSuccessfully'),
            });
        } catch (err) {
            console.error(err);
            Toast.show({
                type: 'sentinelxError',
                text1: translate('unexpectedError'),
            });
        }
    }

    return (
        <>
            <View style={styles.container}>
                <View style={styles.headerContent}>
                    <View style={{ flex: 1 }}></View>
                    <View style={styles.buttonsContainer}>
                        <CustomButton
                            text={translate('addUser')}
                            onPress={() => setUserModalVisible(true)}
                            type='secondary'
                            disabled={isListDirty}
                        />
                        <CustomButton
                            text={translate('save')}
                            onPress={() => savePtzPriorities()}
                            disabled={!isListDirty}
                        />
                    </View>
                </View>
                <View style={styles.cardList}>
                    <View style={[styles.row, { height: 52 }]}>
                        <View style={{ width: 100 }}>
                            <MyAppText style={{ fontSize: 13, textAlign: 'center' }}>{translate('priority')}</MyAppText>
                        </View>
                        <View style={{ flex: 3 }}>
                            <MyAppText style={{ fontSize: 13 }}>{translate('name')}</MyAppText>
                        </View>
                        <View style={{ flex: 2 }}>
                            <MyAppText style={{ fontSize: 13 }}>{translate('email')}</MyAppText>
                        </View>
                        <View style={{ width: 200 }}>
                            <MyAppText style={{ fontSize: 13 }}>{translate('actingBody')}</MyAppText>
                        </View>
                        <View style={{ width: 200 }}>
                            <MyAppText style={{ fontSize: 13 }}>{translate('role')}</MyAppText>
                        </View>
                        <View style={{ width: 50 }} />
                    </View>
                    <View style={{ width: '99%', borderTopColor: getThemedColor(theme, '#E6E6E6'), borderTopWidth: 1, alignSelf: 'center' }} />
                    <ScrollView>
                        {usersPriorities.map((usersPriority, index) => {
                            return <View key={usersPriority.id}>
                                <View style={styles.row}>
                                    <View style={{ width: 100 }}>
                                        {index > 0 ?
                                            <TouchableOpacity onPress={() => {
                                                usersPriorities[index].priority = usersPriorities[index].priority - 1;
                                                if (usersPriorities[index].priority == usersPriorities[index - 1].priority) {
                                                    usersPriorities[index - 1].priority = usersPriorities[index - 1].priority + 1;
                                                }
                                                usersPriorities[index].textPriority = String(usersPriorities[index].priority);
                                                usersPriorities[index - 1].textPriority = String(usersPriorities[index - 1].priority);
                                                setUsersPriorities([...usersPriorities.sort((a, b) => a.priority - b.priority)]);
                                                setListDirty(true);
                                            }}>
                                                <FontAwesomeIcon color={getThemedColor(theme, '#222222')} icon={faChevronUp} />
                                            </TouchableOpacity>
                                            : <FontAwesomeIcon color={getThemedColor(theme, '#CCCCCC')} icon={faChevronUp} />
                                        }
                                        <TextInputMask
                                            value={String(usersPriority.textPriority)}
                                            includeRawValueInChangeText={true}
                                            onChangeText={(maskedText, rawText) => {
                                                usersPriorities[index].textPriority = rawText || '';
                                                setUsersPriorities([...usersPriorities]);
                                            }}
                                            type={'only-numbers'}
                                            style={styles.rowInput}
                                            onBlur={() => {
                                                const newPriority = Number(usersPriorities[index].textPriority);
                                                if (!newPriority) {
                                                    usersPriorities[index].textPriority = String(usersPriorities[index].priority);
                                                    setUsersPriorities([...usersPriorities.sort((a, b) => a.priority - b.priority)]);
                                                    return;
                                                }

                                                if (newPriority == usersPriorities[index].priority) {
                                                    return;
                                                }

                                                for (const up of usersPriorities) {
                                                    // ignore itself
                                                    if (up.priority == usersPriorities[index].priority) {
                                                        continue;
                                                    }
                                                    if (newPriority > usersPriorities[index].priority && up.priority <= newPriority && up.priority > usersPriorities[index].priority) {
                                                        up.priority = up.priority - 1;
                                                        up.textPriority = String(up.priority);
                                                    }
                                                    if (newPriority < usersPriorities[index].priority && up.priority >= newPriority && up.priority < usersPriorities[index].priority) {
                                                        up.priority = up.priority + 1;
                                                        up.textPriority = String(up.priority);

                                                    }
                                                }

                                                usersPriorities[index].priority = newPriority;

                                                setUsersPriorities([...usersPriorities.sort((a, b) => a.priority - b.priority)]);
                                                setListDirty(true);
                                            }}
                                        />
                                        {index != usersPriorities.length - 1 ?
                                            <TouchableOpacity onPress={() => {
                                                usersPriorities[index].priority = usersPriorities[index].priority + 1;
                                                if (usersPriorities[index].priority == usersPriorities[index + 1].priority) {
                                                    usersPriorities[index + 1].priority = usersPriorities[index + 1].priority - 1;
                                                }
                                                usersPriorities[index].textPriority = String(usersPriorities[index].priority);
                                                usersPriorities[index + 1].textPriority = String(usersPriorities[index + 1].priority);
                                                setUsersPriorities([...usersPriorities.sort((a, b) => a.priority - b.priority)]);
                                                setListDirty(true);
                                            }}>
                                                <FontAwesomeIcon color={getThemedColor(theme, '#222222')} icon={faChevronDown} />
                                            </TouchableOpacity>
                                            : <FontAwesomeIcon color={getThemedColor(theme, '#CCCCCC')} icon={faChevronDown} />
                                        }

                                    </View>
                                    <View style={{ flex: 3 }}>
                                        <MyAppText>{usersPriority.user.name}</MyAppText>
                                    </View>
                                    <View style={{ flex: 2 }}>
                                        <MyAppText>{usersPriority.user.email}</MyAppText>
                                    </View>
                                    <View style={{ width: 200 }}>
                                        <MyAppText>{usersPriority.user.role?.actingBody?.name}</MyAppText>
                                    </View>
                                    <View style={{ width: 200 }}>
                                        <MyAppText>{usersPriority.user.role?.name}</MyAppText>
                                    </View>
                                    <View style={{ width: 50 }}>
                                        <TouchableOpacity onPress={() => {
                                            usersPriorities.splice(index, 1);
                                            setUsersPriorities([...usersPriorities.sort((a, b) => a.priority - b.priority)]);
                                            setListDirty(true);
                                        }}>
                                            <FontAwesomeIcon color={getThemedColor(theme, '#222222')} icon={faTrashCan} />
                                        </TouchableOpacity>
                                    </View>
                                </View>
                                <View style={{ width: '99%', borderTopColor: getThemedColor(theme, '#E6E6E6'), borderTopWidth: 1, alignSelf: 'center' }} />
                            </View>;

                        })}
                    </ScrollView>
                </View>
            </View>
            <Modal visible={isUserModalVisible} animationType='fade' onRequestClose={() => setUserModalVisible(false)} transparent={true}>
                <View style={styles.modalContainer}>
                    <View style={styles.modalBody}>
                        <View style={{ rowGap: 5, flex: 1 }}>
                            <MyAppText style={styles.label}>{translate('users')}</MyAppText>
                            <MyDropDownPicker
                                items={users.map(item => {
                                    return {
                                        label: item.name,
                                        value: item.id
                                    };
                                })}
                                searchable={true}
                                open={userDropdownOpen}
                                setOpen={setUserDropdownOpen}
                                setValue={setSelectedUser}
                                value={selectedUser}
                                multiple={false}
                            />
                        </View>
                        <View style={{ rowGap: 5, flex: 1, zIndex: -13 }}>
                            <MyAppText style={styles.label}>{translate('priority')}</MyAppText>
                            <TextInputMask
                                value={priority}
                                includeRawValueInChangeText={true}
                                onChangeText={(maskedText, rawText) => {
                                    setPriority(rawText || '');
                                }}
                                type={'only-numbers'}
                                style={styles.input}
                            />
                        </View>
                        <FormActions
                            onSubmit={addUser}
                            onClose={() => {
                                setSelectedUser(0);
                                setPriority('');
                                setUserModalVisible(false);
                            }}
                            disabled={!selectedUser || !priority} />
                    </View>
                </View>
            </Modal>
        </>
    );
}

function getStyles(theme: Theme) {
    return StyleSheet.create({
        container: {
            flex: 1,
            rowGap: 15
        },
        headerContent: {
            flexDirection: 'row',
            minHeight: 65,
            zIndex: 2,
            flexWrap: 'wrap',
            gap: 5,
        },
        buttonsContainer: {
            flexDirection: 'row',
            alignItems: 'flex-end',
            gap: 10,
        },
        input: {
            color: getThemedColor(theme, '#222222'),
            fontSize: 16,
            fontFamily: 'Open Sans',
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            borderColor: getThemedColor(theme, '#CCCCCC'),
            borderWidth: 1,
            padding: 10,
            borderRadius: 4,
        },
        addUserButton: {
            alignItems: 'center',
            borderWidth: 1,
            borderRadius: 2,
            borderColor: getThemedColor(theme, '#888888'),
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            width: 160,
            height: 40,
            justifyContent: 'center',
            alignSelf: 'flex-end'
        },
        addUserText: {
            color: getThemedColor(theme, '#222222'),
            fontSize: 16,
        },
        saveButton: {
            alignItems: 'center',
            borderWidth: 0,
            borderRadius: 2,
            backgroundColor: getThemedColor(theme, '#000028'),
            width: 160,
            height: 40,
            justifyContent: 'center',
            alignSelf: 'flex-end'
        },
        saveText: {
            color: getThemedColor(theme, '#FFFFFF'),
            fontSize: 16,
        },
        cardList: {
            borderWidth: 1,
            borderRadius: 8,
            borderColor: getThemedColor(theme, '#E6E6E6'),
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            paddingHorizontal: 10,
            flex: 1
        },
        row: {
            flexDirection: 'row',
            paddingVertical: 10,
            alignItems: 'center',
        },
        rowInput: {
            color: getThemedColor(theme, '#222222'),
            textAlign: 'center',
        },
        modalContainer: {
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#31313199',
            flex: 1
        },
        modalBody: {
            minWidth: 300,
            backgroundColor: getThemedColor(theme, '#FFFFFF'),
            flexDirection: 'column',
            borderRadius: 4,
            padding: 25,
            rowGap: 20
        },
        label: {
            zIndex: -11,
            color: getThemedColor(theme, '#58595B'),
        },
        saveButtonDisabled: {
            backgroundColor: getThemedColor(theme, '#CCCCCC')
        },
        saveTextDisabled: {
            color: getThemedColor(theme, '#888888')
        },
    });
}
